import { navigate } from "svelte-routing";
import { navigationAdjustedStore } from "./store/navigationStore";
import { writable } from "svelte/store";

// -- VARIABLES

export let currentPathname = writable( window.location.pathname );

let originalPushState = history.pushState;

let originalReplaceState = history.replaceState;

let acceptableLanguages = [ 'en', 'fr', 'pt', 'es', 'it' ];

// -- FUNCTIONS

function setPathname( newPath )
{
    currentPathname.set( newPath );
}

// ~~

history.pushState = function( state, title, url )
{
    originalPushState.call( this, state, title, url );
    setPathname( window.location.pathname );
};

// ~~

history.replaceState = function( state, title, url )
{
    originalReplaceState.call( this, state, title, url );
    setPathname( window.location.pathname );
};

// ~~

window.addEventListener( 'popstate', () =>
{
    setPathname( window.location.pathname );
}
);

// ~~

function setLanguageBasedOnUserBrowser(
    )
{
    let browserLanguage = navigator.language.split( '-' )[ 0 ];
    let localStorageLanguage = localStorage.getItem( 'language' );

    if ( localStorageLanguage )
    {
        browserLanguage = localStorageLanguage
    }
    else
    {
        let isValidLanguage = acceptableLanguages.some( language => language !== browserLanguage )

        if ( !isValidLanguage )
        {
            browserLanguage = 'en';
        }
    }

    return browserLanguage;
}

// ~~

export function ensureLanguageTagInPath(
    location,
    languageTagStore
    )
{
    let pathSegments = location.pathname.split( '/' ).filter( Boolean );
    let selectedLanguageIsValid = acceptableLanguages.some( language => language === pathSegments[ 0 ] );

    if ( location.pathname === '/' 
         || ( pathSegments.length > 0 && pathSegments[ 0 ].length !== 2 ) 
         || (!selectedLanguageIsValid)
        )
    {
        if( pathSegments[ 0 ]?.length === 2 )
        {
            pathSegments.splice( 0, 1 )
        }

        languageTagStore = setLanguageBasedOnUserBrowser();

        let newPath = location.pathname === '/' ? `/${ languageTagStore }/` : `/${ languageTagStore }/${ pathSegments.join( '/' ) }`;

        if ( location.search )
        {
            newPath += location.search;
        }

        navigate( newPath, { replace: true } );
    }

    navigationAdjustedStore.set( true );
}
