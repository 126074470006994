<script>
    // -- IMPORTS

    import Modal from '../../../../ADMIN/src/lib/component/element/Modal.svelte';
    import BlockManager from '../../../../ADMIN/src/lib/component/element/BlockManager.svelte';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import { getLocalizedText } from 'senselogic-gist';
    import { blockTypeArray, getProcessedMultilineTranslatedText } from '$lib/base.js';
    import PageHeading from '$lib/component/header/PageHeading.svelte';
    import { onMount } from 'svelte';
    import { fetchData } from '../base';
    import Loading from '../component/element/Loading.svelte';
    import { pageStore } from '../store/pageDataStore';
    import urlStore from '../store/urlStore';

    // -- VARIABLES

    let isLoading = true;
    let termsOfServicePage = $pageStore.page.termsOfService;
    let termsOfServiceBlockArray = $pageStore.block.termsOfService;
    let isEditBlockModalOpen = false;
    let blockBeingEdited = {};

    async function loadData(
        )
    {
        try
        {
            isLoading = true;

            let [ termsOfServicePageData, termsOfServiceBlockArrayData ] = await Promise.all(
                [
                    fetchData(
                        '/api/page/get-by-route',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    route: '/terms-of-service'
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    ),
                    fetchData(
                        '/api/block/get-by-page-id',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    pageId: 'bbbGYjy8UGHRPkk9d9GvgA'
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    )
                ]
            );

            termsOfServicePage = termsOfServicePageData.page;
            termsOfServiceBlockArray = termsOfServiceBlockArrayData.blockArray;

            $pageStore.page.termsOfService = termsOfServicePage.page;
            $pageStore.block.termsOfService = termsOfServiceBlockArray.blockArray;
        }
        catch ( error )
        {
            console.error( 'Error:', error );
        }
        finally
        {
            isLoading = false;
        }
    }

    // ~~

    async function saveBlockData(
        )
    {
        isEditBlockModalOpen = false;
        loadData();
    }

    // ~~

    function handleOpenBlockModal(
        block
        )
    {
        isEditBlockModalOpen = true;
        blockBeingEdited = block;
    }


    // -- STATEMENTS

    onMount(
        () =>
        {
            if( termsOfServicePage === undefined 
                || termsOfServiceBlockArray === undefined ) 
            {
                loadData();
            }
            else
            {
                isLoading = false;
            }
        }
    );

    $: if ( $urlStore.pathname.includes( 'terms-of-service' ) )
    {
        document.title = 'Terms of Service';
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .terms-of-use-section
    {
        padding: 0rem 1.5rem 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        align-items: center;

        +media( desktop )
        {
            max-width: 62.5vw;
        }
    }

    .terms-of-use-subtitle
    {
        line-height: 2rem;
        font-size: 1.125rem;
        font-weight: 600;
        text-align: left;
        color: lightGrayColor;
    }

    .terms-of-use-text
    {
        line-height: 2rem;
        font-size: 1.125rem;
        letter-spacing: 0.1rem;
        color: lightGrayColor;
    }

    .terms-of-use-block
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            gap: 1rem;
        }
    }
</style>

{#if isLoading }
    <Loading />
{:else}
    <Modal
        bind:showModal={ isEditBlockModalOpen }
        modalConfirmButtonLabel="Save"
        modalType=""
        hasFooterButtons={ false }
        modalTitle="Edit Block"
        >
        <BlockManager
            blockData={ blockBeingEdited }
            blockTypeArray={ blockTypeArray }
            on:closeModal={ () => isEditBlockModalOpen = false }
            on:blockSaved={ saveBlockData }
        />
    </Modal>

    <div class:is-admin-enabled={ $enabledAdminView }>
        {#if termsOfServicePage.id }
            <AdminEditButton
                inset="0 5rem auto auto"
                type="page"
                id={ termsOfServicePage.id }
                title={ termsOfServicePage.title }
            />
        {/if}
    
        {#if termsOfServicePage.title }
            <PageHeading
                title={ termsOfServicePage.title }
            />
        {/if}
    
        <section class="terms-of-use-section">
            {#each termsOfServiceBlockArray as termsOfServiceBlock }
                {#if termsOfServiceBlock.typeSlug === 'text' }
                    <div class="terms-of-use-block" class:is-admin-enabled={ $enabledAdminView }>
                        <AdminEditButton
                            inset="0 5rem auto auto"
                            handleAdminButton={ () => handleOpenBlockModal( termsOfServiceBlock ) }
                            title={ termsOfServiceBlock.title }
                        />
                        
                        {#if termsOfServiceBlock.title }
                            <p class="terms-of-use-subtitle">{ @html getProcessedMultilineTranslatedText( termsOfServiceBlock.title ) }</p>
                        {/if}
    
                        {#if termsOfServiceBlock.text }
                            <span class="terms-of-use-text">{@html getProcessedMultilineTranslatedText( termsOfServiceBlock.text ) }</span>
                        {/if}
                    </div>
                {:else if termsOfServiceBlock.typeSlug === 'list' || termsOfServiceBlock.typeSlug === 'nested-lists' }
                    <div class="terms-of-use-block" class:is-admin-enabled={ $enabledAdminView }>
                        <AdminEditButton
                            inset="0 5rem auto auto"
                            handleAdminButton={ () => handleOpenBlockModal( termsOfServiceBlock ) }
                            title={ termsOfServiceBlock.title }
                        />
                        
                        {#if termsOfServiceBlock.title }
                            <p class="terms-of-use-subtitle">{ @html getProcessedMultilineTranslatedText( termsOfServiceBlock.title ) }</p>
                        {/if}
    
                        <span class="terms-of-use-text">
                            <div>
                                {#each termsOfServiceBlock.textArray as text }
                                    {#if text }
                                        {@html getProcessedMultilineTranslatedText( text ) }
                                    {/if}
                                {/each}
                            </div>
                        </span>
                    </div>
                {/if}
            {/each}
        </section>
    </div>
{/if}
