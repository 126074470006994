<script>
    // -- IMPORTS

    import { languageArrayStore } from '$lib/store/languageArrayStore.js';
    import { toast } from '$lib/toast';
    import Input from '../../component/element/Input.svelte';
    import InputLocalizedForm from '../../component/element/InputLocalizedForm.svelte';
    import { fetchData, addFileArrayOnObject, checkRequiredIsFilled } from '../../base';
    import { createEventDispatcher, onMount } from 'svelte';
    import Loading from '../../component/element/Loading.svelte';
    import FileInput from '../../component/element/FileInput/FileInput.svelte';
    import { isLoadingStore } from '../../store/isLoadingStore';

    // -- VARIABLES

    export let isUploadingImage = false;
    export let partnerId;

    let form;
    let dispatch = createEventDispatcher();
    let error = null;
    let message = null;
    let isLoading = true;
    let isHovered = false;
    let isSubmitting = false;

    let partnerCompany = {
        title: '',
        number: null,
        text: '',
        mediaPath: '',
        mediaPathArray: [],
        href: '',
        imageSide: '',
        imageFit: ''
    };
    let folderPath = 'partner_company/' + partnerId + '/image/';
    let fileArray = [];
    let requiredFieldArray = ['title', 'text', 'mediaPath'];

    // -- FUNCTIONS

    function setDefaultFileArray(
        )
    {
        if ( partnerCompany?.mediaPath )
        {
            fileArray = [ partnerCompany.mediaPath ];
        }
    }

    // ~~

    function handleUpdateLocalizedInput(
        event
        )
    {
        partnerCompany[ event.detail.name ] = JSON.stringify( event.detail.text );
    }

    async function handleSubmit(
        event
        )
    {
        error = null;
        message = null;
        $isLoadingStore = true;

        try
        {
            let formData = new FormData();
            formData.delete( 'text' );

            partnerCompany = addFileArrayOnObject( partnerCompany, fileArray );

            for ( let [ key, value ] of Object.entries( partnerCompany ) )
            {
                formData.append( key, value )
            }

            let errorArray = checkRequiredIsFilled( partnerCompany, requiredFieldArray );

            if( errorArray.length > 0 )
            {
                isSubmitting = false;
                return;
            }

            let response =
                await fetchData(
                    '/api/partner-company/set-by-id/' + partnerId,
                    {
                        method: 'POST',
                        body: formData,
                        credentials: 'include'
                    }
                    );

            if ( response.error )
            {
                error = response.error;

                toast(
                    {
                        variant: 'error',
                        text: response.error
                    }
                    );

                isSubmitting = false;
            }
            else
            {
                message = response.message;

                toast(
                    {
                        variant: 'success',
                        text: response.message
                    }
                    );

                isSubmitting = false;
                dispatch('partnerUpdated')
            }
        }
        catch ( error )
        {
            console.error( error );
        }
        finally
        {
            setDefaultFileArray();
            $isLoadingStore = false;
        }
    }

    async function loadData(
        )
    {
        try
        {
            let partnerCompanyData =
                await fetchData(
                    '/api/partner-company/get-by-id/' + partnerId,
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                id: partnerId
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                )

            partnerCompany = partnerCompanyData.partnerCompany;
        }
        catch ( error )
        {
            console.error( error );
        }
        finally
        {
            isLoading = false;
            setDefaultFileArray();
        }
    }
    // ~~

    function customSubmit(
        event
        )
    {
        if ( form )
        {
            let fakeSubmitEvent = new Event('submit', { bubbles: true, cancelable: true } );
            form.dispatchEvent( fakeSubmitEvent );
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            loadData();
            setDefaultFileArray();

            window.addEventListener(
                'on-save-block',
                customSubmit
                );

            return () => {
                window.removeEventListener( 'on-save-block', customSubmit );
            }
        }
    );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    form
    {
        width: 100%;
    }

    label
    {
        font-size: 1.25rem;
    }

    option
    {
        color: lightGreyColor;
    }

    fieldset
    {
        border: 1px solid lightGoldColor;
        padding: 1rem;
    }

    // -- CLASSES

    .member-edit-form
    {
        padding: 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        +media( tablet )
        {
            padding: 1rem 2.5rem;
        }

        +media( desktop )
        {
            padding: 1.5rem 3rem;
        }
    }

    .card-form-inputs-container

    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .forms-container
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
    }

    .form-container
    {
        width: 100%;
        padding: 1.5rem 0;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }

    .cards-section
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .admin-page-navigation
    {
        position: fixed;

        inset: 8rem 2rem auto auto;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
</style>

{#if isLoading }
    <Loading />
{:else}
    <div id="blocks" class="cards-section">
        <div class="forms-container">
            <div class="form-container" >
                <form
                    on:submit|preventDefault={ handleSubmit }
                    bind:this={ form }
                    class="member-edit-form"
                >
                    {#if error }
                        <p class="card-form-error">{ error }</p>
                    {/if}
                    {#if message }
                        <p class="card-form-message">{ message }</p>
                    {/if}
                    <div class="card-form-inputs-container">
                        <Input label="Partner name" id="title" bind:value={ partnerCompany[ 'title' ] } fullWidth/>
                        <InputLocalizedForm
                            isMultiLine
                            itemsString={ partnerCompany[ 'text' ] }
                            languageArray={ $languageArrayStore }
                            name="text"
                            on:update={ handleUpdateLocalizedInput }
                            placeholder="text"
                        />
                        <fieldset>
                            <legend class="color-light-gold font-size-125 margin-bottom-100 text-transform-uppercase">Partner logo</legend>
                            <FileInput
                                bind:fileArray={ fileArray }
                                bind:isUploadingImage={ isUploadingImage }
                                fileInputName="image-path"
                                acceptedType="image/*"
                                maxFileCount={ 1 }
                                filePath={ folderPath }
                            />
                        </fieldset>
                    </div>
                </form>
            </div>
        </div>
    </div>
{/if}
