<script>
    // -- IMPORTS
    import { capitalizeFirstLetter } from '../../base';

    // -- VARIABLES

    export let disabled = false;
    export let error = false;
    export let fullWidth = false;
    export let helperText = '';
    export let id = '';
    export let label = '';
    export let multiline = false;
    export let name = '';
    export let readonly = false;
    export let required = false;
    export let type = 'text';
    export let value;
    export let rows = 1;
    export let cols = 1;
    export let inputElement = null;
    export let shouldShowLabel = true;
    export let className;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import "../../../constant.styl";
    @import "../../../mixin.styl";

    // -- ELEMENTS

    textarea
    {
        resize: none;
    }

    textarea.input
    {
        height: initial;
    }

    // -- CLASSES

    .input-root
    {
        margin: 0.5rem;
        width: 25ch;
    }

    .input-container
    {
        position: relative;

        margin: 0px;
        min-width: 0px;
        border: 0px;
        padding: 0px;

        display: inline-flex;
        flex-direction: column;

        vertical-align: top;
    }

    .input-label.error
    {
        color: rgb(211, 47, 47);
    }

    .input-label
    {
        margin-bottom: .5rem;
        max-width: calc(133% - 2rem);

        color: lightGreyColor;

        user-select: none;
        pointer-events: auto;
        transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    .input-content
    {
        box-sizing: border-box;

        display: inline-flex;

        line-height: 1.4375em;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.00938em;
        color: lightGreyColor;

        cursor: text;
        -webkit-box-align: center;
        position: relative;

        border-radius: 2px;

        align-items: center;
    }

    .input-content.adornment-end
    {
        padding-right: 0.875rem;
    }

    .input
    {
        box-sizing: content-box;
        margin: 0px;
        outline: none;
        height: 1.4375em;
        border: 0px;

        background: darkGrayColor;

        font: inherit;
        letter-spacing: inherit;
        color: currentcolor;
        -webkit-tap-highlight-color: transparent;
        width: 100%;
        min-width: 0px;
        padding: 16.5px 0.875rem;

        display: block;

        transition: background 0.3s linear;
        animation-name: mui-auto-fill-cancel;
        animation-duration: 10ms;
    }

    .input:hover
    {
        background: #000000;
    }

    .input-content.error .fieldset
    {
        border-color: rgb(211, 47, 47);
    }

    .fieldset
    {
        position: absolute;

        overflow: hidden;
        inset: -5px 0px 0px;
        margin: 0px;
        min-width: 0%;
        border-radius: inherit;
        padding: 0px 0.5rem;

        text-align: left;

        pointer-events: none;
    }

    .legend
    {
        overflow: hidden;
        height: 0.6875rem;
        width: auto;
        padding: 0px;

        display: block;
        float: unset;

        visibility: hidden;

        font-size: 0.75em;
        white-space: nowrap;

        transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    }

    .legend > span
    {
        padding-left: 0.3125rem;
        padding-right: 0.3125rem;

        display: inline-block;

        visibility: visible;
        opacity: 0;
    }

    .helper-text.error
    {
        color: rgb(211, 47, 47);
    }

    .helper-text
    {
        margin: 3px 0.875rem 0px;

        line-height: 1.66;
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.03333em;
        text-align: left;
        color: lightGoldColor;
    }

    .input-without-adornment
    {
        height: 0.01em;
        max-height: 2em;

        display: flex;
        -webkit-box-align: center;
        margin-right: 0.5rem;

        align-items: center;

        white-space: nowrap;
        color: rgba(0, 0, 0, 0.54);
    }

    .input-start-adornment
    {
        box-sizing: border-box;

        display: inline-flex;

        line-height: 1.4375em;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.00938em;
        color: rgba(0, 0, 0, 0.87);

        cursor: text;
        -webkit-box-align: center;
        position: relative;

        border-radius: 4px;
        padding-left: 0.875rem;

        align-items: center;
    }

    .input-end-adornment
    {
        height: 0.01em;
        max-height: 2em;

        display: flex;
        -webkit-box-align: center;
        margin-left: 0.5rem;

        align-items: center;

        white-space: nowrap;
        color: rgba(0, 0, 0, 0.54);
    }

    .input-paragraphy
    {
        margin: 0px;

        line-height: 1.5;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.00938em;
        color: rgba(0, 0, 0, 0.6);
    }

    .full-width
    {
        width: 100%;
    }

    @-webkit-keyframes mui-auto-fill
    {
        from
        {
            display: block;
        }
    }

    @keyframes mui-auto-fill
    {
        from
        {
            display: block;
        }
    }

    @-webkit-keyframes mui-auto-fill-cancel
    {
        from
        {
            display: block;
        }
    }

    @keyframes mui-auto-fill-cancel
    {
        from
        {
            display: block;
        }
    }
</style>

<div class="input-root input-container" class:full-width={ fullWidth }>
    {#if shouldShowLabel && label }
        <h4
            class:error={ error }
            class="input-label"
            for={ id }
            id={ id ? id + '-label' : '' }
        >
            { capitalizeFirstLetter( label ) }
            { required ? ' *' : '' }
        </h4>
    {/if}

    <div class="input-content" class:error={ error } class:adornment-end={ $$slots[ 'end-adornment' ] }>
        {#if $$slots[ 'start-adornment' ] }
            <div class="input-start-adornment">
                <p class="input-paragraphy">
                    <slot name="start-adornment"/>
                </p>
            </div>
        {/if}

        {#if multiline }
            <textarea
                placeholder={ 'Write here...' }
                readonly={ readonly }
                aria-invalid="true"
                aria-describedby="outlined-error-helper-text-helper-text"
                id={ id }
                class={`input ${ className }`}
                bind:value
                bind:this={ inputElement }
                disabled={ disabled }
                required={ required }
                name={ name }
                rows={ rows }
                cols={ cols }
            />
        {:else}
            {#if type === 'email' }
                <input
                    placeholder={ 'Write here...' }
                    readonly={ readonly }
                    aria-invalid="true"
                    aria-describedby="outlined-error-helper-text-helper-text"
                    id={ id }
                    class="input"
                    bind:value
                    bind:this={ inputElement }
                    disabled={ disabled }
                    required={ required }
                    name={ name }
                    type="email"
                />
            {:else if type === 'password' }
                <input
                    placeholder={ 'Write here...' }
                    readonly={ readonly }
                    aria-invalid="true"
                    aria-describedby="outlined-error-helper-text-helper-text"
                    id={ id }
                    class="input"
                    bind:value
                    bind:this={ inputElement }
                    disabled={ disabled }
                    required={ required }
                    name={ name }
                    type="password"
                />
            {:else if type === 'tel' }
                <input
                    placeholder={ 'Write here...' }
                    readonly={ readonly }
                    aria-invalid="true"
                    aria-describedby="outlined-error-helper-text-helper-text"
                    id={ id }
                    class="input"
                    bind:value
                    bind:this={ inputElement }
                    disabled={ disabled }
                    required={ required }
                    name={ name }
                    type="tel"
                />
            {:else if type === 'search' }
                <input
                    placeholder={ 'Write here...' }
                    readonly={ readonly }
                    aria-invalid="true"
                    aria-describedby="outlined-error-helper-text-helper-text"
                    id={ id }
                    class="input"
                    bind:value
                    bind:this={ inputElement }
                    disabled={ disabled }
                    required={ required }
                    name={ name }
                    type="search"
                />
            {:else if type === 'date' }
                <input
                    readonly={ readonly }
                    aria-invalid="true"
                    aria-describedby="outlined-error-helper-text-helper-text"
                    id={ id }
                    class="input"
                    bind:value
                    bind:this={ inputElement }
                    disabled={ disabled }
                    required={ required }
                    name={ name }
                    type="date"
                />
            {:else if type === 'number' }
                <input
                    readonly={ readonly }
                    aria-invalid="true"
                    aria-describedby="outlined-error-helper-text-helper-text"
                    id={ id }
                    class="input"
                    bind:value
                    bind:this={ inputElement }
                    disabled={ disabled }
                    required={ required }
                    name={ name }
                    type="number"
                    min={ 0}
                />
            {:else}
                <input
                    placeholder={ 'Write here...' }
                    readonly={ readonly }
                    aria-invalid="true"
                    aria-describedby="outlined-error-helper-text-helper-text"
                    id={ id }
                    class="input"
                    bind:value
                    bind:this={ inputElement }
                    disabled={ disabled }
                    required={ required }
                    name={ name }
                    type="text"
                />
            {/if}
        {/if}
        <fieldset
            aria-hidden="true"
            class="fieldset"
        >
            <legend
                class="legend"
            >
                <span>
                    { label }
                    { required ? ' *' : '' }
                </span>
            </legend>
        </fieldset>
        {#if $$slots[ 'end-adornment' ] }
            <div class="input-end-adornment">
                <p class="input-paragraphy">
                    <slot name="end-adornment"/>
                </p>
            </div>
        {/if}
    </div>
    {#if error || helperText }
        <p
            class="helper-text"
            class:error={ error }
            id="outlined-error-helper-text-helper-text"
        >
            { helperText }
        </p>
    {/if}
</div>
