<script>
    // -- IMPORTS

    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import AmbassadorBlock from '../ambassadors/AmbassadorBlock.svelte';

    // -- VARIABLES

    export let managerArray;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .manager-section
    {
        border: 1px solid borderColor;
        padding: 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 16rem;

        +media( desktop )
        {
            margin: 0;
            margin-bottom: 5rem;
            max-width: 77vw;
            border: none;
            padding-bottom: 0;
        }
    }
</style>

<div class="manager-container">
    <div class="manager-card" class:is-admin-enabled={ $enabledAdminView }>
        <AdminEditButton
            type={ "ambassadors" }
            inset="0 20px 0 auto"
            title={ "All ambassadors" }
        />
    </div>
    
    <section class="manager-section">
        {#each managerArray as { id, name, position, mediaPath, mediaPathArray, biography, imageSide, linkedinLink, emailAddress, instagramLink, number } }
            <AmbassadorBlock
                id={ id }
                name={ name }
                position={ position }
                mediaPath={ mediaPath }
                mediaPathArray={ mediaPathArray }
                biography={ biography }
                imageSide={ imageSide }
                linkedinLink={ linkedinLink }
                emailAddress={ emailAddress }
                instagramLink={ instagramLink }
                number={ number }
            />
        {/each}
    </section>
</div>
