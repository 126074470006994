<script>
    // -- IMPORTS

    import { SortableList } from '@jhubbardsf/svelte-sortablejs';
    import { toast } from '$lib/toast';
    import { languageTagStore } from '$store/languageTagStore';
    import FileInputImageContainer from './FileInputImageContainer.svelte';
    import { getLocalizedTextBySlug, isArray } from 'senselogic-gist';

    // -- VARIABLES

    export let fileInputName = 'files';
    export let maxFileCount = 1;
    export let fileArray = [];
    export let acceptedType = 'image/*';
    export let maximumFileSize = 10 * 1024 * 1024;
    export let imageSizeArray = [ 1920, 640, 360 ];
    export let isUploadingImage = false;
    export let filePath = '/image/block'
    export let warningMessage = '';
    let fileInput = null;
    let filesContainer = null;

    // -- FUNCTIONS

    async function handleInput(
        event
        )
    {
        let files = event.target.files;

        for ( let file of Array.from( files ) )
        {
            if ( file.size > maximumFileSize )
            {
                toast.error(
                    getLocalizedTextBySlug(
                        'file-size-error-label',
                        { fileName: file.name, maxSize: maximumFileSize / 1024 / 1024 },
                        $languageTagStore
                        )
                    );
            }
            else if ( fileArray.length > maxFileCount )
            {
                toast.error( 'File limit reached. ' + file.name + ' could not be added' );
            }
            else
            {
                fileArray.push( file );
            }
        }

        fileArray = fileArray;
    }

    // ~~

    function handleSort(
        event
        )
    {
        let item = fileArray[ event.oldIndex ];
        fileArray.splice( event.oldIndex, 1 );
        fileArray.splice( event.newIndex, 0, item );

        fileArray = fileArray;
    }

    // ~~

    function handleClickEvent(
        )
    {
        fileInput.click();
    };
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    .file-input
    {
        display: flex;
        gap: 0.5rem;
    }

    :global( .file-input >div )
    {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .file-input-item
    {
        position: relative;

        min-height: 8rem;
        width: var( --file-input-add-new-button-width, 8rem );

        cursor: grab;
    }

    .file-input-item:active
    {
        cursor: grabbing;
    }

    :global( .file-input-item-image )
    {
        height: 100%;
        width: 100%;
        border-radius: 0.5rem;

        object-fit: cover;
    }

    .file-input-remove-button
    {
        position: absolute;
        top: -0.75rem;
        right: -0.75rem;

        height: 1.5rem;
        width: 1.5rem;
        border-radius: .5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: redColor;
    }

    .file-input-add-new-button
    {
        height: 8rem;
        width: var( --file-input-add-new-button-width, 8rem );
        border: 2px solid lightGoldColor;
        border-radius: .5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: darkGreyColor;

        cursor: pointer;
    }
</style>

<div class="file-input">
    {#if isArray( fileArray ) && fileArray.length > 0 }
        <SortableList
            class="file-input-list"
            onEnd={ handleSort }
            bind:this={ filesContainer }
        >
            {#each fileArray as file ( file ) }
                <FileInputImageContainer
                    imageSizeArray={ imageSizeArray }
                    filePath={ filePath }
                    acceptedType={ acceptedType }
                    handleInput={ handleInput }
                    fileInputName={ fileInputName }
                    bind:src={ file }
                    bind:fileArray={ fileArray }
                    bind:isSubmitting={ isUploadingImage }
                />
            {/each}
        </SortableList>
    {/if}
    <input
        name={ fileInputName }
        type="file"
        accept={ acceptedType }
        multiple={ maxFileCount > 1 }
        hidden
        bind:this={ fileInput }
        on:change={ handleInput }
    />
    {#if isArray( fileArray ) && ( fileArray.length ) < maxFileCount }
            <button
                class="file-input-add-new-button"
                on:click|preventDefault={ handleClickEvent }
            >
                <div class="light-gold-plus-icon size-200"/>
            </button>
    {/if}
    <small>{ warningMessage }</small>
</div>
