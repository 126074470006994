<script>
    // -- IMPORTS

    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { navigate } from 'svelte-routing';
    import { languageTagStore } from '$lib/store/languageTagStore'
    import { textStore } from '$lib/store/textStore';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedText from '../element/AnimatedText.svelte';
    import { getProcessedMultilineTranslatedText } from '$src/lib/base';
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .call-to-action-section
    {
        width: 100%;
        padding: 4rem 1.5rem;

        background-color: lightGoldColor;

        +media( desktop )
        {
            padding: 4rem 0;
        }
    }

    .call-to-action-text-container
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
    }

    .call-to-action-button
    {
        margin-top: 3.5rem;
        min-width: 100%;
        border: 1px solid lightGoldColor;
        padding: 1.5rem;

        background-color: darkGreyColor;

        font-size: 1.5rem;
        letter-spacing: 0.1em;
        text-transform: lightGoldColor;
        text-transform: uppercase;
        color: lightGoldColor;

        cursor: pointer;
        transition: all 0.2s ease-in-out;

        +media( desktop )
        {
            min-width: 31rem;
        }
    }

    .call-to-action-button:hover
    {
        border-color: darkGreyColor;

        background-color: lightGoldColor;

        color: darkGreyColor;
    }

    .call-to-action-heading
    {
        margin: unset;

        line-height: 2.5rem;
        font-size: 2rem;
        font-weight: 400;
        letter-spacing: 0.16rem;
        text-align: center;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 200;
            letter-spacing: 0.48rem;
        }
    }

    .call-to-action-text
    {
        text-align: center;
    }
</style>

<section class="call-to-action-section">
    <div class="call-to-action-text-container">
        <h1 class="color-dark-grey call-to-action-heading" class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                type="text"
                slug="join-bamhub-label"
                theme="dark-grey"
                textToEdit={ $textStore[ 'join-bamhub-label' ] }
            />
            <AnimatedText text={ getProcessedMultilineTranslatedText( $textStore[ 'join-bamhub-label' ] || 'JOIN BAMHUB', $languageTagStore ) } />
        </h1>
        <p class="font-size-150 color-dark-grey call-to-action-text" class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                type="text"
                slug="join-bamhub-subtitle-label"
                theme="dark-grey"
                textToEdit={ $textStore[ 'join-bamhub-subtitle-label' ] }
            />
            <AnimatedText
                text=
                    {
                       getProcessedMultilineTranslatedText(
                            $textStore[ 'join-bamhub-subtitle-label' ]
                            || 'Where "My friend is your friend" comes into its own',
                            $languageTagStore
                            )
                    }
                />
        </p>
    </div>
    <button class="call-to-action-button" on:click={ () => navigate( `/${ $languageTagStore }/become-member` ) }>
        { @html getLocalizedText( $textStore[ 'become-a-member-button' ] || "Become Member", $languageTagStore ) }
    </button>
</section>
