<script>
    // -- IMPORTS
    
    import Modal from '../../../../../ADMIN/src/lib/component/element/Modal.svelte';
    import BlockManager from '../../../../../ADMIN/src/lib/component/element/BlockManager.svelte';
	import EditFoundersPage from './../../../../../ADMIN/src/lib/page/manager/EditFoundersPage.svelte';
    import { blockTypeArray, getProcessedMultilineTranslatedText } from '$lib/base.js';
    import { getImagePath } from '$lib/filePath';
    import { getLocalizedText } from 'senselogic-gist';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import { pageStore } from '../../store/pageDataStore';
    import { fetchData } from '../../base';
    import { onMount } from 'svelte';
    import ContactButtons from '../element/ContactButtons.svelte';
    import urlStore from '$src/lib/store/urlStore';
    import FullLoading from '../Layout/FullLoading.svelte';
    import Media from '../element/Media.svelte';

    // -- VARIABLES

    export let founderArray = [];
    let founderImage = $pageStore.block.FoundersSection;
    let isLoading = true;
    let blockBeingEdited = {};
    let isEditBlockModalOpen = false;
    let isEditFounderModalOpen = false;
    let founderEditedId = '';

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let data =
                await fetchData(
                    '/api/block/get-block-by-id',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                id: "M1liEVhZaYMJJH-eglhn2Q",
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            $pageStore.block.FoundersSection = data.block;
            founderImage = data.block;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // ~~

    async function saveBlockData(
        )
    {
        isEditBlockModalOpen = false;
        loadData();
    }

    // ~~

    function handleEditBlock(
        block 
        )
    {
        blockBeingEdited = block;
        isEditBlockModalOpen = true;
    }

    // ~~

    function handleOpenFounderModal(
        founder
        )
    {
        founderEditedId = founder.id;
        isEditFounderModalOpen = true;
    }

    // ~~

    async function handleSaveFounderData(
        )
    {
        let founderData =
                await fetchData(
                    '/api/founder/get-by-id',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                id: founderEditedId
                            },
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

        let updatedFounder = founderData.founderMember

        founderArray = 
            founderArray.map(
                founder => 
                    founder.id === founderEditedId
                        ? updatedFounder
                        : founder
            );

        isEditFounderModalOpen = false;
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
           if( founderImage === undefined ) 
            {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .founders-section
    {
        border: 1px solid borderColor;
        padding: 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( desktop )
        {
            margin: 0;
            max-width: 77vw;
            border: none;
            padding-bottom: 2.5rem;

            gap: 4rem;
        }
    }

    .founders-container
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;
    }

    .founders-content
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            width: 50%;

            flex: 1;
        }
    }

    .founders-image-container
    {
        height: 100%;
        width: 100%;
        aspect-ratio: 4 / 3;

        +media( desktop )
        {
            height: 50rem;
        }
    }

    .founders-content-heading-title
    {
        line-height: 3rem;
        font-size: 2rem;
        font-weight: 600;
        color: whiteColor;
    }

    .founders-content-heading-subtitle
    {
        line-height: 2rem;
        font-size: 1.25rem;
        color: #E6E9EE;
    }

    .founders-content-wrapper
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .description-text
    {
        position: relative;

        overflow: hidden;

        display: -webkit-box;
        -webkit-box-orient: vertical;

        transition: all .3s;
    }
</style>

<Modal
    bind:showModal={ isEditBlockModalOpen }
    modalConfirmButtonLabel="Save"
    modalType=""
    hasFooterButtons={ false }
    modalTitle="Edit Block"
>
    <BlockManager
        blockData={ blockBeingEdited }
        blockTypeArray={ blockTypeArray }
        on:closeModal={ () => isEditBlockModalOpen = false }
        on:blockSaved={ saveBlockData }
    />
</Modal>

<Modal
    bind:showModal={ isEditFounderModalOpen }
    modalConfirmButtonLabel="Save"
    modalType=""
    hasFooterButtons={ false }
    modalTitle="Founder"
>
    <EditFoundersPage
        founderId={ founderEditedId }
        on:updateSuccess={ handleSaveFounderData }
    />
</Modal>

<section class="founders-section" id="founders">
    {#if isLoading }
        <FullLoading />
    {:else}
        <span class="heading-title color-light-gold" class:is-admin-enabled={ $enabledAdminView }>
            <AnimatedText text={ getProcessedMultilineTranslatedText( $textStore[ 'our-team-label' ], $languageTagStore ) } />
            <AdminEditButton
                type="text"
                slug="our-team-label"
                inset="-2.5rem -1rem auto auto"
                textToEdit={ $textStore[ 'our-team-label' ] }
                title={ "our-team-label" }
            />
        </span>

        <div class="founders-container" class:is-admin-enabled={ $enabledAdminView }>
            { #key founderImage }
                <div class="founders-image-container">
                    <Media
                        mediaClass="founders-image"
                        mediaPath={ founderImage?.mediaPath ?? founderImage?.mediaPathArray }
                        isHeroSection={ true }
                    />
                </div>
            { /key }

            <AdminEditButton
                handleAdminButton={ () => handleEditBlock( founderImage ) }
                title={ "Founder Image" }
            />

            <div class="founders-content-wrapper">
                { #key founderArray }
                    {#each founderArray as founder }
                        <div class="founders-content" class:is-admin-enabled={ $enabledAdminView }>
                            <AdminEditButton
                                handleAdminButton={ () => handleOpenFounderModal( founder ) }
                                title={ founder.name }
                            />

                            <div>
                                <p class="founders-content-heading-title"><AnimatedText text={ founder.name }/></p>
                                <p class="founders-content-heading-subtitle"><AnimatedText text={ getProcessedMultilineTranslatedText( founder.position, $languageTagStore ) }/></p>
                            </div>

                            <AnimatedContainer>
                                <div class="description-text">
                                    { @html getProcessedMultilineTranslatedText( founder.biography || '', $languageTagStore ) }
                                </div>
                            </AnimatedContainer>

                            <AnimatedContainer>
                                <ContactButtons
                                    emailAddress={ founder.emailAddress }
                                    linkedinLink={ founder.linkedinLink }
                                />
                            </AnimatedContainer>
                        </div>
                    {/each}
                { /key }
            </div>
        </div>
    {/if}
</section>
