<script>
    // -- IMPORTS
    
	import { isLoadingStore } from './../../store/isLoadingStore.js';
    import { fade, slide } from 'svelte/transition';
    import Button from './Button.svelte';
    import Loading from './Loading.svelte';

    // -- CONSTANTS

    const onSaveBlockEvent = new CustomEvent( 'on-save-block' );

    // -- VARIABLES

    export let modalType = 'error';
    export let showModal = false;
    export let modalTitle = '';
    export let modalDescription = '';
    export let modalConfirmButtonLabel = 'Confirm';
    export let modalCancelButtonLabel = 'Cancel';
    export let modalButtonAction = null;
    export let isSubmitting = false;
    export let hasFooterButtons = true;

    let isConfirmed = false;

    // -- FUNCTIONS

    function handleCloseModal()
    {
        showModal = false;
    }

    // ~~

    function handleSaveBlock(
        )
    {
        window.dispatchEvent( onSaveBlockEvent );
    }

    // -- STATEMENTS

    $: 
    {
        if ( showModal ) 
        {
            document.body.classList.add("no-scroll");
        } 
        else 
        {
            document.body.classList.remove("no-scroll");
        }
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    .close-confirmation-modal-button:disabled
    .close-confirmation-modal-button[ disabled ]
    {
        cursor: not-allowed;
        pointer-events: all;
    }

    // -- CLASSES

    .modal-overlay
    {
        z-index: 999
        position: fixed;
        top: 0;
        left: 0;

        inset: 0 0;

        background-color: rgba( 0, 0, 0, 0.5 );
    }

    .modal-container
    {
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;

        inset: 0 0;
        height: 100dvh;
        width: 100vw;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-wrapper
    {
        position: relative;

        overflow-y: auto;
        width: 80%;
        max-height: 90vh;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        background-color: darkGreyColor;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.40);

        +media( desktop )
        {
            height: auto;
            width: 50rem;
            max-width: 80%;
            border-radius: .5rem;

            gap: 1.5rem;
        }
    }

    .modal-content-container
    {
        max-width: 100%;
    }

    .modal-header
    {
        z-index: 1001;
        position: sticky;
        top: 0rem;
        left: 0;

        width: 100%;
        border-bottom: 1px solid pureBlackColor;
        padding: 1rem 2rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: darkGreyColor;
    }

    .error-modal-header
    {
        justify-content: flex-end;
    }

    .modal-title
    {
        font-size: 2rem;
        font-weight: 300;
        white-space: nowrap;
        text-align: center;
        color: lightGoldColor;
    }

    .modal-error-title
    {
        width: 100%;
        padding-top: 2rem;

        font-size: 3.5rem;
        text-align: center;
        color: redColor500;
    }

    .close-confirmation-modal-button
    {
        height: 3rem;
        width: 3rem;

        display: flex;
        grid-column: 3;
        justify-content: center;
        justify-self: end;
        align-items: center;
        align-self: flex-end;

        background: transparent;

        cursor: pointer;
    }

    .modal-content
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }

    .modal-description
    {
        width: 100%;
        padding-bottom: 3.5rem;

        line-height: 2.25rem;
        font-size: 1.30rem;
        font-weight: 400;
        text-align: center;
        color: lightGreyColor;
    }

    .icon,
    .close-confirmation-modal-button
    {
        flex-shrink: 0;
    }

    .modal-footer-container
    {
        z-index: 1001;
        position: sticky;
        bottom: 0;

        width: 100%;
        border-top: 1px solid pureBlackColor;
        padding: 1.25rem 2rem;

        display: flex;
        gap: 2rem;
        justify-content: space-between;
        align-items: center;

        background-color: darkGreyColor;
    }

    .modal-footer-container button
    {
        height: 3rem;
        border-radius: 0.5rem;
        padding: 0 1.5rem;

        cursor: pointer;
        transition: .3s ease-in-out;
    }

    .modal-footer-container button:hover
    {
        background-color: darkGreyColor;
    }

    .modal-cancel-button
    {
        padding: 0 1.5rem;

        background-color: darkGreyColor;

        color: grayColor700;
    }

    .modal-cancel-button:hover
    {
        background-color: #000000 !important;
    }

    .modal-confirm-button
    {
        background-color: lightGoldColor;

        font-weight: 700;
        color: grayColor500;
    }
</style>

{#if showModal}
    <div class="modal-overlay" />

    <div class="modal-container">
        <div class='modal-wrapper' transition:slide>
            <div class="modal-content-container">
                <div class="modal-header" class:error-modal-header={ modalType === "error" }>
                    {#if modalType !== "error"}
                        <h3 class="modal-title">
                            { modalTitle }
                        </h3>
                    {/if}

                    <button
                        class="close-confirmation-modal-button"
                        disabled={ $isLoadingStore || isSubmitting }
                        on:click={ handleCloseModal }
                    >
                        <div class="gray-600-x-icon size-300 icon"></div>
                    </button>
                </div>

                {#if modalType === "error" }
                    <h2 class="modal-error-title">
                        { modalTitle }
                    </h2>
                {/if}

                {#if modalDescription }
                    <p class="modal-description">
                        { modalDescription }
                    </p>
                {/if}

                <div>
                    <slot />
                </div>

                <div
                    class="modal-footer-container"
                    class:is-error-modal={ modalType === "error" }
                    >
                    <Button
                        type="button"
                        class="modal-cancel-button"
                        on:click={ handleCloseModal }
                        disabled={ isConfirmed || isSubmitting }
                    >
                        { modalCancelButtonLabel }
                    </Button>
                    <Button
                        variant={ modalType }
                        style={ "border-radius: 0.5rem; font-weight: 700" }
                        disabled={ $isLoadingStore }
                        loading={ $isLoadingStore || isSubmitting }
                        on:click={ modalButtonAction ?? handleSaveBlock }
                    >
                        { modalConfirmButtonLabel }
                    </Button>
                </div>
            </div>

        </div>
    </div>
{/if}
