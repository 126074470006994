<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageCode } from 'senselogic-gist';
    import { onMount } from 'svelte';

    // -- VARIABLES

    export let mediaPath = '';
    export let languageData = ['en'];
    export let metaDescription = '';
    export let metaKeywords = '';
    export let metaTitle;
    export let path;
    export let url;

    let language = languageCode;
    let specificLanguage;

    // -- STATEMENTS

    onMount(
        async () =>
        {
            language = await languageCode;
            specificLanguage = languageData.find(
                ( item ) =>
                {
                    return item.code === languageCode;
                }
                )?.text?.en ?? 'English';
        }
        );
</script>

<svelte:head>
    <meta charset="utf-8"/>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
    <meta name="title" content={ getLocalizedText( metaTitle ?? '' ) }/>
    <meta name="description" content={ getLocalizedText( metaDescription ?? '' ) }/>
    <meta name="keywords" content={ getLocalizedText( metaKeywords ?? '' ) }/>
    <meta name="author" content="GROUPBAM"/>
    <meta name="language" content={ specificLanguage }/>
    <meta name="robots" content="index, follow"/>
    <meta name="revisit-after" content="10 days"/>
    <meta name="format-detection" content="telephone=no"/>
    <meta name="HandheldFriendly" content="true"/>
    <meta name="apple-mobile-web-app-capable" content="yes"/>
    <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
    <meta name="theme-color" content="#FFFFFF"/>
    <meta name="msapplication-config" content="/browserconfig.xml"/>
    <meta name="msapplication-TileColor" content="#FFFFFF"/>
    <meta name="msapplication-TileImage" content={ getImagePath( mediaPath, '' ) }/>
    <meta name="twitter:card" content={ getLocalizedText( metaTitle ?? '' ) }/>
    <meta name="twitter:title" content={ getLocalizedText( metaTitle ?? '' ) }/>
    <meta name="twitter:description" content={ getLocalizedText( metaDescription ?? '' ) }/>
    <meta name="twitter:image" content={ getImagePath( mediaPath, '' ) }/>
    <meta property="og:type" content="website"/>
    <meta property="og:site_name" content="BAMHUB"/>
    <meta property="og:title" content={ getLocalizedText( metaTitle ?? '' ) }/>
    <meta property="og:description" content={ getLocalizedText( metaDescription ?? '' ) }/>
    <meta property="og:url" content={ `${ url }${ language }/${ path }` }/>
    <meta property="og:image" content={ getImagePath(mediaPath, '') }/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content={ getLocalizedText( metaTitle ?? '' ) }/>
    <meta name="twitter:description" content={ getLocalizedText( metaDescription ?? '' ) }/>
    <meta name="twitter:url" content={ `${ url }${ language }/${ path }` }/>
    <meta name="twitter:label" content="Website by"/>
    <meta name="twitter:data" content='Bamhub'/>
    <meta name="twitter:image" content={ getImagePath( mediaPath, '' ) }/>
    <link rel="alternate" hreflang="en" href={ `${ url }en/${ path }` }/>
    <link rel="alternate" hreflang="fr" href={ `${ url }fr/${ path }` }/>
    <link rel="alternate" hreflang="pt" href={ `${ url }pt/${ path }` }/>
    <link rel="alternate" hreflang="es" href={ `${ url }es/${ path }` }/>
    <link rel='icon' type='image/png' href='/favicon.png'>
    <link rel='icon' sizes='192x192' href='/favicon.png'>
    <link rel='apple-touch-icon' href='/favicon.png'>
    <link rel='apple-touch-icon' sizes='76x76' href='/favicon.png'>
    <link rel='apple-touch-icon' sizes='120x120' href='/favicon.png'>
    <link rel='apple-touch-icon' sizes='152x152' href='/favicon.png'>
    <link rel="canonical" href={ `${ url }${ language }/${ path }` }/>
</svelte:head>
