<script>
    // -- IMPORTS

    import DualList from './../../component/element/DualList.svelte';
    import { languageArrayStore } from '$store/languageArrayStore';
    import { toast } from '../../toast';
    import Button from '../../component/element/Button.svelte';
    import Input from '../../component/element/Input.svelte';
    import Switch from 'senselogic-flow/Switch.svelte';
    import InputLocalizedForm from '../../component/element/InputLocalizedForm.svelte';
    import { fetchData, addFileArrayOnObject, checkRequiredIsFilled } from '../../base';
    import { getLocalizedText, logError, getJsonText } from 'senselogic-gist';
    import { createEventDispatcher, onMount } from 'svelte';
    import Loading from '../../component/element/Loading.svelte';
    import FileInput from '../../component/element/FileInput/FileInput.svelte';
    import { isLoadingStore } from '../../store/isLoadingStore';

    // -- VARIABLES

    export let hubId;
    export let isUploadingImage = false;
    export let isUploadingHeadingImage = false;

    let form;
    let hubFormData = {};
    let hubRequiredFieldArray = ['name', 'title'];
    let dispatch = createEventDispatcher()

    let hubPageData = {};
    let pageRequiredFieldArray = ['title', 'description' ];

    let isLoading = true;
    let isHovered = false;
    let isSubmitting = false;

    let folderPath = 'Hub/' + hubFormData.slug + '/image/';
    let coverFileArray = [];
    let headingFileArray = [];

    let hubServiceArray = [];
    
    let serviceIncludedArray;
    let serviceByRequestArray;
    let serviceByLocationArray;
    
    let serviceNotIncludedArray;
    let serviceNotIncludedByRequestArray;
    let serviceNotIncludedByLocationArray;

    let serviceIncludedElement;
    let serviceByRequestElement;
    let serviceByLocationElement;

    // -- FUNCTIONS

    function setDefaultFileArray(
        )
    {
        if ( hubFormData?.mediaPath )
        {
            coverFileArray = [ hubFormData.mediaPath ];
            headingFileArray = [ hubPageData.mediaPath ];
        }
        else
        {
            coverFileArray = hubFormData.mediaPathArray ?? [];
            headingFileArray = hubPageData.mediaPathArray ?? [];
        }
    }

    // ~~

    async function loadData(
        )
        {
            try
            {
                let hubData =
                    await fetchData(
                        '/api/hub/get-by-id',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    id: hubId
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    );

                let pageData =
                    await fetchData(
                        '/api/page/get-by-slug',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    slug: hubData.hub.slug
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    );

                let hubService =
                    await fetchData(
                        '/api/hub-service/get-admin',
                        {
                            method: 'POST',
                            body: JSON.stringify( {} ),
                        }
                    );

                hubServiceArray = hubService.hubArray;
                hubFormData = hubData.hub;
                hubPageData = pageData.page;

                serviceIncludedArray = hubServiceArray.filter(( service ) => hubFormData.serviceIncludedArray.includes( service.id ));
                serviceByRequestArray = hubServiceArray.filter(( service ) => hubFormData.serviceRequestArray.includes( service.id ));
                serviceByLocationArray = hubServiceArray.filter(( service ) => hubFormData.serviceByLocationArray.includes( service.id ));
                
                serviceNotIncludedArray = hubServiceArray.filter(( service ) => !hubFormData.serviceIncludedArray.includes( service.id ));
                serviceNotIncludedByRequestArray = hubServiceArray.filter(( service ) => !hubFormData.serviceRequestArray.includes( service.id ));
                serviceNotIncludedByLocationArray = hubServiceArray.filter(( service ) => !hubFormData.serviceByLocationArray.includes( service.id ));
            }
            catch ( error )
            {
                logError( error );
            }
            finally
            {
                isLoading = false;
                setDefaultFileArray();
            }
        }

    // ~~

    async function handleUpdateHubServices()
    {
        let selectedServicesByRequest = serviceByRequestElement.options;
        hubFormData.serviceRequestArray = [];

        for( let selectedService of selectedServicesByRequest )
        {
            hubFormData.serviceRequestArray.push( selectedService.value );
        }

        let selectedServicesIncluded = serviceIncludedElement.options;
        hubFormData.serviceIncludedArray = [];

        for( let selectedService of selectedServicesIncluded )
        {
            hubFormData.serviceIncludedArray.push( selectedService.value );
        }

        let selectedServicesByLocation = serviceByLocationElement.options;
        hubFormData.serviceByLocationArray = [];

        for( let selectedService of selectedServicesByLocation )
        {
            hubFormData.serviceByLocationArray.push( selectedService.value );
        }
    }

    // ~~

    function handleFormData(
        eventTarget,
        formObject,
        fileArray
        )
    {
        let newFormData = new FormData();

        formObject = addFileArrayOnObject( formObject, fileArray );

        for ( let [ key, value ] of Object.entries( formObject ) )
        {
            if ( key.includes( 'Array' ) )
            {
                newFormData.append( key, getJsonText( value ) )
            }
            else
            {
                newFormData.append( key, value )
            }
        }

        return newFormData;
    }

    // ~~

    async function handleSubmit(
        event
        )
    {
        try
        {
            $isLoadingStore = true;

            let eventTarget = event.target;

            hubPageData = {
                ...hubPageData,
                slug: hubFormData.slug,
                route: `/${ hubFormData.slug }`,
                typeSlug: 'hub',
                number: hubFormData.number,
                isActive: hubFormData.isActive,
                imageAlt: hubPageData.title
            }

            handleUpdateHubServices();

            let hubErrorArray = checkRequiredIsFilled( hubFormData, hubRequiredFieldArray );
            let pageErrorArray = checkRequiredIsFilled( hubPageData, pageRequiredFieldArray );

            if( pageErrorArray.length > 0 || hubErrorArray.length > 0 )
            {
                return;
            }

            let formData = handleFormData( eventTarget, hubFormData, coverFileArray );
            let pageFormData = handleFormData( eventTarget, hubPageData, headingFileArray );

            let responseHub =
                await fetchData(
                    '/api/update-hub/' + hubId,
                    {
                        method: 'POST',
                        body: formData,
                        credentials: 'include'
                    }
                );

            let responsePage =
                await fetchData(
                    '/api/page/set-page/' + hubPageData.id,
                    {
                        method: 'POST',
                        body: pageFormData,
                        credentials: 'include'
                    }
                );

            if ( responseHub.error || responsePage.error )
            {
                toast(
                    {
                        variant: 'error',
                        text: responseHub.error || responsePage.error
                    }
                    );
            }
            else
            {
                toast(
                    {
                        variant: 'success',
                        text: responseHub.message
                    }
                    );
            }

            dispatch( 'hubEdited' )
        }
        catch ( error )
        {
            toast(
                    {
                        variant: 'error',
                        text: error
                    }
                    );
        }
        finally
        {
            $isLoadingStore = false;
            setDefaultFileArray();
        }
    }

    // ~~

    function handleToggleHover(
        )
    {
        isHovered = !isHovered;
    }

    // ~~

    function handleUpdateHubFormLocalized(
        event
        )
    {
        hubFormData[ event.detail.name ] = JSON.stringify( event.detail.text );
    }

    // ~~

    function handleUpdatePageFormLocalized(
        event
        )
    {
        hubPageData[ event.detail.name ] = JSON.stringify( event.detail.text );
    }

    // ~~

    function customSubmit(
        event
        )
    {
        if ( form )
        {
            let fakeSubmitEvent = new Event('submit', { bubbles: true, cancelable: true } );
            form.dispatchEvent( fakeSubmitEvent );
        }
    }

    // -- STATEMENTS

    onMount(
        async () =>
        {
            await loadData();
            setDefaultFileArray();

            window.addEventListener(
                'on-save-block',
                customSubmit
                );

            return () => {
                window.removeEventListener( 'on-save-block', customSubmit );
            }
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    form
    {
        width: 100%;
        padding: 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        background-color: darkGreyColor;

        +media( tablet )
        {
            padding: 1rem 2.5rem;
        }

        +media( desktop )
        {
            width: 100%;
        }
    }

    // -- CLASSES

    .forms-container
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
    }

    .form-container
    {
        width: 100%;
        padding: 1.5rem 0;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }

    .cards-section
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .admin-page-navigation
    {
        position: fixed;

        inset: 8rem 2rem auto auto;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .forms-container
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
    }

    .form-container
    {
        width: 100%;
        padding: 1.5rem 0;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }

    .cards-section
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .admin-page-navigation
    {
        position: fixed;

        inset: 8rem 2rem auto auto;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .card-form-inputs-container
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
</style>

{#if isLoading }
    <Loading />
{:else}
    <div id="hubs" class="cards-section">
        <div class="forms-container">
            <div class="form-container" >
                <form
                    class="card-form"
                    bind:this={ form }
                    on:submit|preventDefault={ handleSubmit }
                >
                    <div class="card-form-inputs-container">
                        <Input label="Slug" id="slug" bind:value={ hubFormData[ 'slug' ] } fullWidth/>
                        <InputLocalizedForm
                            placeholder="Name"
                            name="name"
                            itemsString={ hubFormData[ 'name' ] }
                            languageArray={ $languageArrayStore }
                            on:update={ handleUpdateHubFormLocalized }
                        />
                        <InputLocalizedForm
                            placeholder="Cover Title"
                            name="title"
                            itemsString={ hubFormData[ 'title' ] }
                            languageArray={ $languageArrayStore }
                            on:update={ handleUpdateHubFormLocalized }
                        />
                        <InputLocalizedForm
                            placeholder="Heading Title"
                            name="title"
                            itemsString={ hubPageData[ 'title' ] }
                            languageArray={ $languageArrayStore }
                            on:update={ handleUpdatePageFormLocalized }
                        />
                        <InputLocalizedForm
                            isMultiLine
                            placeholder="Description"
                            name="description"
                            itemsString={ hubPageData[ 'description' ] }
                            languageArray={ $languageArrayStore }
                            on:update={ handleUpdatePageFormLocalized }
                        />
                        <Input type="number" label="Number" id="number" bind:value={ hubFormData[ 'number' ] } fullWidth/>

                        <fieldset>
                            <legend class="color-light-gold font-size-125 margin-bottom-100 text-transform-uppercase">Cover media</legend>
                            <FileInput
                                bind:fileArray={ coverFileArray }
                                bind:isUploadingImage={ isUploadingImage }
                                fileInputName="image-path"
                                acceptedType="image/*, video/*"
                                maxFileCount={ 5 }
                                filePath={ folderPath }
                            />
                        </fieldset>

                        <fieldset>
                            <legend class="color-light-gold font-size-125 margin-bottom-100 text-transform-uppercase">Heading media</legend>
                            <FileInput
                                bind:fileArray={ headingFileArray }
                                bind:isUploadingImage={ isUploadingHeadingImage }
                                fileInputName="image-path"
                                acceptedType="image/*, video/*"
                                maxFileCount={ 5 }
                                filePath={ folderPath }
                            />
                        </fieldset>

                        <DualList
                            bind:selectedListElement={ serviceIncludedElement }
                            selectedListArray={ serviceIncludedArray }
                            unselectedListArray={ serviceNotIncludedArray }
                            titleColumnName="title"
                            dualListTitle="Included Services"
                        />

                        <DualList
                            bind:selectedListElement={ serviceByRequestElement }
                            selectedListArray={ serviceByRequestArray }
                            unselectedListArray={ serviceNotIncludedByRequestArray }
                            titleColumnName="title"
                            dualListTitle="Services by request"
                        />

                        <DualList
                            bind:selectedListElement={ serviceByLocationElement }
                            selectedListArray={ serviceByLocationArray }
                            unselectedListArray={ serviceNotIncludedByLocationArray }
                            titleColumnName="title"
                            dualListTitle="Services by Location"
                        />

                        <fieldset style="align-self: center;">
                            <p>Active</p>
                            <Switch
                                bind:value={ hubFormData[ 'isActive' ] }
                            />
                        </fieldset>
                    </div>
                </form>
            </div>
        </div>
    </div>
{/if}
