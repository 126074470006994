<script>
    // -- IMPORTS

    export let size = '1.5rem';
    export let isCentered = false;
    export let color = '#D2D2D2';
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .button-loading-indicator
    {
        display: flex;

        visibility: visible;

        color: rgba(0, 0, 0, 0.26);
    }

    .circular-progress-root
    {
        height: 1rem;
        width: 1rem;

        display: inline-block;

        animation: 1.4s linear 0s infinite normal none running animation-spin;
    }

    .circular-progress-circle
    {
        stroke-dasharray: 80px, 200px;
        stroke-dashoffset: 0;
        animation: 1.4s ease-in-out 0s infinite normal none running animation-spin-dashoffset;
    }

    .centered
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% );
    }

    @keyframes animation-spin-dashoffset
    {
        0%
        {
            stroke-dasharray: 1px, 200px;
            stroke-dashoffset: 0;
        }

        50%
        {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -15px;
        }

        100%
        {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -125px;
        }
    }

    @keyframes animation-spin
    {
        0%
        {
            transform: rotate(0deg);
        }

        100%
        {
            transform: rotate(360deg);
        }
    }
</style>

<span class="button-loading-indicator button-loading-indicator-center" class:centered={isCentered}>
    <span
        class="circular-progress-root"
        role="progressbar"
        aria-labelledby=":r1q:"
        style={ `width: ${ size }; height: ${ size };` }
    >
        <svg class="circular-progress-svg" viewBox="22 22 44 44">
            <circle stroke={ color } class="circular-progress-circle" cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6">
            </circle>
        </svg>
    </span>
</span>
