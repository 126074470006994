<script>
    // -- IMPORTS

    import { getJsonText, getLocalizedText } from 'senselogic-gist';
    import { languageArrayStore } from '$lib/store/languageArrayStore';
    import { toast } from '../../toast';
    import Button from '../element/Button.svelte';
    import InputLocalizedForm from '../element/InputLocalizedForm.svelte';
    import Select from '../element/Select.svelte';
    import ArrayEditor from '../element/ArrayEditor.svelte';
    import { fetchData, addFileArrayOnObject, checkRequiredIsFilled } from '../../base';
    import { createEventDispatcher, onMount } from 'svelte';
    import FileInput from '../element/FileInput/FileInput.svelte';
    import { isLoadingStore } from '../../store/isLoadingStore';

    // -- CONSTANTS

    let multilingualKeyArray =
        [
            'text',
            'title'
        ];

    let imageSideOptionArray =
        [ 'left', 'right' ];

    // -- VARIABLES

    export let serviceData =
        {
            title: '',
            iconPath: '',
            mediaPath: null,
            imageSide: 'right',
            mediaPathArray: [],
            textArray: [],
            slug: '',
        };

    export let isUploadingImage = false;

    let form;
    let folderPath = 'hub_service/' + getLocalizedText( serviceData.slug.toLowerCase()) || 'new' + '/';
    let fileArray = [];
    let requiredFieldArray = ['title'];

    let dispatch = createEventDispatcher();
    let serviceForm = serviceData;
    let isSubmitting = false;

    // -- FUNCTIONS

    function handleUpdateLocalizedInput(
        event
        )
    {
        serviceForm[ event.detail.name ] = JSON.stringify( event.detail.text );
    }

    // ~~

    function setDefaultFileArray(
        )
    {
        if ( serviceData?.mediaPath !== null )
        {
            fileArray = [ serviceData.mediaPath ];
        }
        else
        {
            fileArray = serviceData.mediaPathArray ?? [];
        }
    }

    // ~~

    function handleSaveTextArray( event )
    {
        serviceForm[ 'textArray' ] = JSON.parse( event.detail.textArray );
    }

    // ~~

    function handleCancel(
        )
    {
        dispatch( 'closeModal' );
    }

    // ~~

    async function handleSubmit( event )
    {
        $isLoadingStore = true;

        try
        {
            if ( !event )
            {
                console.log('evento nao existe')
                return;
            }
            let formData = new FormData();

            serviceForm  = addFileArrayOnObject( serviceForm, fileArray );

            serviceForm = {
                ...serviceForm,
                slug: getLocalizedText( serviceForm.title.toLowerCase().replaceAll( ' ', '-' ) ),
            };

            let errorArray = checkRequiredIsFilled( serviceForm, requiredFieldArray );

            if( errorArray.length > 0 )
            {
                return;
            }

            for ( let [ key, value ] of Object.entries( serviceForm ) )
            {
                if ( key.includes( 'Array' ) )
                {
                    formData.append( key, getJsonText( value ) )
                }
                else
                {
                    formData.append( key, value )
                }
            }

            let response =
                await fetchData(
                    serviceData.id ? '/api/hub-service/edit/' + serviceData.id : '/api/hub-service/add',
                    {
                        method: 'POST',
                        body: formData,
                        credentials: 'include',
                    }
                    )

            if ( response.error )
            {
                toast(
                    {
                        variant: 'error',
                        text: 'Something went wrong'
                    }
                    );
            }
            else if( response.authError )
            {
                toast(
                    {
                        variant: 'error',
                        text: response.authError
                    }
                    );
            }
            else
            {
                toast(
                    {
                        variant: 'success',
                        text: response.message
                    }
                    );
            }

            dispatch('blockSaved');
        }
        catch ( error )
        {
            toast(
                {
                    variant: 'error',
                    text: error
                }
                );
        }
        finally
        {
            setDefaultFileArray();
            $isLoadingStore = false;
        }
    }

    // ~~

    function customSubmit(
        event
        )
    {
        if ( form )
        {
            let fakeSubmitEvent = new Event('submit', { bubbles: true, cancelable: true } );
            form.dispatchEvent( fakeSubmitEvent );
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            setDefaultFileArray();

            window.addEventListener(
                'on-save-block',
                customSubmit
                );

            return () => {
                window.removeEventListener( 'on-save-block', customSubmit );
            }
        }
        );

    $: isSubmitting
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';
    @import '../../../element.styl';

    // -- ELEMENTS

    form
    {
        width: 100%;
        padding: 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        +media( tablet )
        {
            padding: 1rem 2.5rem;
        }

        +media( desktop )
        {
            padding: 1.5rem 3rem;
        }
    }

    option
    {
        color: lightGreyColor;
    }

    // -- CLASSES

    .card-form-inputs-container

    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .form-items
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .block-section
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .buttons-container
    {
        margin-top: 1rem;
        width: 100%;

        display: flex;
        gap: 1rem;
    }
</style>

<form
    on:submit|preventDefault={ handleSubmit }
    bind:this={ form }
>
    <div class="card-form-inputs-container">
        <div class="form-items">
            {#each Object.entries( serviceData ) as [ key, value ]  }
                {#if key === 'id' || key === 'pageId' }
                     { '' }
                {:else if multilingualKeyArray.includes( key ) }
                    <div class="block-section">
                        <InputLocalizedForm
                            isMultiLine
                            itemsString={ serviceForm[ key ] }
                            languageArray={ $languageArrayStore }
                            name={ key }
                            on:update={ handleUpdateLocalizedInput }
                            placeholder={ key }
                        />
                    </div>
                {:else if key === 'imageSide' }
                    <div class="block-section">
                        <Select
                            name={ key }
                            label={ key }
                            bind:value={ serviceForm[ key ] }
                            fullWidth
                        >
                            {#each imageSideOptionArray as imageSideOption }
                                <option value={ imageSideOption } selected={ imageSideOption === serviceForm[ key ] }>
                                    { imageSideOption }
                                </option>
                            {/each}
                        </Select>
                    </div>
                {:else if key === 'mediaPath' }
                    <div class="block-section">
                        <h2>Media</h2>
                        <FileInput
                            bind:fileArray={ fileArray }
                            bind:isUploadingImage={ isUploadingImage }
                            fileInputName="image-path"
                            acceptedType="image/*, video/*"
                            maxFileCount={ 5 }
                            filePath={ folderPath }
                        />
                    </div>
                {:else if key === 'textArray' }
                    <div class="block-section">
                        <h3>Service Description | Service Benefits</h3>
                        <ArrayEditor bind:data={ serviceForm[ key ] } on:saveTextArray={ handleSaveTextArray }/>
                    </div>
                {/if}
            {/each}
        </div>
    </div>
</form>
