<script>
    // -- IMPORTS

    import { link, Link, navigate } from 'svelte-routing';
    import { getLocalizedText } from 'senselogic-gist';
    import { enabledAdminView } from '$lib/store/adminStore';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import page from '../../store/pageStore';
    import { textStore } from '$lib/store/textStore';
    import Button from '../element/Button.svelte';
    import LanguageToggler from '../header/LanguageToggler.svelte';
    import MobileNavigationIcon from '../header/MobileNavigationIcon.svelte';
    import AdminEditButton from '../element/AdminEditButton.svelte';
    import { user } from '../../store/userStore';
    import Switch from '../element/Switch.svelte';

    // -- VARIABLES

    let isMobileNavigationOpen = false;
    let isMobile = window.navigator.userAgent.includes( 'iPhone' )
                   || window.navigator.userAgent.includes( 'Android' );

    // -- FUNCTIONS

    function handleMobileNavigationButtonClick()
    {
        isMobileNavigationOpen = !isMobileNavigationOpen;
    }

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    header
    {
        z-index: 999;
        position: sticky;
        top: 0;

        width: 100%;
        max-width: 100vw;
        border-bottom: 2px solid #2a2a2a;
        padding: 1rem 2rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: darkGreyColor;
    }

    // -- CLASSES

    .header-logo
    {
        max-width: 12rem;

        object-fit: cover;
    }

    .header-navigation
    {
        min-width: 61vw;

        +media( smaller-desktop )
        {
            position: fixed;

            overflow: scroll;
            inset: 0 200%;
            height: 100vh;
            width: 100vw;

            display: flex;
            flex-direction: column;

            background-color: darkGreyColor;

            transition: inset 350ms ease-in-out;
            overscroll-behavior: contain;
        }
    }

    .header-navigation.is-open
    {
        +media( smaller-desktop )
        {
            inset: 0 0;
        }
    }

    .header-navigation-links-list
    {
        min-height: max-content;
        width: 100%;

        display: flex;
        gap: 2.5rem;
        justify-content: space-between;
        align-items: center;

        +media( smaller-desktop )
        {
            margin-top: 5rem;
            padding: 1rem 2rem;

            flex-direction: column;
            gap: 1rem;
            align-items: flex-start;
        }

        +media( desktop )
        {
            margin-bottom: 0;

            gap: 1rem;
        }
    }

    .header-navigation-link-container
    {
        margin: 0;
        padding: 0.75rem 0.75rem;

        +media( smaller-desktop )
        {
            width: 100%;
            border-bottom: 1px solid #2A2A2A;
            padding: 1rem 0;
        }
    }

    :global( .header-navigation-link )
    {
        font-size: 1rem;
        font-weight: 250;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: lightGreyColor;

        cursor: pointer;
        transition: all 0.1s ease-in-out;

        +media( smaller-desktop )
        {
            line-height: 2rem;
            font-size: 1.5rem;
        }
    }

    :global( .header-navigation-link:hover )
    {
        color: lightGoldColor;
    }

    .mobile-navigation-button
    {
        outline: none;
        height: 3rem;
        width: 3rem;
        border: none;

        display: none;

        background-color: unset;

        cursor: pointer;

        +media( smaller-desktop )
        {
            z-index: 999;

            display: block;
        }
    }

    .is-mobile
    {
        padding-bottom: 15vh;
    }
</style>

<header>
    <Link
        to="/{ $languageTagStore }"
        on:click={ () => isMobileNavigationOpen = false }
    >
        <img class="header-logo" src="/image/icon/logo.svg" alt="logo"/>
    </Link>
    <nav class="header-navigation" class:is-open={ isMobileNavigationOpen } class:is-mobile={ isMobile }>
        <ul class="header-navigation-links-list">
            { #key $textStore[ 'team-menu-label' ] }
                <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                    <Link
                        class="header-navigation-link"
                        to="/{ $languageTagStore }/team"
                        on:click={ () => isMobileNavigationOpen = false }
                    >
                        { getLocalizedText( $textStore[ 'team-menu-label' ], $languageTagStore ) }
                    </Link>

                    <AdminEditButton
                        isMultiline={ false }
                        type="text"
                        slug="team-menu-label"
                        textToEdit={ $textStore[ 'team-menu-label' ] }
                    />
                </li>
            { /key }
            
            { #key $textStore[ 'networking-menu-label' ] }
                <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                    <Link
                        class="header-navigation-link"
                        to="/{ $languageTagStore }/networking"
                        on:click={ () => isMobileNavigationOpen = false }
                    >
                        { getLocalizedText( $textStore[ 'networking-menu-label' ], $languageTagStore ) }
                    </Link>

                    <AdminEditButton
                        isMultiline={ false }
                        type="text"
                        slug="networking-menu-label"
                        textToEdit={ $textStore[ 'networking-menu-label' ] }
                    />
                </li>
            { /key }

            { #key $textStore[ 'partners-bamhub-menu-label' ] }
                <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                    <Link
                        class="header-navigation-link"
                        to="/{ $languageTagStore }/partners"
                        on:click={ () => isMobileNavigationOpen = false }
                    >
                        { getLocalizedText( $textStore[ 'partners-bamhub-menu-label' ], $languageTagStore ) }
                    </Link>

                    <AdminEditButton
                        isMultiline={ false }
                        type="text"
                        slug="partners-bamhub-menu-label"
                        textToEdit={ $textStore[ 'partners-bamhub-menu-label' ] }
                    />
                </li>
            { /key }

            { #key $textStore[ 'hubs-menu-label' ] }
                <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                    <Link
                        class="header-navigation-link"
                        to="/{ $languageTagStore }/hubs"
                        on:click={ () => isMobileNavigationOpen = false }
                    >
                        { getLocalizedText( $textStore[ 'hubs-menu-label' ], $languageTagStore ) }
                    </Link>

                    <AdminEditButton
                        isMultiline={ false }
                        type="text"
                        slug="hubs-menu-label"
                        textToEdit={ $textStore[ 'hubs-menu-label' ] }
                    />
                </li>
            { /key }

            { #key $textStore[ 'what-is-bamhub-menu-label' ] }
                <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                    <Link
                        class="header-navigation-link"
                        to="/{ $languageTagStore }/what-is-bamhub"
                        on:click={ () => isMobileNavigationOpen = false }
                    >
                        { getLocalizedText( $textStore[ 'what-is-bamhub-menu-label' ], $languageTagStore ) }
                    </Link>

                    <AdminEditButton
                        isMultiline={ false }
                        type="text"
                        slug="what-is-bamhub-menu-label"
                        textToEdit={ $textStore[ 'what-is-bamhub-menu-label' ] }
                    />
                </li>
            { /key }

            { #key $textStore[ 'news-menu-label' ] }
                <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                    <Link
                        class="header-navigation-link"
                        to="/{ $languageTagStore }/news"
                        on:click={ () => isMobileNavigationOpen = false }
                    >
                        { getLocalizedText( $textStore[ 'news-menu-label' ], $languageTagStore ) }
                    </Link>

                    <AdminEditButton
                        isMultiline={ false }
                        type="text"
                        slug="news-menu-label"
                        textToEdit={ $textStore[ 'news-menu-label' ] }
                    />
                </li>
            { /key }

            { #key $textStore[ 'faq-menu-label' ] }
                <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                    <Link
                        class="header-navigation-link"
                        to="/{ $languageTagStore }/FAQ"
                        on:click={ () => isMobileNavigationOpen = false }
                    >
                        { getLocalizedText( $textStore[ 'faq-menu-label' ], $languageTagStore ) }
                    </Link>

                    <AdminEditButton
                        isMultiline={ false }
                        type="text"
                        slug="faq-menu-label"
                        textToEdit={ $textStore[ 'faq-menu-label' ] }
                    />
                </li>
            { /key }

            {#key $page.url.href }
                <li class:is-admin-enabled={ $enabledAdminView }>
                    <LanguageToggler bind:isMobileNavigationOpen />

                    <AdminEditButton
                        type="language"
                        title="language"
                    />
                </li>
            {/key}

            {#if $user }
                {#if $user.role === 'authenticated' }
                    <li>
                        <p class="font-size-100 text-align-center">Admin</p>
                        <Switch bind:value={ $enabledAdminView }/>
                    </li>
                {/if}
            {/if}

            { #key $textStore[ 'become-a-member-button' ] }
                <li class:is-admin-enabled={ $enabledAdminView }>
                    <a href={ `/${ $languageTagStore }/become-member` } use:link>
                        <Button
                            fullWidth={ false }
                            on:click={ () => isMobileNavigationOpen = false }
                        >
                            { getLocalizedText( $textStore[ 'become-a-member-button' ], $languageTagStore ) }
                        </Button>
                    </a>

                    <AdminEditButton
                        isMultiline={ false }
                        type="text"
                        slug="become-a-member-button"
                        textToEdit={ $textStore[ 'become-a-member-button' ] }
                    />
                </li>
            { /key }
            <!-- <Button
                fullWidth={ false }
                invertColor
                on:click={ () => goto( `/${ $languageTagStore }/login` ) }
            >
                { getLocalizedText( $textStore[ 'login-label' ], $languageTagStore ) }
            </Button> -->
        </ul>
    </nav>
    <button
        class="mobile-navigation-button"
        aria-label="Menu"
        on:click={ handleMobileNavigationButtonClick }
    >
        <MobileNavigationIcon { isMobileNavigationOpen } />
    </button>
</header>
