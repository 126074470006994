<script>
    // -- IMPORTS

    import Modal from '../../../../ADMIN/src/lib/component/element/Modal.svelte';
    import BlockManager from '../../../../ADMIN/src/lib/component/element/BlockManager.svelte';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { blockTypeArray, getProcessedMultilineTranslatedText } from '$lib/base.js';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import { getLocalizedText } from 'senselogic-gist';
    import PageHeading from '$lib/component/header/PageHeading.svelte';
    import { pageStore } from '../store/pageDataStore';
    import { fetchData } from '../base';
    import { onMount } from 'svelte';
    import urlStore from '../store/urlStore';
    import FullLoading from '../component/Layout/FullLoading.svelte';

    // -- VARIABLES

    let privacyPolicyPage = $pageStore.page.privacyPolicy;
    let privacyPolicyBlockArray = $pageStore.block.privacyPolicy;

    let isLoading = true;
    let isEditBlockModalOpen = false;
    let blockBeingEdited = {}

    async function loadData(
        )
    {
        try
        {
            isLoading = true;

            let [
                    privacyPolicyPageData,
                    privacyPolicyBlockArrayData
                ] =
                await Promise.all(
                    [
                        fetchData(
                            '/api/page/get-by-route',
                            {
                                method: 'POST',
                                body: JSON.stringify(
                                    {
                                        route: '/privacy-policy'
                                    }
                                ),
                                headers: { 'Content-Type': 'application/json' }
                            }
                        ),
                        fetchData(
                            '/api/block/get-by-page-id',
                            {
                                method: 'POST',
                                body: JSON.stringify(
                                    {
                                        pageId: '9cn0HJ30wbONiUz1cCeFCQ'
                                    }
                                ),
                                headers: { 'Content-Type': 'application/json' }
                            }
                        )
                    ]
                )

            privacyPolicyPage = privacyPolicyPageData.page;
            privacyPolicyBlockArray = privacyPolicyBlockArrayData.blockArray;

            $pageStore.page.privacyPolicy = privacyPolicyPage.page;
            $pageStore.block.privacyPolicy = privacyPolicyBlockArray.blockArray;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // ~~

    async function saveBlockData(
        )
    {
        isEditBlockModalOpen = false;
        loadData();
    }

    // ~~

    function handleOpenBlockModal(
        block
        )
    {
        isEditBlockModalOpen = true;
        blockBeingEdited = block;
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if ( privacyPolicyPage === undefined
                 || privacyPolicyBlockArray === undefined
            )
            {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    );

    $: if ( $urlStore.pathname.includes( 'privacy-policy' ) )
    {
        document.title = 'Privacy Policy';
    }

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .privacy-policy-section
    {
        padding: 0rem 1.5rem 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        align-items: center;

        +media( desktop )
        {
            max-width: 62.5vw;
        }
    }

    .privacy-policy-subtitle
    {
        line-height: 2rem;
        font-size: 1.125rem;
        font-weight: 600;
        text-align: left;
        color: lightGrayColor;
    }

    .privacy-policy-text
    {
        line-height: 2rem;
        font-size: 1.125rem;
        letter-spacing: 0.1rem;
        color: lightGrayColor;
    }

    .privacy-policy-block
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            gap: 1rem;
        }
    }

    .numeric-list,
    .numeric-item
    {
        list-style-type: decimal;
    }

    .privacy-policy-text.list,
    .privacy-policy-text.numeric-list,
    .privacy-policy-text.nested-lists
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
</style>

<Modal
    bind:showModal={ isEditBlockModalOpen }
    modalConfirmButtonLabel="Save"
    modalType=""
    hasFooterButtons={ false }
    modalTitle="Edit Block"
>
    <BlockManager
        blockData={ blockBeingEdited }
        blockTypeArray={ blockTypeArray }
        on:closeModal={ () => isEditBlockModalOpen = false }
        on:blockSaved={ saveBlockData }
    />
</Modal>

{#if isLoading }
    <FullLoading />
{:else}
    <div class:is-admin-enabled={ $enabledAdminView }>
        {#if privacyPolicyPage.id }
            <AdminEditButton
                inset="0 5rem auto auto"
                type="page"
                id={ privacyPolicyPage.id }
                title={ getLocalizedText( privacyPolicyPage.title ) }
            />
        {/if}
    
        {#if privacyPolicyPage.title }
            <PageHeading
                title={ privacyPolicyPage.title }
            />
        {/if}
    
        <section class="privacy-policy-section">
            {#if privacyPolicyBlockArray }
                {#each privacyPolicyBlockArray as privacyPolicyBlock }
                    {#if privacyPolicyBlock.typeSlug === 'text' }
                        <div class="privacy-policy-block" class:is-admin-enabled={ $enabledAdminView }>
                            <AdminEditButton
                                inset="0 0rem auto auto"
                                handleAdminButton={ () => handleOpenBlockModal( privacyPolicyBlock ) }
                                title={ getLocalizedText( privacyPolicyBlock.title ) }
                            />
    
    
                            {#if privacyPolicyBlock.title }
                                <p class="privacy-policy-subtitle">{ @html getProcessedMultilineTranslatedText( privacyPolicyBlock.title ) }</p>
                            {/if}
    
                            {#if privacyPolicyBlock.text }
                                <div>
                                    <span class="privacy-policy-text">{@html getProcessedMultilineTranslatedText( privacyPolicyBlock.text ) }</span>
                                </div>
                            {/if}
                        </div>
                    {:else if privacyPolicyBlock.typeSlug.includes('list') }
                        <div class="privacy-policy-block" class:is-admin-enabled={ $enabledAdminView }>
                            <AdminEditButton
                                inset="0 0rem auto auto"
                                handleAdminButton={ () => handleOpenBlockModal( privacyPolicyBlock ) }
                                title={ getLocalizedText( privacyPolicyBlock.title ) }
                            />
    
                            {#if privacyPolicyBlock.title }
                                <p class="privacy-policy-subtitle">{ @html getProcessedMultilineTranslatedText( privacyPolicyBlock.title ) }</p>
                            {/if}
    
                            <span class="privacy-policy-text { privacyPolicyBlock.typeSlug }">
                                {#if privacyPolicyBlock.text }
                                    {@html getProcessedMultilineTranslatedText( privacyPolicyBlock.text ) }
                                {/if}
    
                                <div>
                                    {#each privacyPolicyBlock.textArray as text }
                                        {#if text }
                                            {@html getProcessedMultilineTranslatedText( text ) }
                                        {/if}
                                    {/each}
                                </div>
    
                                {#if privacyPolicyBlock.teaser }
                                    {@html getProcessedMultilineTranslatedText( privacyPolicyBlock.teaser ) }
                                {/if}
                            </span>
                        </div>
                    {/if}
                {/each}
            {/if}
        </section>
    </div>
{/if}
