<script>
    // -- IMPORTS

    import { getJsonText, isNumber, log, getLocalizedText, isArray } from 'senselogic-gist';
    import { languageArrayStore } from '$store/languageArrayStore';
    import { toast } from '../../toast';
    import Input from './Input.svelte';
    import FileInput from './FileInput/FileInput.svelte';
    import InputLocalizedForm from './InputLocalizedForm.svelte';
    import Select from './Select.svelte';
    import ArrayEditor from './ArrayEditor.svelte';
    import { fetchData, addFileArrayOnObject, checkRequiredIsFilled } from '../../base';
    import { createEventDispatcher, onMount } from 'svelte';
    import { isLoadingStore } from '../../store/isLoadingStore';

    // -- CONSTANTS

    let multilingualKeyArray =
        [
            'text',
            'title',
            'teaser',
            'subtitle'
        ];
    let imageHorizontalPositionArray =
        [
            'left',
            '10%',
            '20%',
            '30%',
            '40%',
            'center',
            '60%',
            '70%',
            '80%',
            '90%',
            'right'
        ];
    let imageVerticalPositionArray =
        [
            'top',
            '10%',
            '20%',
            '30%',
            '40%',
            'center',
            '60%',
            '70%',
            '80%',
            '90%',
            'bottom'
        ];
    let imageFitOptionArray =
        [ 'cover', 'contain' ];
    let imageSideOptionArray =
        [ 'left', 'right' ];

    // -- VARIABLES

    export let blockData =
        {
            id : '',
            slug : 'new',
            typeSlug : '',
            number : 0,
            isActive : true,
            title : '',
            subTitle : '',
            description : '',
            mediaPath : '',
            mediaPathArray : [],
            imageAlt : '',
            imagFit : '',
            imageHorizontalPosition : '',
            imageVerticalPosition : '',
        };
    export let blockTypeArray;
    export let isUploadingImage = false;

    let form;
    let dispatch = createEventDispatcher();
    let folderPath = 'block/'
                      + blockData.slug.toLowerCase().replaceAll("-", "_")
                      + '/';
    let fileArray = [];
    let blockForm = blockData;
    let isSubmitting = false;
    let requiredFieldArray = [ 'title', 'typeSlug' ];

    // -- FUNCTIONS

    function setDefaultFileArray(
        )
    {
        if ( blockData?.mediaPath !== null && blockData?.mediaPath !== '' )
        {
            fileArray = [ blockData.mediaPath ];
        }
        else
        {
            fileArray = blockData.mediaPathArray ?? [];
        }
    }

    // ~~

    function handleUpdateLocalizedInput(
        event
        )
    {
        blockForm[ event.detail.name ] = JSON.stringify( event.detail.text );
    }

    // ~~

    function handleSaveTextArray( event )
    {
        blockForm[ 'textArray' ] = JSON.parse( event.detail.textArray );
    }

    // ~~

    function handleCancel(
        )
    {
        dispatch( 'closeModal' );
    }
    // ~~

    async function handleSubmit( event )
    {
        $isLoadingStore = true;

        try
        {
            if ( !event )
            {
                return;
            }

            let formData = new FormData();

            blockForm = addFileArrayOnObject( blockForm, fileArray );

            blockForm = {
                ...blockForm,
                slug: blockForm.slug || getLocalizedText( blockForm.title ).toLowerCase().replaceAll( ' ', '-' ).replaceAll("\"", ""),
            }

            let errorArray = checkRequiredIsFilled( blockForm, requiredFieldArray );

            for ( let [ key, value ] of Object.entries( blockForm ) )
            {
                if ( key.includes( 'Array' ) 
                     || Array.isArray( value ) )
                {
                    formData.append( key, getJsonText( value ) )
                }
                else
                {
                    formData.append( key, value )
                }
            }

            if( errorArray.length > 0 )
            {
                return;
            }

            let response =
                await fetchData(
                    blockForm.id 
                        ? '/api/update-card/BLOCK/' + blockForm.id 
                        : '/api/block/add',
                    {
                        method: 'POST',
                        body: formData,
                        credentials: 'include',
                    }
                    )

            if ( response.error )
            {
                toast(
                    {
                        variant: 'error',
                        text: 'Something went wrong'
                    }
                    );
            }
            else if( response.authError )
            {
                toast(
                    {
                        variant: 'error',
                        text: response.authError
                    }
                    );
            }
            else
            {
                toast(
                    {
                        variant: 'success',
                        text: response.message
                    }
                    );
                dispatch('blockSaved')
            }
        }
        catch ( error )
        {
            toast(
                {
                    variant: 'error',
                    text: 'Error saving your block'
                }
                );
            console.log( error );
        }
        finally
        {
            $isLoadingStore = false;
            setDefaultFileArray();
        }
    }

    // ~~

    function customSubmit(
        event
        )
    {
        if ( form )
        {
            let fakeSubmitEvent = new Event('submit', { bubbles: true, cancelable: true } );
            form.dispatchEvent( fakeSubmitEvent );
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            setDefaultFileArray();

            window.addEventListener(
                'on-save-block',
                customSubmit
                );

            return () => {
                window.removeEventListener( 'on-save-block', customSubmit );
            }
        }
        );

    $: isSubmitting
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';
    @import '../../../element.styl';

    // -- ELEMENTS

    form
    {
        width: 100%;
        padding: 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        +media( tablet )
        {
            padding: 1rem 2.5rem;
        }

        +media( desktop )
        {
            padding: 1.5rem 3rem;
        }
    }

    option
    {
        color: lightGreyColor;
    }

    // -- CLASSES

    .card-form-inputs-container

    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .form-items
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .block-section
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .buttons-container
    {
        margin-top: 1rem;
        width: 100%;

        display: flex;
        gap: 1rem;
    }
</style>

<form
    on:submit|preventDefault={ handleSubmit }
    bind:this={ form }
>
    <div class="card-form-inputs-container">
        <div class="form-items">
            {#each Object.entries( blockData ) as [ key, value ]  }
                {#if key === 'id' || key === 'pageId' }
                    { '' }
                {:else if key === 'slug'}
                    <div class="block-section">
                        <Input
                            label={ key }
                            id="{ key }"
                            bind:value={ blockForm[ key ] }
                            fullWidth
                        />
                    </div>
                {:else if multilingualKeyArray.includes( key ) }
                    <div class="block-section">
                        <InputLocalizedForm
                            isMultiLine={ key !== 'title' }
                            itemsString={ blockForm[ key ] }
                            languageArray={ $languageArrayStore }
                            name={ key }
                            on:update={ handleUpdateLocalizedInput }
                            placeholder={ key }
                        />
                    </div>
                {:else if isNumber( value ) && key === "number" }
                    <div class="block-section">
                        <h2>Block Structure</h2>
                        <Input
                            label={ key }
                            id="{ key }"
                            type="number"
                            bind:value={ blockForm[ key ] }
                            helperText="Number is used to sort the blocks in ascending order"
                            fullWidth
                        />
                    </div>
                {:else if key === 'typeSlug' }
                    <div class="block-section">
                        <Select
                            name={ key }
                            label={ key }
                            bind:value={ blockForm[ key ] }
                            fullWidth
                        >
                            {#each blockTypeArray as blockType }
                                <option value={ blockType.slug } selected={ blockType.slug === blockForm[ key ] }>
                                    { blockType.name }
                                </option>
                            {/each}
                        </Select>
                    </div>
                {:else if key === 'imageHorizontalPosition' && blockForm.typeSlug.includes( 'image' ) }
                    <div class="block-section">
                        <Select
                            name={ key }
                            label={ key }
                            bind:value={ blockForm[ key ] }
                            fullWidth
                        >
                            {#each imageHorizontalPositionArray as imageHorizontalPosition }
                                <option value={ imageHorizontalPosition } selected={ imageHorizontalPosition === blockForm[ key ] }>
                                    { imageHorizontalPosition }
                                </option>
                            {/each}
                        </Select>
                    </div>
                {:else if key === 'imageVerticalPosition' && blockForm.typeSlug.includes( 'image' ) }
                    <div class="block-section">
                        <Select
                            name={ key }
                            label={ key }
                            bind:value={ blockForm[ key ] }
                            fullWidth
                        >
                            {#each imageVerticalPositionArray as imageVerticalPosition }
                                <option value={ imageVerticalPosition } selected={ imageVerticalPosition === blockForm[ key ] }>
                                    { imageVerticalPosition }
                                </option>
                            {/each}
                        </Select>
                    </div>
                {:else if key === 'mediaPath' && blockForm.typeSlug.includes( 'image' ) }
                    <div class="block-section">
                        <h2>Media</h2>
                        <FileInput
                            bind:fileArray={ fileArray }
                            bind:isUploadingImage={ isUploadingImage }
                            fileInputName="image-path"
                            acceptedType="image/*, video/*"
                            maxFileCount={ 5 }
                            filePath={ folderPath }
                        />
                    </div>
                {:else if key === 'imageSide' && blockForm.typeSlug.includes( 'image' ) }
                    <div class="block-section">
                        <Select
                            name={ key }
                            label={ key }
                            bind:value={ blockForm[ key ] }
                            fullWidth
                        >
                            {#each imageSideOptionArray as imageSideOption }
                                <option value={ imageSideOption } selected={ imageSideOption === blockForm[ key ] }>
                                    { imageSideOption }
                                </option>
                            {/each}
                        </Select>
                    </div>
                {:else if key === 'textArray' }
                    <div class="block-section">
                        <h2>Multiple Texts</h2>
                        <ArrayEditor bind:data={ blockForm[ key ] } on:saveTextArray={ handleSaveTextArray }/>
                    </div>
                {/if}
            {/each}
        </div>
    </div>
</form>
