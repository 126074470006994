<script>
    // -- IMPORTS
    
	import EditPartnerCompaniesPage from './../../../../../ADMIN/src/lib/page/PartnerCompanies/EditPartnerCompaniesPage.svelte';
	import Modal from './../../../../../ADMIN/src/lib/component/element/Modal.svelte';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import AdminEditButton from '../element/AdminEditButton.svelte';
    import { enabledAdminView } from '$src/lib/store/adminStore';
    import Media from '../element/Media.svelte';
    import { fetchData, getProcessedMultilineTranslatedText } from '$src/lib/base';
    import PartnerCompanyModal from './partnerCompanyModal.svelte';

    // -- VARIABLES

    export let mediaPath;
    export let text;
    export let title;
    export let id;
    
    let isModalOpen = false;
    let isEditPartnerModalOpen = false;

    // -- FUNCTIONS

    function handleOpenModal(
        )
    {
        isModalOpen = true;
    }

    // ~~

    function handleEditPartnerModal(
        )
    {
        isEditPartnerModalOpen = !isEditPartnerModalOpen;
    }

    // ~~

    async function loadData(
        )
    {
        let partnerCompanyData =
            await fetchData(
                '/api/partner-company/get-by-id/' + id,
                {
                    method: 'POST',
                    body: JSON.stringify(
                        {
                        },
                    ),
                    headers: { 'Content-Type': 'application/json' }
                }
            );

        let partnerCompany = partnerCompanyData.partnerCompany;
        
        text = partnerCompany.text;
        title = partnerCompany.title;
        mediaPath = partnerCompany.mediaPath;
    }

    // ~~

    function handleEditedPartner(
        )
    {
        isEditPartnerModalOpen = false;
        loadData();
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .partner-companies-container
    {
        position: relative;
    }

    .partner-companies-card
    {
        width: 100%;
        padding: 0px 1.25rem;

        background: transparent;

        display: flex;
        flex: 1;
        flex-direction: column;
        flex-shrink: 0;
        gap: 1rem;
        align-items: center;

        transition: all 2s;
        cursor: pointer;
    }

    .partner-companies-card-icon-container
    {
        height: 4.5rem;
        width: 16rem;

        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        justify-content: center;
        align-items: center;
    }

    .partner-companies-card-text
    {
        position: relative;

        overflow: hidden;

        text-align: center;
        color: grayColor400;
        leading-trim: both;
        text-edge: cap;
        line-height: 1.5;
        font-size: 1rem;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;

        width: 100%;

        display: -webkit-box;
        -webkit-box-orient: vertical;

        transition: all .3s;
    }

    :global( .partner-companies-card-icon )
    {
        margin: 0 auto;
        height: 100%;
        width: 100%;

        flex: 1 1 1;
        align-self: stretch;
        object-fit: contain;

        filter: grayscale( 1 );
    }
</style>

<PartnerCompanyModal 
    bind:isModalOpen={ isModalOpen } 
    mediaPath={ mediaPath }
    text={ text }
    title={ title }
/>

<Modal
    bind:showModal={ isEditPartnerModalOpen }
    modalConfirmButtonLabel="Save"
    modalType=""
    hasFooterButtons={ false }
    modalTitle="Partner Company"
>
    <EditPartnerCompaniesPage
        partnerId={ id }
        on:partnerUpdated={ handleEditedPartner }
    />
</Modal>

{ #key [ id, text, title, mediaPath ]  }
    <div class="partner-companies-container">
        <AdminEditButton
            handleAdminButton={ handleEditPartnerModal }
            title={ getLocalizedText( title, $languageTagStore ) }
        />

        <button 
            class="partner-companies-card" 
            class:is-admin-enabled={ $enabledAdminView }
            on:click={ handleOpenModal }
            >
            <div class="partner-companies-card-icon-container">
                {#if mediaPath }
                    <Media
                        mediaClass="partner-companies-card-icon"
                        mediaPath={ mediaPath }
                        mediaSize={ "360" }
                        mediaBackgroundSize={ "contain" }
                    />
                {/if}
            </div>
        
            <div
                class="partner-companies-card-text"
                title={ getLocalizedText( text, $languageTagStore ) }
            >
                {#if text }
                    { @html getProcessedMultilineTranslatedText( text, $languageTagStore ) }
                {/if}
            </div>
        </button>
    </div>
{ /key }
