<script>
  import { onDestroy } from "svelte";

    // -- VARIABLES

    export let mdInput = null;
    export let editorText = '';
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    textarea
    {
        resize: none;

        background-color: darkGrayColor;
    }

    // -- CLASSES

    .text-editor
    {
        width: 100%;

        display: var( --should-display, initial )
    }

    .text-editor-body
    {
        height: 14rem;
        max-height: 14rem;
        width: 100%;
        border-radius: 0.5rem;
        padding: 1rem;

        display: inline-block;

        color: lightGreyColor;
    }

    .text-editor-body:hover
    {
       background-color: pureBlackColor;
    }
</style>

<div class="text-editor">
    <textarea
        class="text-editor-body"
        id="text-input"
        wrap="hard"
        resize={ false }
        rows="8"
        bind:this={ mdInput }
        bind:value={ editorText }
    />
</div>
