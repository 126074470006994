<script>
    // -- IMPORTS

    import { getLocalizedText } from "senselogic-gist";
    import AdminEditButton from "./AdminEditButton.svelte";
    import AnimatedContainer from "./AnimatedContainer.svelte";
    import Media from "./Media.svelte";
    import { enabledAdminView } from "$src/lib/store/adminStore";
    import { languageTagStore } from "$src/lib/store/languageTagStore";
    import { blockTypeArray, fetchData, getProcessedMultilineTranslatedText } from "$src/lib/base";
    import Modal from "../../../../../ADMIN/src/lib/component/element/Modal.svelte";
    import BlockManager from "../../../../../ADMIN/src/lib/component/element/BlockManager.svelte";

    // -- VARIABLES

    export let block = {};
    export let blockIndex = 0;

    let isEditBlockModalOpen = false;
    let blockBeingEdited = block;

    // -- FUNCTIONS

    async function loadData(
        )
    {
        
        let blockData =
            await fetchData(
                '/api/block/get-block-by-id',
                {
                    method: 'POST',
                    body: JSON.stringify(
                        {
                            id: block.id,
                        }
                    ),
                    headers: { 'Content-Type': 'application/json' }
                }
                );

        block = blockData.block;
    }
    
    async function saveBlockData(
        )
    {
        isEditBlockModalOpen = false;
        loadData();
    }
    // ~~

    function handleOpenBlockModal(
        )
    {
        isEditBlockModalOpen = true;
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .what-we-offer-card-item
    {
        position: relative;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .what-we-offer-card-item.reverse
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .what-we-offer-card-item-content
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            display: flex;
            flex: 1 0 0;
            gap: 2rem;
            justify-content: center;
        }
    }

    .what-we-offer-card-item-title
    {
        line-height: 2rem;
        font-size: 1.4375rem;
        font-weight: 500;
        letter-spacing: 0.08625rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 2.5rem;
            font-size: 2rem;
            letter-spacing: 0.16rem;
        }
    }

    .what-we-offer-image-container
    {    
        height: 18.75rem;
        width: 100%;
        aspect-ratio: 1;

        align-self: stretch;

        +media( desktop )
        {
            position: sticky;
            top: 8rem;

            height: 40rem;
            width: 44.375rem;
            aspect-ratio: 3 / 4;
        }
    }

    :global( .what-we-offer-image )
    {
        aspect-ratio: 1;

        align-self: stretch;
        object-fit: cover;

        +media( desktop )
        {
            height: 40rem;
            width: 44.375rem;
            aspect-ratio: 3 / 4;
        }
    }

    .description-text
    {
        display: -webkit-box;
        -webkit-box-orient: vertical;

        max-height: 35rem;
        padding-bottom: 5rem;

        line-height: 2rem;
        font-size: 1.125rem;

        transition: max-height 2.6s cubic-bezier(0, 1, 0, 1);

        +media( desktop )
        {
            line-height: 2.25rem;
            font-size: 1.25rem;
            letter-spacing: 0.03rem;
            color: lightGrayColor;
        }
    }

    .block-item-text-overlay
    {
        position: absolute;
        bottom: 0;
        left: 0;

        height: 10%;
        width: 100%;

        background: linear-gradient( 180deg, rgba(27, 27, 27, 0.00) 0%, #1B1B1B 100% );

        pointer-events: none;
        transition: height 1.2s;
    }
</style>

<Modal
    bind:showModal={ isEditBlockModalOpen }
    modalConfirmButtonLabel="Save"
    modalType=""
    hasFooterButtons={ false }
    modalTitle="Edit Block"
>
    <BlockManager
        blockData={ blockBeingEdited }
        blockTypeArray={ blockTypeArray }
        on:closeModal={ () => isEditBlockModalOpen = false }
        on:blockSaved={ saveBlockData }
    />
</Modal>

{ #key block }
    <!-- <AnimatedContainer style="width: 100%"> -->
        <div 
            id={ block.slug || 'id' }
            class="what-we-offer-card-item { block.imageSide === 'right' ? 'reverse' : '' }" 
            class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                handleAdminButton={ handleOpenBlockModal }
                title={ getLocalizedText( block.title || '', $languageTagStore ) }
            />
            {#if block.mediaPath || block.mediaPathArray }
                <div class="what-we-offer-image-container">
                    <Media
                        mediaPath={ block.mediaPathArray?.length > 0 ? block.mediaPathArray : block.mediaPath }
                        mediaClass="what-we-offer-image"
                        mediaId={ block.id }
                        mediaPosition={{
                            vertical: block.imageVerticalPosition,
                            horizontal: block.imageHorizontalPosition,
                            horizontalArray: block.imageHorizontalPositionArray,
                            verticalArray: block.imageVerticalPositionArray,
                        }}
                    />
                </div>
            {/if}

            <div class="what-we-offer-card-item-content">
                <p class="what-we-offer-card-item-title color-light-gold">{ @html getProcessedMultilineTranslatedText( block.title || '', $languageTagStore ) }</p>

                {#if block.text }
                    <p 
                        class="block-text"
                        id={`block-${ block.id }`}
                    >
                        {@html getProcessedMultilineTranslatedText( block.text, $languageTagStore ) }
                    </p>
                {/if }
            </div>
        </div>
    <!-- </AnimatedContainer> -->
{ /key }