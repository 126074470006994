<script>
    import DualListSelect from './dualList/DualListSelect.svelte';
    import DualList from './DualList.svelte';
    import { getLocalizedText } from 'senselogic-gist';
    import Select from './Select.svelte';
    import Button from './Button.svelte';
    // -- IMPORTS

    import { languageTagStore } from '$lib/store/languageTagStore';

    // -- CONSTANTS

    let moveToButtonStyle = `
        font-size: 12px;
        padding: 0.5rem;
        max-width: 2rem;
    `

    // -- VARIABLES

    export let titleColumnName = 'title';
    export let selectedListArray = [];
    export let unselectedListArray = [];
    export let dualListTitle = 'Dual List';
    export let selectedListElement;
    let unselectedListElement;

    let isHovered = '';

    // -- FUNCTIONS

    function moveToSelect(
        select
        )
    {
        let fromSelect = unselectedListElement;
        let toSelect = selectedListElement;

        if( select == 'selected' )
        {
            fromSelect = selectedListElement;
            toSelect = unselectedListElement;
        }

        let fromSelectOptions = Array.from( fromSelect.options );

        if( fromSelectOptions.length > 0 )
        {
            let counter = fromSelect.options.length - 1;

            for( ; counter >- 1; counter-- )
            {
                if( fromSelect.options[ counter ].selected )
                {
                     toSelect.add( fromSelect.options[ counter ] );
                }                
            }
        }

        let checkedOptionArray = document.querySelectorAll( 'option:checked' )
                                    .forEach(option => option.selected = false);;
    }

    // ~~

    function handleToggleHover(
        event
        )
    {
        let targetId = event.target.id;

        if( isHovered === targetId )
        {
            isHovered = '';
        }
        else
        {
            isHovered = event.target.id;
        }
    }

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .input-container,
    .dual-list-container
    {
        grid-column: 1 / 3;
    }

    .dual-list-container
    {
        display: grid;
        grid-template-columns: 1fr 12px 1fr;
        justify-items: center;
        align-items: center;
    }

    .fieldset
    {
        display: grid;
        grid-template-columns: 1fr;

        +media( desktop )
        {
            grid-template-columns: 1fr 1fr;
        }
    }

    .dual-list-title
    {
        margin-bottom: 0.5rem;

        font-size: 1.3rem;
    }

    .list-option
    {
        overflow: hidden;
        border-radius: 0.5rem;
        padding: 1rem 0.5rem;

        background: pureBlackColor;

        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: uppercase;

        transition: all 0.2s ease-in-out;
    }

    .list-option:hover
    {
        color: lightGoldColor;
    }

    .list-option:checked,
    .list-option:focus,
    .list-option::selection
    {
        background: lightGoldColor !important;

        color: darkGreyColor;
    }

    .move-to-buttons-container
    {
        width: 2.5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .move-to-button-content
    {
        z-index: 3;

        width: 100%;
    }
</style>

<fieldset class="fieldset dual-list-fieldset">
    <legend class="dual-list-title">{ dualListTitle }</legend>

    <div class="dual-list-container">
        <DualListSelect
            label="Not included"
            size="5"
            fullWidth
            multiple
            shrink
            shouldExpand={ false }
            hasBorder={ false }
            bind:element={ unselectedListElement }
        >
            {#each unselectedListArray as unselectedOption ( unselectedOption.id ) }
                <option value={ unselectedOption.id } class="list-option">
                    { getLocalizedText( unselectedOption[ titleColumnName ], $languageTagStore ) }
                </option>
            {/each}
        </DualListSelect>

        <div class="move-to-buttons-container">
            <nav
                class="move-to-button-content"
                id="included"
                on:mouseenter={ handleToggleHover }
                on:mouseleave={ handleToggleHover }
            >
                <Button
                    type="button"
                    style={ moveToButtonStyle }
                    on:click={ () => moveToSelect( 'unselected' ) }
                >
                    <div
                        class="size-150"
                        class:light-gold-full-arrow-right-icon={ isHovered !== 'included' }
                        class:dark-grey-full-arrow-right-icon={ isHovered === 'included' }
                    />
                </Button>
            </nav>

            <nav
                id="not-included"
                class="move-to-button-content"
                on:mouseenter={ handleToggleHover }
                on:mouseleave={ handleToggleHover }
            >
                <Button
                    type="button"
                    style={ moveToButtonStyle }
                    on:click={ () => moveToSelect( 'selected' ) }
                    on:mouseenter={ handleToggleHover }
                    on:mouseleave={ handleToggleHover }
                    on:focus={ handleToggleHover }
                >
                    <div
                        class="size-150"
                        class:light-gold-full-arrow-left-icon={ isHovered !== 'not-included' }
                        class:dark-grey-full-arrow-left-icon={ isHovered === 'not-included' }
                    />
                </Button>
            </nav>
        </div>

        <DualListSelect
            label="Included"
            size="5"
            multiple
            fullWidth
            shrink
            shouldExpand={ false }
            hasBorder={ false }
            bind:element={ selectedListElement }
        >
            {#each selectedListArray as selectedOption ( selectedOption.id ) }
                <option value={ selectedOption.id } class="selected-option list-option">
                    { getLocalizedText( selectedOption[ titleColumnName ], $languageTagStore ) }
                </option>
            {/each}
        </DualListSelect>
    </div>
</fieldset>
