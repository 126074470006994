<script>
    // -- IMPORTS
    
	import TextForm from './../../../../../ADMIN/src/lib/component/form/TextForm.svelte';
    import Modal from "../../../../../ADMIN/src/lib/component/element/Modal.svelte";
    import { languageTagStore } from '$src/lib/store/languageTagStore';
    
    // -- VARIABLES

    export let isModalOpen = false;
    export let textData = {};
    export let isMultiline = true;
    export let handleReloadText = () => {};
</script>

<Modal
    bind:showModal={ isModalOpen }
    modalConfirmButtonLabel="Save"
    modalType=""
    hasFooterButtons={ false }
    modalTitle={ `Edit ${ textData.slug }` }
>
    <TextForm
        isMultiline={ isMultiline }
        activeLanguageCode={ $languageTagStore }
        textData={ textData }
        isInsideModal={ true }
        on:update-text={ handleReloadText }
    />
</Modal>