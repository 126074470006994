<script>
    // -- VARIABLES

    export let disabled = false;
    export let error = false;
    export let fullWidth = false;
    export let multiple = false;
    export let helperText = '';
    export let label = '';
    export let name = '';
    export let required = false;
    export let type = 'text';
    export let value;
    export let size = '1';
    export let element = null;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import "../../../../constant.styl";
    @import "../../../../mixin.styl";

    // -- ELEMENTS

    select
    {
        background: pureBlackColor !important;
    }

    // -- CLASSES

    .input-root
    {
        margin: 0.5rem;
        height: 100%;
        width: 25ch;
    }

    .input-container
    {
        position: relative;

        margin: 0px;
        min-width: 0px;
        border: 0px;
        padding: 0px;

        display: inline-flex;
        flex-direction: column;

        vertical-align: top;
    }

    .input-label.error
    {
        color: rgb(211, 47, 47);
    }

    .input-label
    {
        z-index: 1;
        position: absolute;
        top: -4px;
        left: 0px;
        transform-origin: left top;
        transform: translate(0.875rem, -0.5625rem) scale(0.75);

        overflow: hidden;
        max-width: calc(133% - 2rem);
        padding: 0px;

        display: block;

        line-height: 1.4375em;
        font-size: 1rem;
        font-weight: 400;
        white-space: nowrap;
        letter-spacing: 0.00938em;
        text-overflow: ellipsis;
        color: lightGoldColor;

        user-select: none;
        pointer-events: auto;
        transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    .input-content
    {
        box-sizing: border-box;

        display: inline-flex;

        line-height: 1.4375em;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.00938em;
        color: lightGreyColor;

        cursor: text;
        -webkit-box-align: center;
        position: relative;

        overflow: hidden;
        height: 100%;
        border-radius: .25rem;

        align-items: center;
    }

    .input-content.is-multiline
    {
        overflow: hidden;

        align-items: flex-start;
    }

    .input
    {
        box-sizing: content-box;
        margin: 0px;
        outline: none;
        border: 0px;
        padding: 0px;

        background: none;

        font: inherit;
        letter-spacing: inherit;
        color: currentcolor;
        -webkit-tap-highlight-color: transparent;
        height: 100%;
        width: 100%;
        min-width: 0px;

        display: block;

        animation-name: mui-auto-fill-cancel;
        animation-duration: 10ms;
    }

    .input-content.error .fieldset
    {
        border-color: rgb(211, 47, 47);
    }

    .fieldset
    {
        position: absolute;

        overflow: hidden;
        inset: -5px 0px 0px;
        margin: 0px;
        min-width: 0%;

        text-align: left;

        pointer-events: none;
    }

    .legend
    {
        overflow: hidden;
        height: 0.6875rem;
        width: auto;
        padding: 0px;

        display: block;
        float: unset;

        visibility: hidden;
        background: darkGreyColor;

        font-size: 0.75em;
        white-space: nowrap;
        color: lightGoldColor;

        transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    }

    .legend > span
    {
        display: inline-block;

        visibility: visible;
        opacity: 0;
    }

    .helper-text.error
    {
        color: rgb(211, 47, 47);
    }

    .helper-text
    {
        margin: 3px 0.875rem 0px;

        line-height: 1.66;
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.03333em;
        text-align: left;
        color: lightGoldColor;
    }

    .full-width
    {
        width: 100%;
    }

    @-webkit-keyframes mui-auto-fill
    {
        from
        {
            display: block;
        }
    }

    @keyframes mui-auto-fill
    {
        from
        {
            display: block;
        }
    }

    @-webkit-keyframes mui-auto-fill-cancel
    {
        from
        {
            display: block;
        }
    }

    @keyframes mui-auto-fill-cancel
    {
        from
        {
            display: block;
        }
    }
</style>

<div class="input-root input-container" class:full-width={ fullWidth }>
    <label
        class:error={ error }
        class="input-label"
        for="outlined-error-helper-text"
        id="outlined-error-helper-text-label"
    >
        { label }
    </label>
    <div class="input-content" class:error={ error }>
        <select
            aria-describedby="outlined-error-helper-text-helper-text"
            aria-invalid="true"
            bind:this={ element }
            bind:value
            class="input is-multiline"
            disabled={ disabled }
            id="outlined-error-helper-text"
            multiple="multiple"
            name={ name }
            size={ size }
        >
            <slot/>
        </select>
    </div>
    {#if error }
        <p
            class="helper-text"
            class:error={ error }
            id="outlined-error-helper-text-helper-text"
        >
            { helperText }
        </p>
    {/if}
</div>
