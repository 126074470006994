<script>
  import { getLocalizedText } from "senselogic-gist";

    // -- VARIABLES

    import IconButton from "../element/IconButton.svelte";
    import Media from "../element/Media.svelte";
    import { getProcessedMultilineTranslatedText } from "$src/lib/base";
    import { languageTagStore } from "$src/lib/store/languageTagStore";
    import { slide } from "svelte/transition";
    import Button from "../element/Button.svelte";

    export let isModalOpen = false;
    export let mediaPath = '';
    export let text = '';
    export let title = '';

    // -- FUNCTIONS

    function handleCloseModal(
        )
    {
        isModalOpen = false;
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .overlay
    {
        z-index: 999
        position: fixed;

        inset: 0 0;

        background-color: rgba( 0, 0, 0, 0.5 );
    }

    .modal-container
    {
        z-index: 1000;
        position: fixed;
        top: 0;

        inset: 0 0;
        height: 100dvh;
        width: 100vw;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-wrapper
    {
        position: relative;

        overflow-y: auto;
        max-height: 90vh;
        width: 80%;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        background-color: darkGreyColor;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.40);

        padding-bottom: 1.5rem;

        +media( desktop )
        {
            height: 700px;
            width: 50rem;
            max-width: 80%;
            border-radius: .5rem;

            gap: 1.5rem;
        }
    }

    .modal-header
    {
        position: sticky;
        top: 0.25px;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;

        border-bottom: 1px solid pureBlackColor;
        background-color: darkGreyColor;
        max-width: 100%;
    }

    .modal-content
    {
        margin-top: rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2.5rem;
    }

    .partner-companies-card-text
    {
        max-width: 80%;

        font-size: 1.25rem;
    }

    .partner-companies-card-icon-container
    {
        width: 32rem;
        aspect-ratio: 2/1;

        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        justify-content: center;
        align-items: center;
    }
</style>

{ #if isModalOpen }
    <div class="overlay" />

    <section class="modal-container">
        <div class="modal-wrapper" transition:slide>
            <header class="modal-header">
                <h3>
                    { title }
                </h3>

                <Button
                    on:click={ handleCloseModal }
                    style="padding: .25rem; min-width: fit-content;"
                    variant="no-animation"
                >
                    <div class="light-gold-x-icon size-200"></div>
                </Button>
            </header>
        
            <article class="modal-content">
                <div class="partner-companies-card-icon-container">
                    {#if mediaPath }
                        <Media
                            mediaClass="partner-companies-card-icon"
                            mediaPath={ mediaPath }
                            mediaSize={ "360" }
                            mediaBackgroundSize={ "contain" }
                        />
                    {/if}
                </div>
            
                <div
                    class="partner-companies-card-text"
                    title={ getLocalizedText( text, $languageTagStore ) }
                >
                    {#if text }
                        { @html getProcessedMultilineTranslatedText( text, $languageTagStore ) }
                    {/if}
                </div>
            </article>
        </div>
    </section>
{ /if }