<script>
    // -- IMPORTS
    import { getImagePath, getMediaPath } from "$src/lib/filePath";
  import { getFileExtension } from "senselogic-gist";

    // -- VARIABLES

    export let mediaPath;
    export let mediaStyle = '';
    export let mediaClass = '';
    export let mediaSize = '1920';
    export let mediaBackgroundSize = 'cover';
    export let autoplay = true;

    let isFirstClick = true;
    let fileExtension = '';
    let videoFormats = [
            ".mp4",
            ".webm",
            ".ogg",
            ".av1"
        ];

    if ( mediaPath )
    {
        fileExtension = getFileExtension( mediaPath ).toLowerCase();
    }

</script>

<style lang="stylus">
    .block-item-video,
    .block-item-image
    {
        max-height: 100%;
        width: 100%;
    }

    .block-item-video
    {
        object-fit: cover;
    }

    .block-item-image
    {
        object-fit: cover;

        color: transparent;
    }
</style>

{#if videoFormats.includes( fileExtension ) }
    <video
        class="block-item-video { mediaClass }"
        autoplay={ autoplay}
        loop
        muted
        style={ mediaStyle }
    >
        <source src={ getMediaPath( mediaPath ) } type="video/mp4"/>
        <source src={ getMediaPath( mediaPath ) } type="video/webm"/>
        <source src={ getMediaPath( mediaPath ) } type="video/ogg"/>
        <source src={ getMediaPath( mediaPath ) } type="video/av1"/>
    </video>
{:else}
    <div
        class="block-item-image { mediaClass }"
        style="
                background: url( { getImagePath( mediaPath, mediaSize ) } ) no-repeat center center / { mediaBackgroundSize },
                            url( { getImagePath( mediaPath, '360' ) } ) no-repeat center center / cover,
                            #1B1B1B no-repeat center center / cover;
                { mediaStyle }
               "
    />
{/if}
