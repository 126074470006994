<script>
    import AnimatedContainer from './AnimatedContainer.svelte';
    import { capitalizeFirstLetter } from '../../base'
    // -- VARIABLES

    export let key = crypto.randomUUID();
    export let title;
    let activeItemKey;

    // -- FUNCTIONS
    function toggleAccordion(
        index
        )
    {
        activeItemKey = activeItemKey === index ? null : index;
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    .accordion-button
    {
        margin-bottom: 1.25rem;
        width: 100%;
        border: 1px lightGoldColor solid;
        border-radius: .3rem;
        padding: 1rem;

        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;

        background: transparent;

        color: lightGreyColor;

        cursor: pointer;
    }

    .accordion-button-icon
    {
        color: lightGoldColor;
    }
</style>

<div class="accordion">
    <button
        class="accordion-button"
        on:click|preventDefault={ () => toggleAccordion( key ) }
    >
        <p>{ capitalizeFirstLetter( title ) || 'Untitled Item' }</p>
        <span class="accordion-button-icon">{ activeItemKey === key ? '▼' : '▶' }</span>
    </button>

    {#if activeItemKey === key}
        <AnimatedContainer inView={ true } slideDirection='top'>
            <slot></slot>
        </AnimatedContainer>
    {/if}
</div>
