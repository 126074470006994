<script>
    import { fetchData } from '$lib/base';
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { getDateTimeSuffix, getLocalDateTime, isArray  } from 'senselogic-gist';
    import { toast } from '$lib/toast';
    import IconButton from './IconButton.svelte';
    import Input from './Input.svelte';

    // -- VARIABLES

    export let accept = 'image/*';
    export let handleUpdateItem = () => {};
    export let name = '';
    export let value = '';
    export let index = 0;
    export let label = "Image path";
    let imageInputElement;
    let inputElement;

    // -- FUNCTIONS

    async function handleFile(
        event
        )
    {
        let files = event.target.files;
        let formData = new FormData();

        if ( !files )
        {
            return;
        }

        for ( let i = 0; i < files.length; ++i )
        {
            formData.append( 'inputFiles', files[ i ] );
        }

        return formData.getAll( 'inputFiles' );
    }

    // ~~

    async function handleUpload(
        files
        )
    {
        let uploadedFilePaths = [];

        if ( isArray( files ) && files.length > 0 )
        {
            for ( let file of files )
            {
                try
                {
                    let localDateTime = getLocalDateTime();
                    let dateTimeSufix = getDateTimeSuffix( localDateTime, '', '' );
                    let contentType = file.type;
                    let fileName = file.name.substring( 0, file.name.lastIndexOf( '.' ) ).replace( /[^a-zA-Z0-9]/g, '_' );
                    let extension = file.name.split('.').pop();

                    let filePath = `/block/${ fileName }_${ dateTimeSufix }.${ extension.toLowerCase() }`;

                    let formData = new FormData();

                    formData.append('inputFiles', file )
                    formData.append('filePath', filePath );

                    let response = await fetchData(
                        '/api/upload-file',
                        {
                            method: 'POST',
                            body: formData,
                            credentials: 'include'
                        }
                    );

                    if ( response.error )
                    {
                        throw response.error;
                    }

                    toast(
                        {
                            variant: 'success',
                            text: 'File uploaded'
                        }
                        );

                    uploadedFilePaths.push( filePath );
                }
                catch ( error )
                {
                    console.error( 'Error uploading file:', error )

                    toast(
                        {
                            variant: 'error',
                            text: 'Error uploading file'
                        }
                        );
                }
            }
        }

        if ( isArray( uploadedFilePaths ) && uploadedFilePaths.length > 0 )
        {
            return uploadedFilePaths[ 0 ];
        }

        return null;
    }
</script>

<style lang="stylus">
    // -- ELEMENTS

    img
    {
        margin: 0 auto;
        max-height: 10rem;
    }
</style>

<Input fullWidth label={ label } type="text" value={ value } on:input bind:inputElement={ inputElement }>
    <div slot="end-adornment">
        <IconButton type="button" on:click={ () => imageInputElement.click() }>
            <div class="light-gold-upload-icon size-150"/>
        </IconButton>
        <IconButton type="button" on:click={ () => value = '' }>
            <div class="red-500-trash-icon size-150"/>
        </IconButton>
    </div>
</Input>
<input
    accept={ accept }
    bind:this={ imageInputElement }
    enctype="multipart/form-data"
    on:change={ async ( event ) =>
            {
                let files = await handleFile( event );

                let filePath = await handleUpload( files );

                inputElement.value = filePath;

                if ( index )
                {
                    handleUpdateItem( index, name, filePath )
                }
                else
                {
                    handleUpdateItem( name, filePath )
                }
            }
        }
    style="display:none"
    type="file"
/>
<img src={ getImagePath( value ) } alt="">
