<script>
    import { onMount } from "svelte";
    import { fetchData, getProcessedMultilineTranslatedText } from "../base";
    import PageHeading from "../component/header/PageHeading.svelte";
    import FullLoading from "../component/Layout/FullLoading.svelte";
    import { pageStore } from "../store/pageDataStore";
    import { getImagePath } from "../filePath";
    import { enabledAdminView } from "../store/adminStore";
    import AdminEditButton from "../component/element/AdminEditButton.svelte";
    import { getLocalizedText } from "senselogic-gist";
    import { textStore } from "../store/textStore";
    import { languageTagStore } from "../store/languageTagStore";
  import Media from "../component/element/Media.svelte";

    let isLoading = true;
    let faqPage = $pageStore.page.faq;
    let faqBlockArray = $pageStore.block.faq;
    let faqQuestionArray = $pageStore.block.faq?.question;

    async function loadData(
        )
    {
        try
        {
            isLoading = true;

            let faqPageData =
                await fetchData(
                            '/api/page/get-faq',
                            {
                                method: 'POST',
                                body: JSON.stringify(
                                    {
                                    }
                                ),
                                headers: { 'Content-Type': 'application/json' }
                            }
                        );

            faqPage = faqPageData.pageBase;
            faqBlockArray = faqPageData.blockArray;
            faqQuestionArray = faqPageData.faqArray;

            $pageStore.page.faq = faqPageData.pageBase;
            $pageStore.block.faq = faqPageData.blockArray;
            $pageStore.block.faq.question = faqPageData.faqArray;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    onMount(
        () =>
        {
            if (
                  faqPage === undefined
                  || faqBlockArray === undefined
                  || faqQuestionArray === undefined
            )
            {
                loadData();
            }
            else
            {
                isLoading = false;
            }
        }
    );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .faq-page-heading
    {
        position: relative;

        width: 60%;
    }

    .faq-heading-image-container
    {
        height: 27.5rem;
        width: 100%;
        aspect-ratio: 3 / 4;

        +media( desktop )
        {
            height: 37.5rem;
        }
    }

    :global( .faq-heading-image )
    {
       height: 100%;
       width: 100%;

       object-fit: cover;

       +media( desktop )
       {
           height: 37.5rem;
       }
    }

    .faq-section
    {
        margin-top: 10rem;
        padding: 0rem 1.5rem 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 5.5rem;

        +media( desktop )
        {
            max-width: 62.5vw;
        }
    }

    .faq-topic-container
    {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        align-items: left;
    }

    .faq-question-container
    {
        display: flex;
        flex-direction: column;
    }

    .faq-topic-title
    {
        margin-bottom: 1rem;

        line-height: 3rem;
        font-size: 2rem;
        font-weight: 200;
        font-style: normal;
        color: lightGreyColor;

        +media( desktop )
        {
            margin-bottom: 2rem;

            line-height: 5rem;
            font-size: 4rem;
        }
    }

    .faq-question-text
    {
        color: lightGoldColor;
        leading-trim: both;
        text-edge: cap;
        line-height: 3rem;
        font-size: 1.5rem;
        font-weight: 600;
        font-style: normal;

        +media( desktop )
        {
            line-height: 3rem;
            font-size: 2rem;
        }
    }

    .faq-answer-text
    {
        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
        color: lightGreyColor;

        +media( desktop )
        {
            line-height: 2.5rem;
            font-size: 1.5rem;
        }
    }

    .horizontal-line
    {
        margin-top: 2rem;
        height: 1.5px;
        width: 100%;

        background-color: #2a2a2a;

        +media( desktop )
        {
            margin-top: 3rem;
        }
    }

    .require-service-text
    {
        position: relative;

        margin-bottom: 3rem;

        +media( desktop )
        {
            margin-bottom: 5rem;
        }
    }
</style>

{#if isLoading }
    <FullLoading />
{:else}
    <div class="faq-page-heading">
        {#if faqPage.id }
            <AdminEditButton
                inset="0 5rem auto auto"
                type="faq/edit"
                title={ getLocalizedText( faqPage.title || '', $languageTagStore ) }
            />
        {/if}
    </div>

    {#if faqPage.title }
        <PageHeading
            title={ getLocalizedText( faqPage.title || '', $languageTagStore ) }
        />
    {/if}

    {#if faqPage.mediaPath || faqPage.mediaPathArray }
        <div class="faq-heading-image-container">
            <Media
                mediaPath={ faqPage.mediaPath || faqPage.mediaPathArray }
                mediaClass="faq-heading-image"
                isHeroSection={ true }
            />
        </div>
    {/if}

    <div class="faq-section" class:is-admin-enabled={ $enabledAdminView }>
        {#each faqBlockArray as topic }
            {#if topic.typeSlug === 'list' }
                <div class="faq-topic-container" class:is-admin-enabled={ $enabledAdminView }>
                    <span class="faq-topic-title">{ @html getProcessedMultilineTranslatedText( topic.title || '', $languageTagStore ) }</span>

                    {#each faqQuestionArray as question }
                        {#if question.slug  === topic.slug }
                            <div class="faq-question-container">
                                <p class="faq-question-text">{ @html getProcessedMultilineTranslatedText( question.title || '', $languageTagStore ) }</p>

                                <span class="faq-answer-text">{ @html getProcessedMultilineTranslatedText( question.text || '', $languageTagStore ) }</span>
                            </div>
                        {/if}
                    {/each}

                    <AdminEditButton
                        type="faq/edit"
                        queryString="tab=1"
                        hash={ topic.id }
                        title={ getLocalizedText( topic.title || '', $languageTagStore ) }
                    />

                    <hr class="horizontal-line" />
                </div>
            {/if}
        {/each}

        <p class="require-service-text" class:is-admin-enabled={ $enabledAdminView }>
            { @html getProcessedMultilineTranslatedText( $textStore[ 'require-service-label' ] || '', $languageTagStore ) }
            <AdminEditButton
                type="text"
                slug="require-service-label"
                title="require-service-label"
            />
        </p>
    </div>
{/if}
