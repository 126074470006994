// -- IMPORTS

import { getLocalizedText, getLocalizedTextBySlug, getProcessedMultilineText, isMultilingualText } from 'senselogic-gist';
import { get } from 'svelte/store';
import { languageTagStore } from './store/languageTagStore';
import { toast } from './toast';

// -- CONSTANTS

export let hostUrl = '';
export let websiteUrl = 'https://bamhub.com';

// -- FUNCTIONS

export async function fetchData(
    route,
    options = { method: 'GET' }
    )
{
    let url = hostUrl + route;

    try
    {
        let response = await fetch( url, options );

        if ( !response.ok )
        {
            throw new Error( 'Network response was not ok: ' + response.status );
        }

        return await response.json();
    }
    catch ( error )
    {
        console.error( 'There was a problem with your fetch operation:', error );

        throw error;
    }
}

// ~~

export function clickOutside(
    node
    )
{
    function handleClickEvent(
        event
        )
    {
        if ( node && !node.contains( event.target ) && !event.defaultPrevented )
        {
            node.dispatchEvent(
                new CustomEvent( 'clickOutside', node )
                );
        }
    }

    document.addEventListener( 'click', handleClickEvent, true );

    return (
        {
            destroy()
            {
                document.removeEventListener( 'click', handleClickEvent, true );
            }
        }
        );
}

// ~~

export function encodeFileToBase64Url(
    file
    )
{
    return new Promise(
        ( resolve, reject ) =>
        {
            let reader = new FileReader();
            reader.onload = () => resolve( reader.result.toString() );
            reader.onerror = error => reject( error);
            reader.readAsDataURL( file );
        }
        );
}

// ~~

export function decodeBase64UrlToFile(
    base64Url,
    filename
    )
{
    return new Promise( ( resolve, reject ) =>
    {
        let base64EncodedData = base64Url.split( ';base64,' ).pop();

        let byteCharacters = atob( base64EncodedData );
        let byteNumbers = new Array( byteCharacters.length );

        for ( let i = 0; i < byteCharacters.length; i++ )
        {
            byteNumbers[ i ] = byteCharacters.charCodeAt( i );
        }

        let byteArray = new Uint8Array( byteNumbers );

        let fileBlob = new Blob( [ byteArray ], { type: 'application/octet-stream' } );

        let file = new File( [ fileBlob ], filename, { type: 'application/octet-stream' } );

        resolve( file );
    }
    );
}

// ~~

export function getProcessedMultilineTranslatedText( text, variableMap = {} )
{
    let processedText = getProcessedMultilineText( getLocalizedText( text, variableMap, get( languageTagStore ) ).replace(/\n(?!\\n)/g, '\n') );

    return processedText;
}

// ~~

export function getProcessedMultilineTranslatedTextBySlug( text, variableMap = {} )
{
    let processedText = getProcessedMultilineText( getLocalizedTextBySlug( text, variableMap, get( languageTagStore ) ).replace(/\n(?!\\n)/g, '\n') );

    return processedText;
}

// ~~

export function sortNumberAscending(
    numA,
    numB
    )
{
    return numA - numB;
}

// ~~

export function sortNumberDescending(
    numA,
    numB
    )
{
    return numB - numA;
}

// ~~

export function handleCurrencyValue(
    value,
    currency
    )
{
    let valueAsString = value.toString()

    if ( isMultilingualText( valueAsString ) && value?.includes( currency ) )
    {
        let valueByCurrency = getLocalizedText( valueAsString, currency, currency )

        let valueAsNumber = parseInt( valueByCurrency );

        return formatPrice( valueAsNumber, currency );
    }
    else
    {
        let valueAsNumber = parseInt( value );

        if  ( isNaN( valueAsNumber ) )
        {
            return '-'
        }

        return formatPrice( valueAsNumber, 'eur' );
    }
}

// ~~

export function formatPrice(
    value,
    currency
    )
{
    if( currency === 'brl' )
    {
        return new Intl.NumberFormat(
            'pt-BR',
            {
                style: 'currency',
                currency: 'BRL' }
            )
            .format( value );
    }
    else if( currency === 'usd' )
    {
        return new Intl.NumberFormat(
            'en-US',
            {
                style: 'currency',
                currency: 'USD' }
            )
            .format( value );
    }
    else
    {
        return new Intl.NumberFormat(
           'de-DE',
           {
               style: 'currency',
               currency: 'EUR' }
           )
           .format( value );
    }
}

// ~~

export function isObjectEmpty(
    object
    )
{
    for ( var key in object )
    {
        if ( object.hasOwnProperty( key ) && object[ key ].trim() !== '' )
        {
            return false;
        }
    }

    return true;
}

// ~~

export function capitalizeFirstLetter(
    word
    )
{
    return String(word).charAt(0).toUpperCase() + String(word).slice(1);
}

// ~~

export function addFileArrayOnObject(
    object,
    fileArray
    )
{
    if ( fileArray?.length === 1 )
    {
        object.mediaPathArray = null;
        object.mediaPath = fileArray[ 0 ];
    }
    else if( fileArray?.length > 1 )
    {
        object.mediaPath = null;
        object.mediaPathArray = fileArray;
    }
    else
    {
        object.mediaPath = null;
    }

    return object;
}

// ~~

export function checkRequiredIsFilled(
    formObject,
    requiredFieldArray,
    )
{
    let errorArray = [];

    for ( let [ key, value ] of Object.entries( formObject ) )
    {
        if( requiredFieldArray.includes( key )
            &&  ( !value
                  || value === '""'
                  || value?.length === 0
                  || Object.keys( value ).length === 0 )
        )
        {
            if( key === 'mediaPath' || key === 'mediaPathArray' ) {
                if ( !formObject.mediaPath && formObject.mediaPathArray?.length === 0
                    || formObject.mediaPath === null && formObject.mediaPathArray === null )
                {
                    errorArray.push( { field: key, message: `Please upload a media file.` } );
                }
            }
            else if( key === 'coordinateMap' )
            {
                errorArray.push( { field: key, message: `Please click on the map to set a location.` } );
            }
            else
            {
                errorArray.push( { field: key, message: `The ${ key } field is required.` } );
            }
        }
    }

    if( errorArray.length > 0 )
    {
        for( let error of errorArray )
        {
            toast(
                {
                    variant: 'error',
                    text: error.message
                }
                );
        }
    }

    return errorArray;
}

// ~~

export function getTabIndexByQueryParams(
    )
{
    let urlParams = new URLSearchParams( window.location.search );
    let tabIndexNumber = Number( urlParams.get( 'tab' ) );

    return tabIndexNumber;
}

// ~~

export function getURLHashParameter(
    )
{
    let urlHash = window.location.hash;
    let hashValue = urlHash.replace('#', '');

    return hashValue;
}
