<script>
    // -- IMPORTS

    import { enabledAdminView } from '$lib/store/adminStore.js';
    import AdminEditButton from '../element/AdminEditButton.svelte';
    import { onMount } from 'svelte';
    import Marker from './Marker.svelte';
    import { getImagePath } from '$src/lib/filePath';
    import Loading from '../element/Loading.svelte';
    import { createLoadObserver } from '$lib/base'
    import { robinsonProjection } from '$src/lib/mapCoordinates';

    // -- letANTS

    let onLoad = createLoadObserver(
        () =>
        {
            let mapContainer = document.querySelector( '.map-content' );

            mapContainer.style.minHeight = `0`;
            isLoading = false;
        }
    );

    // -- VARIABLES

    export let networkingArray = [];
    let imageWidth = 1430;
    let imageHeight = 612;
    let imageElement;
    let isLoading = true;

    // -- FUNCTIONS

    function latLngToPixel(
        latitude, 
        longitude, 
        mapWidth, 
        mapHeight
        ) 
    {
        let minLatitude = -90, maxLatitude = 90;
        let minLongitude = -180, maxLongitude = 180;

        let x = ( longitude - minLongitude ) / ( maxLongitude - minLongitude );
        let y = 1 - ( latitude - minLatitude ) / ( maxLatitude - minLatitude );

        return {
            x: Math.round( x * mapWidth ),
            y: Math.round( y * mapHeight )
        };
    }

    // ~~

    function updateHubArray(
        )
    {
        networkingArray = networkingArray.map(
            city =>
            {
                let { x, y } =
                    robinsonProjection(
                        city.latitude,
                        city.longitude,
                        imageWidth
                    );

                return (
                    {
                        ...city,
                        x,
                        y
                    }
                    );
            }
            );
    }

    // ~~

    function handleResizeEvent(
        )
    {
        imageWidth = imageElement?.offsetWidth;
        imageHeight = imageElement?.offsetHeight;

        updateHubArray();
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            window.addEventListener( 'resize', handleResizeEvent );

            return () =>
                {
                    window.removeEventListener( 'resize', handleResizeEvent );
                }
        }
        );

    // ~~

    onMount( updateHubArray );

    // ~~

    $: updateHubArray();
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .map-container
    {
        width: 90vw;
        
        margin-bottom: -120px;
    }

    .map-content
    {
        position: relative;
    }

    .map-image
    {
        height: auto;
        width: 100%;

        object-fit: cover;
        -webkit-user-select:none;
        -webkit-touch-callout:none;
    }
</style>

<div class="map-container" class:is-admin-enabled={ $enabledAdminView }>
    <AdminEditButton
        type="our-network"
        inset="-1rem 2rem 0 auto"
        title="Network cities"
    />

    <div class="map-content">
        <!-- TODO: Replace with Supabase image using getImagePath -->
        <img
            use:onLoad
            bind:this={ imageElement }
            on:load={ handleResizeEvent }
            on:contextmenu={ () => false }
            class="map-image"
            src={ "/image/networking/map.svg" } 
            alt=""
        />
        {#if isLoading }
            <Loading isCentered />
        {:else}
            {#each networkingArray as hub, hubIndex }
                <Marker marker={ hub } markerIndex={ hubIndex }/>
            {/each}
        {/if}
    </div>
</div>
