<script>
    // -- IMPORTS

    import Switch from './Switch.svelte';
    import { onMount } from 'svelte';
    import MDTextEditor from './MDTextEditor.svelte';
    import { getProcessedMultilineText, getLocalizedText } from 'senselogic-gist';
    import { createEventDispatcher } from 'svelte';
    import Input from './Input.svelte';
    import { capitalizeFirstLetter } from '../../base';

    // -- VARIABLES

    export let itemsString = '';
    export let placeholder = '';
    export let name = '';
    export let isMultiLine = false;
    export let languageArray;
    export let activeLanguageCode = 'en';
    
    let mdInput = null;
    let dispatch = createEventDispatcher();
    let itemsToExport = '';
    let usedLanguages = new Set();
    let activeTextFormattingAction = {};
    let isPreviewMode = false;

    if( !itemsString )
    {
        itemsString = '';
    }

    let items =
        itemsString.split( '¨' )
        .reduce(
            ( accumulator, currentValue, index ) =>
            {
                if ( index === 0 )
                {
                    accumulator[ 'en' ] = currentValue;
                }
                else
                {
                    let [ key, value ] = currentValue.split( ':' );
                    accumulator[ key ] = value;
                }

                return accumulator;
            },
            {}
            );

    // -- FUNCTIONS

    function removeEmptyStrings(
        )
    {
        items = Object.fromEntries(
                    Object.entries( items ).filter( ( [ key, value ] ) => value !== '' )
                );
    }

    // ~~

    function selectlanguage(
        langCode
        )
    {
        activeLanguageCode = langCode;

        removeEmptyStrings();

        if ( !Object.keys( items ).includes( langCode ) )
        {
            items = { ...items, [ langCode ]: '' };
        }
    }

    // ~~

    function toggleFormatting(
        text,
        formatType = ''
        )
    {
        let newText = '';
        let formatCharacter = '';

        switch( formatType )
        {
            case 'bold':
                formatCharacter = '**';
                break;
            case 'italic':
                formatCharacter = '%%';
                break;
            case 'link':
                formatCharacter = '((url)( placeholder ))';
                break;
        }

        if( formatType === 'link' )
        {
            return formatCharacter;
        }

        if ( text !== '' )
        {
            newText = `${ formatCharacter }${ text }${ formatCharacter }`;
        }
        else
        {
            if( activeTextFormattingAction[ activeLanguageCode ]?.includes( formatType ) )
            {
                activeTextFormattingAction[ activeLanguageCode ] = activeTextFormattingAction[ activeLanguageCode ].filter( activeAction => activeAction !== formatType );
            }
            else
            {
                activeTextFormattingAction[ activeLanguageCode ] = [ ...activeTextFormattingAction[ activeLanguageCode ],  formatType ];
            }

            newText = `${ formatCharacter }`;
        }

        return newText;
    }

    // ~~

    function replaceSelectTextarea(
        action = '',
        value = 0
        )
    {
        let first = mdInput.value.slice( 0, mdInput.selectionStart );
        let text = mdInput.value.slice(mdInput.selectionStart, mdInput.selectionEnd );
        let newText = toggleFormatting( text, action );

        let rest = mdInput.value.slice( mdInput.selectionEnd, mdInput.value.length );

        mdInput.value = first + newText + rest;
        items[ activeLanguageCode ] = first + newText + rest;

        mdInput.selectionEnd = ( first + newText ).length;
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            languageArray.forEach(( language ) => activeTextFormattingAction[ language.code ] = []);
        }
        );

    // ~~

    $:
    {
        itemsToExport = Object.entries( items )
            .map( ( [ key, value ] ) => key === 'en' ? value : `¨${ key }:${ value }` )
            .join( '' );
    }

    // ~~

    $:
    {
        usedLanguages.clear();
        Object.keys( items ).forEach( key =>
        {
            usedLanguages.add( key );
        }
        );
    }

    // ~~

    $:
    {
        let error = 'Please fill in the English text';

        if( items.en !== ''
            && itemsToExport === true
            )
        {
            error = null;
        }

        dispatch( 'update', { text: itemsToExport, name, error } );
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .input-language-container
    {
        display: flex;
        flex-direction: column;
        gap: .75rem;

        font-size: 1rem;
        line-height: 1.25rem;
        letter-spacement: .25rem; 
    }

    .input-title
    {
        color: lightGreyColor;
    }

    .text-editor-button
    {
        margin: 0;
        border: 1px solid lightGoldColor;
        border-radius: .5rem;
        padding: 0.75rem;
        max-height: 3rem;

        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        background-color: transparent;

        font-size: .75rem;
        text-transform: uppercase;
        color: lightGoldColor;

        cursor: pointer;
        text-wrap: nowrap;
        width: fit-content;

        transition: all 0.2s ease-in-out;

        +media( smaller-desktop )
        {
            width: 20%;
            max-width: unset;
        }
    }

    .text-editor-button:hover,
    {
        background-color: lightGoldColor;

        color: darkGreyColor;
    }

    .language-selector
    {
        padding: 0.75rem 1rem;
    }

    .text-formatting-button:hover .light-gold-bold-icon,
    .text-formatting-button:hover .light-gold-italic-icon,
    .text-formatting-button:hover .light-gold-link-icon,
    .is-formatting-active .light-gold-bold-icon,
    .is-formatting-active .light-gold-italic-icon,
    .is-formatting-active .light-gold-link-icon,
    {
        border: 1px solid darkGrayColor;

        background: darkGreyColor;
    }

    .is-language-active,
    .is-formatting-active
    {
        background-color: lightGoldColor;

        color: darkGreyColor;
    }

    .is-language-empty
    {
        position: relative;

        border-color: grayColor700;

        color: grayColor700;
    }

    .is-language-empty::after
    {
        position: absolute;
        top: -.2rem;
        right: -.2rem;

        height: .75rem;
        width: .75rem;
        border-radius: 50%;

        content: '';
        background-color: lightGoldColor;
    }

    .text-editor-header
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .language-container,
    .text-action-button-container
    {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .input-container
    {
        gap: 1rem;
        align-items: center;
    }

    .preview-mode-switch-container
    {
        margin-bottom: 1rem;
        border: 1px solid lightGoldColor;
        border-radius: .5rem;
        padding: .5rem .5rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: darkGreyColor;

        font-size: .75rem;
        font-weight: 600;
        text-transform: uppercase;
        color: lightGoldColor;
        text-wrap: nowrap;

        transition: all 0.2s ease-in-out;

        +media( smaller-desktop )
        {
            width: 100%;
        }
    }

    .textarea-preview
    {
        height: 14rem;
        max-height: 14rem;       
        width: 100%;
        border-radius: 0.5rem;
        margin-bottom: .44rem;
        padding: 1rem;

        display: inline-block;

        background-color: darkGrayColor;

        color: lightGreyColor;
        text-align: left;
    }

    .input-item
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;

        +media( desktop )
        {
            flex-direction: row;
        }
    }

    .input-item-language
    {
        height: 3rem;
        width: 3rem;
        border: 1px solid lightGoldColor;
        border-radius: 2px;
        padding: 1rem;

        display: none;
        justify-content: center;
        align-items: center;

        text-transform: uppercase;

        +media( desktop )
        {
            display: flex;
        }
    }
</style>

<div class="input-language-container">
    <h4 class="input-title">{ capitalizeFirstLetter( placeholder ) }</h4>

    <div class="text-editor-header">
        {#if isMultiLine }
            <div class="text-action-button-container">
                <button
                    type="button"
                    class="text-editor-button text-formatting-button"
                    class:is-formatting-active={ activeTextFormattingAction[ activeLanguageCode ]?.includes( 'bold' ) }
                    title="Bold"
                    on:click={ () => replaceSelectTextarea( 'bold' ) }
                >
                    <div class="light-gold-bold-icon size-100"/>
                </button>

                <button
                    type="button"
                    class="text-editor-button text-formatting-button"
                    class:is-formatting-active={ activeTextFormattingAction[ activeLanguageCode ]?.includes('italic') }
                    title="Italic"
                    on:click={ () => replaceSelectTextarea( 'italic' ) }
                >
                    <div class="light-gold-italic-icon size-100"/>
                </button>

                <button
                    type="button"
                    class="text-editor-button text-formatting-button"
                    class:is-formatting-active={ activeTextFormattingAction[ activeLanguageCode ]?.includes('link') }
                    title="Link"
                    on:click={ () => replaceSelectTextarea( 'link' ) }
                >
                    <div class="light-gold-link-icon size-100"/>
                </button>
            </div>
        {/if}

        <div class="language-container">
            {#each languageArray as language }
                <button
                    class="language-selector text-editor-button"
                    class:is-language-active={ activeLanguageCode === language.code }
                    class:is-language-empty={ !(language.code in items) }
                    on:click={ () => { selectlanguage( language.code ); } }
                    type="button"
                >
                    { getLocalizedText( language.code ) }
                </button>
            {/each}
        </div>
    </div>

    <div class="input-container">
        {#if isMultiLine }
            <div class="preview-mode-switch-container">
                <span>Preview Mode</span>

                <Switch
                    bind:value={ isPreviewMode }
                    isPreviewSwitch={ true }
                />
            </div>

            <div class="input-item">
                <MDTextEditor
                    bind:mdInput={ mdInput }
                    bind:editorText={ items[ activeLanguageCode ] }
                    --should-display={ isPreviewMode ? 'none' : 'block' }
                />

                {#if isPreviewMode }
                    <div class="textarea-preview">
                        {@html getProcessedMultilineText( items[ activeLanguageCode ] ) }
                    </div>
                {/if}
            </div>
        {:else}
            <div class="input-item">
                <Input bind:value={ items[ activeLanguageCode ] } fullWidth label={ placeholder } shouldShowLabel={ false }/>
            </div>
        { /if}
    </div>

    <input hidden name={ name } bind:value={ itemsToExport }/>
</div>
