<script>
    // -- IMPORTS
    
    import { websiteUrl } from '$lib/base.js';
    import Seo from '$lib/component/element/Seo.svelte';
    import { enabledAdminView } from '$lib/store/adminStore';
    import { getProcessedMultilineTranslatedText } from '$lib/base';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { onMount } from 'svelte';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import { fetchData } from '../base';
    import { pageStore } from '../store/pageDataStore';
    import { languageArrayStore } from '../store/languageArrayStore';
    import FullLoading from '../component/Layout/FullLoading.svelte';
    import urlStore from '../store/urlStore';
    import { getLocalizedText } from 'senselogic-gist';
    import TitleImageAndDescriptionBlock from '../component/element/TitleImageAndDescriptionBlock.svelte';

    // -- VARIABLES

    let isLoading = true;
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let mediaPath = '';
    let url = '';

    let newsPage = $pageStore.page.newsPage;
    let newsBlockArray = $pageStore.block.newsSection;

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let newsPageData =
                await fetchData(
                    '/api/get-news',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                route: '/news'
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            $pageStore.page.newsPage = newsPageData.page;
            $pageStore.block.newsSection = newsPageData.blockArray;
            newsBlockArray = newsPageData.blockArray;
            newsPage = newsPageData.page;
            metaTitle = newsPageData.page.metaTitle ?? newsPageData.page.title;
            metaDescription = newsPageData.page.metaDescription ?? newsPageData.page.subTitle;
            metaKeywords = newsPageData.page.metaKeywords;
            mediaPath = newsPageData.page.mediaPath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if( newsPage === undefined
                || newsBlockArray === undefined )
            {
                loadData();
            }
            else
            {
                isLoading = false;
            }
        }
        );

    $:  if ( $urlStore.pathname.includes( 'news' ) )
        {
            document.title = 'News / Gallery';
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

     // -- CLASSES

    .news-section
    {
        border: 1px solid borderColor;
        padding: 0 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( tablet )
        {
            max-width: 60vw;
            border: none;
        }

        +media( desktop )
        {
            max-width: 77vw;

            gap: 4rem;
        }
    }

    .page-heading
    {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .page-heading .page-heading-subtitle
    {
        text-transform: none !important;
    }

    .image-container
    {
        margin-top: -5rem;
        width: 100%;

        display: flex;
        flex-direction: column;

        +media( desktop )
        {
            gap: 2rem;
        }
    }

    .image-container-text
    {
        +media( desktop )
        {
            text-align: center;
        }
    }

    .block-list
    {
        margin-bottom: 4rem;

        display: flex;
        flex-direction: column;
        gap: 4rem;

        +media( desktop )
        {
            margin-top: 2.5rem;
            margin-bottom: 7.5rem;

            gap: 7.5rem;
        }
    }
</style>

{#if isLoading }
    <FullLoading />
{:else}
    <Seo
        metaTitle={ metaTitle }
        metaDescription={ metaDescription }
        metaKeywords={ metaKeywords }
        url={ url }
        mediaPath={ mediaPath }
        languageData={ $languageArrayStore }
        path=''
    />

    <section class="news-section" class:is-admin-enabled={ $enabledAdminView }>
        <AdminEditButton
            inset="5rem 5rem auto auto"
            type="news"
            title={ getLocalizedText( newsPage?.title || '', $languageTagStore ) }
        />

        {#await import( '$lib/component/header/PageHeading.svelte' ) then { default : PageHeading } }
            <PageHeading
                title={ newsPage?.title || '' }
                subtitle={ newsPage?.subTitle || '' }
            />
        {/await}

        <div class="image-container">
            <div class="image-container-text">
                {#if newsPage.additionalTitle }
                    <p class="font-size-200 font-weight-500 color-light-gold text-transform-uppercase line-height-300">
                        <AnimatedText text={ getProcessedMultilineTranslatedText( newsPage.additionalTitle || '', $languageTagStore ) }/>
                    </p>
                {/if}
                {#if newsPage.additionalDescription }
                    <p class="font-size-125 color-light-grey margin-top-50 line-height-225">
                        <AnimatedText text={ getProcessedMultilineTranslatedText( newsPage.additionalDescription || '', $languageTagStore ) }/>
                    </p>
                {/if}
            </div>
        </div>

        <div class="block-list">
            {#each newsBlockArray as newsBlock, newsBlockIndex }
                <TitleImageAndDescriptionBlock 
                    block={ newsBlock }
                    blockIndex={ newsBlockIndex }
                />
            {/each}
        </div>
    </section>
{/if}
