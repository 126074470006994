<script>
    // -- IMPORTS

    import { inview } from 'svelte-inview';

    // -- VARIABLES

    let inView = false;
    let absoluteCharIndex = 0;

    export let text;

    // -- FUNCTIONS

    function getCurrentDelay()
    {
        absoluteCharIndex += 5;

        return absoluteCharIndex;
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    span
    {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }

    // -- CLASSES

    .char
    {
        width: 100%;

        opacity: 0;

        +media( desktop )
        {
            width: auto;
        }
    }

    .slide-in-bottom
    {
        animation: slide-in-bottom 1.8s ease both;
    }

    // -- ANIMATIONS

    @keyframes slide-in-bottom
    {
        0%
        {
            transform: translateY( 60px );

            opacity: 0;
        }

        100%
        {
            transform: translateY( 0 );

            opacity: 1;
        }
    }
</style>

<span
    use:inview=
    {
        {
            unobserveOnEnter: true,
            threshold: 0.5
        }
    }
    on:inview_enter=
    {
        () => inView = true
    }

>
    <!-- {#if animateByCharacter }

        {#each text.split( ' ' ) as word }
            <span class='word'>
            {#each word.split( '' ) as char }
                    <span
                        class="char {inView ? 'slide-in-bottom' : ''}"
                        style="animation-delay: { getCurrentDelay() }ms"
                    >
                        { char }
                    </span>
            {/each}
            &nbsp;
        </span>
        {/each}

    {:else} -->

        <span class="char { inView ? 'slide-in-bottom' : '' }">
            {@html text }
        </span>

    <!-- {/if} -->
</span>
