// -- VARIABLES

const robinsonTable =
    [
        { lat: 0,  X: 1.0000, Y: 0.0000 },
        { lat: 5,  X: 0.9986, Y: 0.0620 },
        { lat: 10, X: 0.9954, Y: 0.1240 },
        { lat: 15, X: 0.9900, Y: 0.1860 },
        { lat: 20, X: 0.9822, Y: 0.2480 },
        { lat: 25, X: 0.9730, Y: 0.3100 },
        { lat: 30, X: 0.9600, Y: 0.3720 },
        { lat: 35, X: 0.9427, Y: 0.4340 },
        { lat: 40, X: 0.9216, Y: 0.4958 },
        { lat: 45, X: 0.8962, Y: 0.5571 },
        { lat: 50, X: 0.8679, Y: 0.6176 },
        { lat: 55, X: 0.8350, Y: 0.6769 },
        { lat: 60, X: 0.7986, Y: 0.7346 },
        { lat: 65, X: 0.7597, Y: 0.7903 },
        { lat: 70, X: 0.7186, Y: 0.8435 },
        { lat: 75, X: 0.6732, Y: 0.8936 },
        { lat: 80, X: 0.6213, Y: 0.9394 },
        { lat: 85, X: 0.5722, Y: 0.9761 },
        { lat: 90, X: 0.5322, Y: 1.0000 }
    ];

const proportion = 2048 / 1038.75;

// -- FUNCTIONS

export function interpolate(
    table,
    latitude
    )
{
    let absLat = Math.min( latitude, 90 );
    let index = 0;

    while ( index < table.length - 1 && table[ index + 1 ].lat <= absLat )
    {
        index++;
    }

    if ( index >= table.length - 1 )
    {
        return { X: table[ index ].X, Y: table[ index ].Y };
    }

    let lower = table[ index ];
    let upper = table[ index + 1 ];
    let fraction = ( absLat - lower.lat ) / ( upper.lat - lower.lat );

    let X = lower.X + ( upper.X - lower.X ) * fraction;
    let Y = lower.Y + ( upper.Y - lower.Y ) * fraction;

    return { X, Y };
}

// ~~

export function robinsonProjection(
    lat,
    lon,
    width,
    height = width / proportion,
    centerLongitude = 11.5
    )
{
    let adjustedLon = lon - centerLongitude;
    let lambda = ( adjustedLon * Math.PI ) / 180;
    let phi = Math.max( -90, Math.min( 90, lat ) );

    let { X, Y } = interpolate( robinsonTable, Math.abs( phi ) );

    let x = lambda * X;
    let y = Y * ( phi >= 0 ? 1 : -1 );

    let maxX = Math.PI * robinsonTable[ 0 ].X;
    let maxY = 1.0000;

    let pixelX = ( ( x / maxX ) + 1 ) * ( width / 2 );
    let pixelY = ( ( -y / maxY ) + 1 ) * ( height / 2 );

    return { x: pixelX, y: pixelY };
}
