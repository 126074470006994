// -- IMPORTS

import { getLocalizedText, getLocalizedTextBySlug, getProcessedMultilineText } from 'senselogic-gist';
import { Capacitor } from '@capacitor/core';
import { get } from 'svelte/store';
import { languageTagStore } from './store/languageTagStore';
import { derived, writable } from 'svelte/store'

// -- VARIABLES

export let platform = Capacitor.getPlatform();
export let hostUrl = getHostUrl( platform, 'http', false );
export let websiteUrl = 'https://bamhub.com';

export let blockTypeArray = 
    [
        {
            "id": "BAXw16sXZ7FN8gIXqGDpcA",
            "slug": "text",
            "name": "Text"
        },
        {
            "id": "LkjNlVp5gfgaRIGSfV_D6w",
            "slug": "title",
            "name": "Title"
        },
        {
            "id": "XysZnoaTdQNtfdzAD2A7aA",
            "slug": "image",
            "name": "Image"
        },
        {
            "id": "vc8lFlOOA8P2mytalU_vjQ",
            "slug": "text-and-image",
            "name": "Text and image"
        },
        {
            "id": "GMdh8jtBqgbAQFuu0RzUbw",
            "slug": "title-and-text",
            "name": "Title and text"
        },
        {
            "id": "8SQtinfTS_y8_fpvdJKMjg",
            "slug": "card-image-and-title",
            "name": "Card image and title"
        },
        {
            "id": "qBU1d9aAJVP-xQMLIhNSng",
            "slug": "list-image-and-text",
            "name": "List image and text"
        },
        {
            "id": "akmDeGhWmB5KdZBy40J-SA",
            "slug": "text-title-and-image",
            "name": "Text, title and image"
        },
        {
            "id": "LMTa8dkYgqsnGWTzr_HsTw",
            "slug": "grid",
            "name": "Grid"
        },
        {
            "id": "gNytdulKiGu6cmZ4xIy_Sw",
            "slug": "title-image-and-text-array",
            "name": "Text, image and text array"
        },
        {
            "id": "42JR0ZP51OJoOjb9_An0Zg",
            "slug": "services-offered-by-val-e-busin-table",
            "name": "Services offered by Val & Busin table"
        },
        {
            "id": "aM-j8K6n_SvCIwtHU9diLw",
            "slug": "numeric-list",
            "name": "Numeric list"
        },
        {
            "id": "xj3JfuGh5_N0yIR7lIh4rg",
            "slug": "customized-services-from-bamhub-table",
            "name": "Customized services from BAMHUB"
        },
        {
            "id": "EK6fx9RTsN1SXQ7fLt55YQ",
            "slug": "list",
            "name": "List"
        },
        {
            "id": "lTcb4LxOnYR6vKrwymHPdg",
            "slug": "customized-services-table",
            "name": "Customized services table"
        },
        {
            "id": "ij4gNw3pGpZlz1rk3h9j9g",
            "slug": "nested-lists",
            "name": "Nested lists"
        },
        {
            "id": "IkgMTQPmejpJ8s5FgB3wuQ",
            "slug": "services-offered-via-bam-table",
            "name": "Services offered via BAM"
        },
        {
            "id": "NYo7Aj-u2cU1OIyBVIGnAQ",
            "slug": "membership-plans-table",
            "name": "Membership plans table"
        },
        {
            "id": "LzgLRp-kSlydT3QddbySjw",
            "slug": "title-text-and-text-array",
            "name": "Title, text and title array"
        }
    ]

// -- FUNCTIONS

export async function fetchData(
    route,
    options = { method: 'GET' }
    )
{
    let url = hostUrl + route;

    try
    {
        let response = await fetch( url, options );

        if ( !response.ok )
        {
            throw new Error( 'Network response was not ok: ' + response.status );
        }

        return await response.json();
    }
    catch ( error )
    {
        console.error( 'There was a problem with your fetch operation:', error );

        throw error;
    }
}

// ~~

export function clickOutside(
    node
    )
{
    function handleClickEvent(
        event
        )
    {
        if ( node && !node.contains( event.target ) && !event.defaultPrevented )
        {
            node.dispatchEvent(
                new CustomEvent( 'clickOutside', node )
                );
        }
    }

    document.addEventListener( 'click', handleClickEvent, true );

    return (
        {
            destroy()
            {
                document.removeEventListener( 'click', handleClickEvent, true );
            }
        }
        );
}

// ~~

export function getProcessedMultilineTranslatedText( text, variableMap = {} )
{
    let processedText = getProcessedMultilineText(  getLocalizedText( text, variableMap, get( languageTagStore ) )
                                                        .replaceAll('\\n', '\n') 
                                                        .replaceAll( '\"', '')
                                                    );

    return processedText;
}

// ~~

export function getProcessedMultilineTranslatedTextBySlug( text, variableMap = {} )
{
    let processedText = getProcessedMultilineText( getLocalizedTextBySlug( text, variableMap, get( languageTagStore ) ).replace('\\n', '\n') );

    return processedText;
}

// ~~

export function sortNumberAscending(
    numA,
    numB
    )
{
    return numA - numB;
}

// ~~

export function sortNumberDescending(
    numA,
    numB
    )
{
    return numB - numA;
}

// ~~

export function getFormattedCurrencyValue(
    value,
    currency
    )
{
    let valueAsString = value.toString();
    let fixedCurrency = valueAsString.includes( currency ) ? currency : 'eur';

    let valueByCurrency = getLocalizedText( valueAsString, fixedCurrency, fixedCurrency );

    let valueAsNumber = parseInt( valueByCurrency );

    return formatPrice( valueAsNumber, fixedCurrency );
}

// ~~

export function formatPrice(
    value,
    currency
    )
{
    if( currency === 'brl' )
    {
        return new Intl.NumberFormat(
            'pt-BR',
            {
                style: 'currency',
                currency: 'BRL' }
            )
            .format( value );
    }
    else if( currency === 'usd' )
    {
        return new Intl.NumberFormat(
            'en-US',
            {
                style: 'currency',
                currency: 'USD' }
            )
            .format( value );
    }
    else
    {
        return new Intl.NumberFormat(
           'de-DE',
           {
               style: 'currency',
               currency: 'EUR' }
           )
           .format( value );
    }
}

// ~~

export function isObjectEmpty(
    object
    )
{
    for ( var key in object )
    {
        if ( object.hasOwnProperty( key ) && object[ key ].trim() !== '' )
        {
            return false;
        }
    }

    return true;
}

// ~~

export function createUrlStore(
    ssrUrl
    )
{
    if ( typeof window === 'undefined' ) {
    let { subscribe } = writable( ssrUrl )
      return { subscribe }
    }

    let href = writable( window.location.href )

    let originalPushState = history.pushState
    let originalReplaceState = history.replaceState

    let updateHref = () => href.set( window.location.href )

    history.pushState =
        function () {
            originalPushState.apply( this, arguments )
            updateHref()
        }

    history.replaceState =
        function () {
            originalReplaceState.apply(this, arguments)
            updateHref()
        }

    window.addEventListener('popstate', updateHref)
    window.addEventListener('hashchange', updateHref)

    return {
        subscribe: derived(
                href,
                ( $href ) =>
                    new URL( $href ) )
                        .subscribe
    }
}

// ~~

export function createLoadObserver(
    handler
    )
{
    let waiting = 0

    function onload(
        element
        )
    {
        waiting++
        element.addEventListener(
            'load',
            () => {
                waiting--
                if ( waiting === 0 )
                {
                    handler()
                }
            }
        )
    }

    return onload
}

// ~~

function getHostUrl(
    platform,
    connectionType,
    development = false,
    subdomain = 'www'
    )
{
    let baseUrl = ( platform !== 'web' ? `s://${ subdomain }.bamhub.com` : '' );

    if ( development )
    {
        baseUrl = ( platform === 'android' ? '://10.0.2.2:8000' : '://localhost:8000' );
    }

    return (
        connectionType === 'http'
        ? `${ development ? 'http' : '' }${ baseUrl }`
        : `ws${ development ? baseUrl : `s://${ subdomain }.bamhub.com` }`
        );
}
