<script>
    // -- IMPORTS

    import { getLocalizedText } from 'senselogic-gist';
    import InputLocalizedForm from './InputLocalizedForm.svelte';
    import { createEventDispatcher } from 'svelte';
    import { getRandomTuid } from 'senselogic-gist';
    import { languageArrayStore } from '$lib/store/languageArrayStore';
    import { writable } from 'svelte/store';
    import Button from './Button.svelte';
    import Select from './Select.svelte';
    import ImageBlockEditor from './ImageBlockEditor.svelte';
    import Accordion from './Accordion.svelte';

    // -- VARIABLES

    export let data = [];
    export let AddNewLabel = ''
    let arrayData = writable( data ?? [] );
    let dispatch = createEventDispatcher();

    // -- STATEMENTS

    let items = writable( [] );

    $: arrayData.subscribe(
        ( value ) =>
        {
            items.set(
                value.map( ( item ) => item )
                );
        }
        );

    // ~~

    $: dispatch( 'saveTextArray', { textArray: JSON.stringify( $items ) } );

    // -- FUNCTIONS

    function handleAddItem(
        )
    {
        items.update(
            ( item ) =>
            {
                let newItems = [ ...item, 'New Item' ];
                arrayData.set( newItems );

                return newItems;
            }
            );
    }

    // ~~

    function handleRemoveItem(
        index
        )
    {
        items.update( ( item ) =>
            {
                let newItems = item.filter( ( _, i ) => i !== index );
                arrayData.set( newItems );

                return newItems;
            }
            );
    }

    // ~~

    function handleUpdateItem(
        index,
        value
        )
    {
        items.update(
            ( item ) =>
            {
                item[ index ] = value;
                arrayData.set( item );

                return item;
            }
            );
    }

    // ~~

    function addTextItem(
        index
        )
    {
        items.update(
            ( item ) =>
            {
                item[ index ].push('');
                arrayData.set( item );

                return item;
            }
            );
    }

    // ~~

    function moveItemUp(
        index
        )
    {
        if ( index === 0 ) return;

        items.update( ( items ) =>
            {
                [ items[ index - 1 ], items[ index ] ] = [ items[ index ], items[ index - 1 ] ];
                arrayData.set( items );

                return items;
            }
            );
    }

    // ~~

    function moveItemDown(
        index
        )
    {
        items.update( ( items ) =>
            {
                if ( index === items.length - 1 ) return items;
                [ items[ index ], items[ index + 1 ] ] = [ items[ index + 1 ], items[ index ] ];
                arrayData.set( items );

                return items;
            }
        );
    }

</script>

<style lang="stylus">
    p
    {
        margin: 0;

        align-items: center;

        text-align: center;
    }
    .editor-container
    {
        border: 1px solid #ccc;
        padding: 10px;
        & + &
        {
            margin-top: 2rem;
        }
    }

    .editor-content
    {
        margin: 0.5rem 0;

        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
        align-items: center;
        column-gap: 0.5rem;
    }

    .text-array-container
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .buttons-container
    {
        margin-top: 1rem;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        justify-content: space-between;
    }
</style>

<div>
    {#each $items as item, index }
        <div class="editor-container">
            <div class="text-array-container">
                <h3>{ index }</h3>
                <div>
                    <InputLocalizedForm
                        isMultiLine={ item.length > 80  }
                        itemsString={ item }
                        languageArray={ $languageArrayStore }
                        placeholder={ 'Text' }
                        on:update={ ( event ) => handleUpdateItem( index, event.detail.text ) }
                    >
                        <Button fullWidth variant="error" type="button" on:click={ () => handleRemoveItem( index ) }>
                            DELETE TEXT
                        </Button>
                    </InputLocalizedForm>
                </div>
            </div>

            <div class="buttons-container">
                <Button
                    fullWidth
                    type="button"
                    on:click={ () => moveItemUp( index ) }
                >
                    Move Up
                </Button>
                <Button
                    fullWidth
                    type="button"
                    on:click={ () => moveItemDown( index ) }
                >
                    Move Down
                </Button>
                <Button
                    fullWidth
                    variant="error"
                    type="button"
                    style={"grid-column: 1/-1;"}
                    on:click={ () => handleRemoveItem( index ) }
                >
                    Remove Item
                </Button>
            </div>
        </div>
    {/each}
        <Button
            fullWidth
            type="button"
            style={"margin-top: 1.5rem;"}
            on:click={ handleAddItem }
        >
            { AddNewLabel || 'Add Item'}
        </Button>
</div>
