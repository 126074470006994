<script>
    // -- IMPORTS

    import { fetchData } from '$lib/base';
    import { getFileExtension, isString, log } from 'senselogic-gist';
    import { onMount } from 'svelte';
    import Media from './Media.svelte';
    import Loading from '$component/element/Loading.svelte';
    import { toast } from '$lib/toast';
    import Modal from '../Modal.svelte';

    // -- VARIABLES

    export let src;
    export let isSubmitting = false;
    export let isDeleting = false;
    export let fileArray = [];
    export let imageSizeArray = [ 1920, 640, 360 ];
    export let filePath = ''
    export let acceptedType = 'image/*';
    export let fileInputName = 'files';
    export let maximumFileSize = 10 * 1024 * 1024;

    let modalSubmittingState = false;
    let fileURL = null;
    let error = null;
    let showDeleteModal = false;
    let isButtonBeingHovered = false;
    let fileInput = null;
    let srcToDelete = '';

    // -- FUNCTIONS
    async function handleUploadFile(
        )
    {
        isSubmitting = true;

        try
        {
            let formData = new FormData();
            formData.append( 'file', src );
            formData.append( 'imageSizeArray', JSON.stringify( imageSizeArray ) );
            formData.append( 'filePath', filePath )

            let response
            = await fetchData(
                '/api/add-file',
                {
                    method: 'POST',
                    body: formData,
                    credentials: 'include'
                }
            );

            if ( response.error )
            {
                toast.error( response.error );
                setTimeout(
                    () =>
                    {
                        handleDeleteFile();
                    },
                    3000
                    );
            }
            else
            {
                src = response.filePath;
            }
        }
        catch ( error )
        {
            console.error( error )
            toast.error( 'An error occurred while uploading the file' );
        }
        finally
        {
            isSubmitting = false;
        }
    }

    // ~~

    async function handleDeleteFile(
        srcToDelete = src
        )
    {
        modalSubmittingState = true;
        error = null;

        if ( isString( srcToDelete ) )
        {
            isDeleting = true;

            let response
                = await fetchData(
                    '/api/remove-file',
                    {
                        method: 'POST',
                        body: JSON.stringify( { filePath: srcToDelete } ),
                        credentials: 'include'
                    }
                    );

            if ( response.error )
            {
                error = response.error;
                toast.error( response.error );
            }

            isDeleting = false;
        }

        if ( error === null )
        {
            let fileIndex = fileArray.indexOf( srcToDelete );
            fileArray.splice( fileIndex, 1 );

            if ( fileURL !== null )
            {
                URL.revokeObjectURL( fileURL );
            }

            fileArray = fileArray;
            modalSubmittingState = false;
        }

        showDeleteModal = false;
    }

    // ~~

    async function handleInput(
        event
        )
    {
        let files = event.target.files;

        for ( let file of Array.from( files ) )
        {
            if ( file.size > maximumFileSize )
            {
                toast.error(
                    getLocalizedTextBySlug(
                        'file-size-error-label',
                        { fileName: file.name, maxSize: maximumFileSize / 1024 / 1024 },
                        'en'
                        )
                    );
            }
            else if ( fileArray.length > 5 )
            {
                toast.error( 'File limit reached. ' + file.name + ' could not be added' );
            }
            else
            {
                handleDeleteFile( src )
                fileArray.push( file );
            }
        }

        fileArray = fileArray;
    }

    // ~~

    function handleClickEvent(
        )
    {
        fileInput.click();
    };

    // ~~

    function handleToggleHover(
        )
    {
        isButtonBeingHovered = !isButtonBeingHovered;
    }

    // -- STATEMENTS

    onMount(
        async () =>
        {
            if ( src && !isString( src ) )
            {
                fileURL = URL.createObjectURL( src );
                await handleUploadFile();
            }
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';

    // -- CLASSES

    .file-input-image-container
    {
        position: relative;

        height: 8rem;
        width: 8rem;

        cursor: grab;
    }

    .file-input-item-image
    {
        height: 8rem;
        width: 8rem;
        border-radius: 1rem;

        object-fit: cover;
    }

    .file-input-replace-button
    {
        position: relative;
        position: relative;

        height: 8rem;
        width: var( --file-input-add-new-button-width, 8rem );
        border: 2px solid darkGreyColor;
        border-radius: .5rem;

        background: transparent;

        cursor: pointer;
        transition: border 0.25s ease-in-out;
    }

    .file-input-replace-button:hover
    {
        border: 2px solid lightGoldColor;
        border-radius: .5rem;
    }

    .overlay-container
    {
        position: absolute;

        height: 100%;
        width: 100%;
        border-radius: 0.4rem;

        transition: background-color 0.25s ease-in-out;
    }

    .file-input-replace-button:hover .overlay-container
    {
        background-color: rgba( 0, 0, 0, 0.8 );
    }

    .replace-icon
    {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% );
    }

    .file-input-remove-button
    {
        position: absolute;
        top: -0.75rem;
        right: -0.75rem;

        height: 1.5rem;
        width: 1.5rem;
        border-radius: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: redColor;
    }

    .loading-container
    {
        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 100%;
        border-radius: .5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba( 0, 0, 0, 0.3 );
        backdrop-filter: blur(1px);
    }

    .file-input-item-image
    {
        border-radius: 0.5rem;
    }
</style>

<Modal
    bind:showModal={ showDeleteModal }
    modalTitle="You are deleting!"
    modalDescription="Are you sure you want to delete this image? this action cannot be undone"
    modalConfirmButtonLabel="Yes, Delete"
    modalCancelButtonLabel="No, Keep it"
    modalButtonAction={ handleDeleteFile }
    modalType="error"
    isSubmitting={ modalSubmittingState }
/>

<div class="file-input-image-container">
    {#if isString( src ) }
        <input
            name={ fileInputName }
            type="file"
            accept={ acceptedType }
            multiple={ 1 }
            hidden
            bind:this={ fileInput }
            on:change={ handleInput }
        />

        <button
            class="file-input-replace-button"
            on:mouseenter={ handleToggleHover }
            on:mouseleave={ handleToggleHover }
            on:click|preventDefault={ handleClickEvent }
        >
            <div class="overlay-container"/>

            {#if isButtonBeingHovered }
                <div class="light-gold-plus-icon size-200 replace-icon"/>
            {/if}

            <Media
                mediaClass="file-input-item-image"
                mediaPath={ src }
                mediaSize={ 640 }
            />
        </button>
    {:else}
        <img
            class="file-input-item-image"
            src={ fileURL }
            alt={ src?.name }
        />
    {/if}
    {#if isSubmitting || isDeleting}
        <div class="loading-container">
            <Loading color="#1B1B1B" />
        </div>
    {/if}
    <button
        class="file-input-remove-button"
        disabled={ isSubmitting || isDeleting}
        on:click|preventDefault={ () => showDeleteModal = true }
    >
        <div class="light-grey-trash-icon size-100" />
    </button>
</div>
