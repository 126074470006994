<script>
  import { getImagePath } from "$src/lib/filePath";
  import { languageTagStore } from "$src/lib/store/languageTagStore";
  import { getLocalizedText } from "senselogic-gist";
  import { onDestroy, onMount } from "svelte";

    export let isOpen = false;
    export let marker;
    export let markerCardContainerElement = null;
    export let markerId = 0;
    let markerCard = null;
    let markerCardImage = null;
    let markerCardTitle = null;

    // ~~

    function handleResize() {
        let scaleFactor = window.visualViewport.scale;
        let isZoomedIn = scaleFactor > 1;
        let isScaled = scaleFactor > 1.8;
        let isImageHidden = scaleFactor > 1.75;

        if ( markerCardTitle !== null )
        {
            markerCardTitle.style.fontSize = isScaled ? `${ 1.5 / ( scaleFactor - 1 ) }rem` : "1.5rem";
            markerCardTitle.style.lineHeight = isScaled ? `${ 2.1 / ( scaleFactor - 1 ) }rem` : "2.1rem";
            markerCard.style.whiteSpace = isZoomedIn ? 'nowrap' : 'normal';
        }

        if ( isZoomedIn
             && markerCard !== null
            )
        {
            markerCard.style.width = `${ 10.6 / scaleFactor }rem`;
            markerCard.style.minWidth = "min-content";
            markerCard.style.padding = `${ 1 / scaleFactor }rem`;
            markerCard.style.borderRadius = `${ 1.25 / scaleFactor }rem`;
            markerCard.style.border = `${ 1 / scaleFactor - 1 }px`;
        }

        if ( markerCardImage !== null
             && isZoomedIn
        )
        {
            markerCardImage.style.width = "100%";
            markerCardImage.style.height = `${ 6 / scaleFactor }rem`;
            markerCardImage.style.display = isImageHidden ? "none" : "initial";
        }
        else if (
            markerCardImage !== null
            && !isZoomedIn
        )
        {
            markerCardImage.style.height = "6rem";
        }
    }

    // -- STATEMENTS

    onMount(() => {
        handleResize();

        window.visualViewport.addEventListener( "resize", handleResize );
    });

    onDestroy(() => {
        window.visualViewport.addEventListener( "resize", handleResize );
    });
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES
    .is-hover
    {
        transform: translateY( 0 );

        display: inherit;

        visibility: visible;
        opacity: 1;

        pointer-events: none;
    }

    .marker-card-container:not( .is-hover )
    {
        transform: translateY( 1rem );

        display: none;

        visibility: hidden;
        opacity: 0;

        pointer-events: none;
    }

    .marker-card-container
    {
        z-index: 2;
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .marker-card
    {
        position: absolute;

        border: 1px solid lightGoldColor;
        border-radius: 1.25rem;
        padding: 1rem;

        display: flex;
        flex-direction: column;
        gap: .5rem;
        align-items: center;

        background: darkGreyColor;

        transition: width 400ms ease-in-out;

        +media( desktop )
        {
            position: initial;
        }
    }

    .marker-card-image
    {
        height: 6rem;
        width: 8.5rem;
        border-radius: 10px;

        align-self: stretch;

        transition: width 400ms ease-in-out, height 400ms ease-in-out;

        +media( desktop )
        {
            width: 10.5rem;
        }
    }

    .marker-card-description
    {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .title
    {
        line-height: 2.1rem;
        font-size: 1.5rem;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0.015rem;
        text-align: center;
        color: lightGoldColor;

        transition: font-size 400ms ease-in-out;
    }

    .subtitle
    {
        align-self: stretch;

        line-height: 2rem;
        font-size: 1.1rem;
        color: lightGreyColor;
    }

    .marker-card-polygone
    {
        display: none;

        +media( desktop )
        {
            margin-top: -16px;
            height: 12px;
            width: 12px;
            fill: linear-gradient( 0deg, rgba( 0, 0, 0, 0.20 ) 0%, rgba( 0, 0, 0, 0.20 ) 100% ), #000;
        }
    }
</style>

<div id={ markerId } class="marker-card-container" bind:this={ markerCardContainerElement } class:is-hover={ isOpen }>
    <div
        class="marker-card"
        on:click|stopPropagation
        bind:this={ markerCard }
        >
        {#if marker.mediaPath }
            <div
                bind:this={ markerCardImage }
                class="marker-card-image"
                style="background: URL( { getImagePath( marker.mediaPath, '640' ) } ) no-repeat center center / cover,
                                   URL( { getImagePath( marker.mediaPath, '360' ) } ) no-repeat lightgray 50% / cover;"/>
        {/if}
        <div class="marker-card-description">
            {#if marker.name }
                <p class="title" bind:this={ markerCardTitle }>{ getLocalizedText( marker.name, $languageTagStore ) }</p>
            {/if}

            {#if marker.subtitle }
                <p class="subtitle">{ marker.subtitle }</p>
            {/if}
        </div>
    </div>
    <svg class="marker-card-polygone" xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
        <path d="M21 36.3726L0.215396 0.372555L41.7846 0.372558L21 36.3726Z" fill="black"/>
        <path d="M21 36.3726L0.215396 0.372555L41.7846 0.372558L21 36.3726Z" fill="black" fill-opacity="0.2"/>
    </svg>
</div>
