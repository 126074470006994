<script>
    // -- IMPORTS

    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedContainer from '../element/AnimatedContainer.svelte';
    import AnimatedText from '../element/AnimatedText.svelte';
    import { link } from 'svelte-routing';
    import { getProcessedMultilineTranslatedText } from '$src/lib/base';
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    footer
    {
        width: 88%;
        padding: 5rem 0 3rem;

        display: flex;
        flex-direction: column;
        gap: 7.5rem;

        +media( desktop )
        {
            width: 87.5%;
            padding: 7.5rem 0 1rem;

            gap: 17.5rem;
        }
    }

    p
    {
        +media( smaller-desktop )
        {
            line-height: 1.75rem;
            font-size: 1.25rem;
            letter-spacing: 0.04em;
        }
    }

    // -- CLASSES

    .footer-logo-and-info-group
    {
        width: 100%;

        display: flex;
        justify-content: space-between;

        +media( smaller-desktop )
        {
            flex-direction: column;
            gap: 3rem;

            text-transform: uppercase;
        }
    }

    .footer-logo-group
    {
        max-width: 27.5rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        +media( smaller-desktop )
        {
            width: 100%;

            gap: 1rem;
        }
    }

    .footer-logo
    {
        margin: 0 auto;
        width: 4rem;

        +media( desktop )
        {
            margin: initial;
        }
    }

    .footer-logo-group-text
    {
        line-height: 2.25rem;
        font-size: 1.5rem;
        font-weight: 300;
        letter-spacing: 0.08em;
        text-align: center;
        color: lightGreyColor;

        +media( desktop )
        {
            min-width: 27.5rem;

            text-align: left;
        }
    }

    .footer-info-group
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-end;

        +media( smaller-desktop )
        {
            row-gap: 2rem;
            align-items: center;
        }
    }

    .footer-info-container
    {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    .footer-info
    {
        line-height: 1.625rem;
        font-weight: 300;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        text-wrap: nowrap;
        color: lightGreyColor;
    }

    .footer-social-links
    {
        display: flex;
        gap: 2rem;
    }

    .footer-logo-copyright-and-terms-group
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 3rem;

        +media( desktop )
        {
            gap: 4rem;
        }
    }

    .footer-copyright-and-terms-group
    {
        width: 100%;

        display: flex;
        justify-content: space-between;

        +media( smaller-desktop )
        {
            flex-direction: column;
            gap: 1.5rem;
            align-items: center;
        }
    }

    .footer-copyright-text
    {
        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.08em;
        text-decoration: none;
    }

    a,
    a:hover,
    a:focus,
    a:active
    {
        text-decoration: none;
        color: inherit;

        cursor: pointer;
    }
</style>

<footer>
    <div class="footer-logo-and-info-group">
        <div class="footer-tagline"  class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                type="text"
                slug="tagline"
                textToEdit={ $textStore[ 'tagline' ] }
            />

            <AnimatedContainer>
                <div class="footer-logo-group">
                    <img
                        class="footer-logo"
                        src="/image/icon/logo_2.svg"
                        alt="logo"
                        loading="lazy"
                    >
    
                    <p class="footer-logo-group-text">
                        {@html getProcessedMultilineTranslatedText( $textStore[ 'tagline' ], $languageTagStore ) }
                    </p>
                </div>
            </AnimatedContainer>
        </div>
        <div class="footer-info-group">
            <div class="contact-mail" class:is-admin-enabled={ $enabledAdminView }>
                <AdminEditButton
                    type="text"
                    slug="contact-email"
                    textToEdit={ $textStore[ 'contact-email' ] }
                />

                <AnimatedContainer>
                    <div class="footer-info-container">
                        <div class="mail-icon size-150" />
                        <p class="footer-info">
                             { @html getProcessedMultilineTranslatedText( $textStore[ 'contact-email' ], $languageTagStore ) }
                        </p>
                    </div>
                </AnimatedContainer>
            </div>
            
            <div class="contact-phone" class:is-admin-enabled={ $enabledAdminView }>
                <AdminEditButton
                    type="text"
                    slug="phone-number"
                    textToEdit={ $textStore[ 'phone-number' ] }
                />

                <AnimatedContainer delay="200ms" >
                    <div class="footer-info-container">
                        <div class="phone-icon size-150" class:is-admin-enabled={ $enabledAdminView }/>
                        <p class="footer-info">
                            { @html getProcessedMultilineTranslatedText( $textStore[ 'phone-number' ], $languageTagStore ) }
                        </p>
                    </div>
                </AnimatedContainer>
            </div>

            <div class="footer-social-links">
                <AnimatedContainer delay="300ms">
                    <a href="https://www.instagram.com/bamhub.embassy/" target="_blank" aria-label="Go to Instagran">
                        <div class="instagram-icon size-150"/>
                    </a>
                </AnimatedContainer>
                <AnimatedContainer delay="500ms">
                    <a  href="https://www.linkedin.com/company/bamhub/" target="_blank" aria-label="Go to Linkedin">
                        <div class="linkedin-icon size-150"/>
                    </a>
                </AnimatedContainer>
            </div>
        </div>

    </div>
    <div class="footer-logo-copyright-and-terms-group">
        <img
            class="footer-text-logo"
            src="/image/icon/vector.svg"
            alt="BAMHUB"
        />
        <div class="footer-copyright-and-terms-group">
            <p class="footer-copyright-text">
                <AnimatedText text={ `BAMHUB / ${ new Date().getFullYear() } ©` } />
            </p>

            <div class:is-admin-enabled={ $enabledAdminView }>
                <a
                    class="footer-copyright-text"
                    use:link
                    href={ `/${ $languageTagStore }/privacy-policy` }
                >
                    <AnimatedText text={ getProcessedMultilineTranslatedText( $textStore[ 'privacy-policy-label' ], $languageTagStore ) }/>
                </a>
                <AdminEditButton
                    type="text"
                    slug="privacy-policy-label"
                    inset="-2rem -2rem auto auto"
                    textToEdit={ $textStore[ 'privacy-policy-label' ] }
                />
            </div>

            { #key $textStore[ "faq-label" ] }
                <div class:is-admin-enabled={ $enabledAdminView }>
                    <a
                        class="footer-copyright-text"
                        use:link
                        href={ `/${ $languageTagStore }/FAQ` }
                    >
                        <AnimatedText text={ getProcessedMultilineTranslatedText( $textStore[ 'faq-label' ], $languageTagStore ) }/>
                    </a>
                    <AdminEditButton
                        type="text"
                        slug="faq-label"
                        inset="-2rem -2rem auto auto"
                        textToEdit={ $textStore[ 'faq-label' ] }
                    />
                </div>
            { /key }

            <div class:is-admin-enabled={ $enabledAdminView }>
                <a
                    class="footer-copyright-text"
                    use:link
                    href={`/${ $languageTagStore }/terms-of-service`}
                >
                    <AnimatedText text={ getProcessedMultilineTranslatedText( $textStore[ 'terms-of-service' ], $languageTagStore ) }/>
                </a>
                <AdminEditButton
                    type="text"
                    slug="terms-of-service"
                    inset="-2rem -2rem auto auto"
                    textToEdit={ $textStore[ 'terms-of-service' ] }
                />
            </div>
        </div>
    </div>
</footer>
