<script>
    // -- IMPORTS

    import { languageArrayStore } from '$store/languageArrayStore.js';
    import Input from '../../component/element/Input.svelte';
    import Select from '../../component/element/Select.svelte';
    import InputLocalizedForm from '../../component/element/InputLocalizedForm.svelte';
    import { fetchData, addFileArrayOnObject, checkRequiredIsFilled } from '../../base';
    import { createEventDispatcher, onMount } from 'svelte';
    import Loading from '../../component/element/Loading.svelte';
    import FileInput from '../../component/element/FileInput/FileInput.svelte';
    import { isLoadingStore } from '../../store/isLoadingStore';

    // -- VARIABLES

    export let isUploadingImage = false;
    export let founderId;

    let form;
    let dispatch = createEventDispatcher()
    let error = null;
    let message = null;
    let isLoading = true;
    let isHovered = false;
    let fileArray = [];
    let isSubmitting = false;

    let founderMember = {};
    let folderPath = 'Founder/' + founderMember.id + '/image/';
    let requiredFieldArray = [ 'biography', 'name', 'position' ];

    // -- FUNCTIONS

    function setDefaultFileArray(
        )
    {
        if ( founderMember?.mediaPath )
        {
            fileArray = [ founderMember.mediaPath ];
        }
        else
        {
            fileArray = founderMember.mediaPathArray ?? [];
        }
    }

    // ~~

    function handleUpdateLocalizedInput(
        event
        )
    {
        founderMember[ event.detail.name ] = JSON.stringify( event.detail.text );
    }

    async function handleSubmit(
        event
        )
    {
        $isLoadingStore = true;
        error = null;
        message = null;

        try
        {
            let formData = new FormData();
            formData.delete( 'biography' )

            founderMember = addFileArrayOnObject( founderMember, fileArray );

            for ( let [ key, value ] of Object.entries( founderMember ) )
            {
                formData.append( key, value )
            }
            let errorArray = checkRequiredIsFilled( founderMember, requiredFieldArray );

            if( errorArray.length > 0 )
            {
                return;
            }

            let response =
                await fetchData(
                    '/api/founder/' + founderId,
                    {
                        method: 'POST',
                        body: formData,
                        credentials: 'include'
                    }
                    );

                isSubmitting = false;
                dispatch( 'updateSuccess' );
        }
        catch ( error )
        {
            console.error( error );
        }
        finally
        {
            setDefaultFileArray();
            $isLoadingStore = false;
        }
    }

    async function loadData(
        )
    {
        try
        {
            let managerData =
                await fetchData(
                    '/api/founder/get-by-id',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                id: founderId
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                )

            founderMember = managerData.founderMember;
        }
        catch ( error )
        {
            console.error( error );
        }
        finally
        {
            isLoading = false;
            setDefaultFileArray();
        }
    }
    // ~~

    function customSubmit(
        event
        )
    {
        if ( form )
        {
            let fakeSubmitEvent = new Event('submit', { bubbles: true, cancelable: true } );
            form.dispatchEvent( fakeSubmitEvent );
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            loadData();

            window.addEventListener(
                'on-save-block',
                customSubmit
                );

            return () => {
                window.removeEventListener( 'on-save-block', customSubmit );
            }
        }
    );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    form
    {
        width: 100%;

        +media( desktop )
        {
            width: 50%;
        }
    }

    label
    {
        font-size: 1.25rem;
    }

    option
    {
        color: lightGreyColor;
    }

    fieldset
    {
        border: 1px solid lightGoldColor;
        padding: 1rem;
    }

    // -- CLASSES

    .member-edit-form
    {
        width: 100%;
        padding: 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        +media( tablet )
        {
            padding: 1rem 2.5rem;
        }

        +media( desktop )
        {
            padding: 1.5rem 3rem;
        }
    }

    .card-form-inputs-container

    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .forms-container
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
    }

    .form-container
    {
        width: 100%;
        padding: 1.5rem 0;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }

    .cards-section
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .admin-page-navigation
    {
        position: fixed;

        inset: 8rem 2rem auto auto;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
</style>

{#if isLoading }
    <Loading />
{:else}
    <div id="blocks" class="cards-section">
        <div class="forms-container">
            <div class="form-container" >
                <form
                    on:submit|preventDefault={ handleSubmit }
                    class="member-edit-form"
                    bind:this={ form }
                >
                    {#if error }
                        <p class="card-form-error">{ error }</p>
                    {/if}
                    {#if message }
                        <p class="card-form-message">{ message }</p>
                    {/if}
                    <div class="card-form-inputs-container">
                        <Input label="Manager name" id="name" bind:value={ founderMember[ 'name' ] } fullWidth/>

                        <InputLocalizedForm
                            isMultiLine
                            itemsString={ founderMember[ 'biography' ] }
                            languageArray={ $languageArrayStore }
                            name="biography"
                            on:update={ handleUpdateLocalizedInput }
                            placeholder="Biography"
                        />

                        <InputLocalizedForm
                            isMultiLine
                            itemsString={ founderMember[ 'position' ] }
                            languageArray={ $languageArrayStore }
                            name="position"
                            on:update={ handleUpdateLocalizedInput }
                            placeholder="Manager role"
                        />
                        {#if founderMember[ 'imageSide' ] }
                            <Select
                                name="imageSide"
                                label="Image side"
                                bind:value={ founderMember[ 'imageSide' ] }
                                fullWidth
                            >
                                {#each [ 'left', 'right' ] as imageSideOption }
                                    <option value={ imageSideOption } selected={ imageSideOption === founderMember[ 'imageSide' ] }>
                                        { imageSideOption }
                                    </option>
                                {/each}
                            </Select>
                        {/if}

                        <InputLocalizedForm
                            itemsString={ founderMember[ 'linkedinLink' ] }
                            languageArray={ $languageArrayStore }
                            name="linkedinLink"
                            on:update={ handleUpdateLocalizedInput }
                            placeholder="Linkedin"
                        />

                        <InputLocalizedForm
                            itemsString={ founderMember[ 'emailAddress' ] }
                            languageArray={ $languageArrayStore }
                            name="emailAddress"
                            on:update={ handleUpdateLocalizedInput }
                            placeholder="Email"
                        />

                        {#if founderMember[ 'mediaPath' ] }
                            <fieldset>
                                <legend class="color-light-gold font-size-125 margin-bottom-100 text-transform-uppercase">Manager media</legend>
                                <FileInput
                                    bind:fileArray={ fileArray }
                                    bind:isUploadingImage={ isUploadingImage }
                                    fileInputName="image-path"
                                    acceptedType="image/*, video/*"
                                    maxFileCount={ 5 }
                                    filePath={ folderPath }
                                />
                            </fieldset>
                        {/if}
                        {#if founderMember[ 'imageAlt' ] }
                            <Input label="Alternative image text" id="image-alt" bind:value={ founderMember[ 'imageAlt' ] } fullWidth/>
                        {/if}
                    </div>
                </form>
            </div>
        </div>
    </div>
{/if}
