<script>
    import { capitalizeFirstLetter } from '../../base';
    // -- VARIABLES

    export let disabled = false;
    export let error = false;
    export let fullWidth = false;
    export let multiple = false;
    export let helperText = '';
    export let label = '';
    export let name = '';
    export let required = false;
    export let type = 'text';
    export let value;
    export let size = '1';
    export let element = null;
    export let shrink = true;
    export let shouldExpand = true;
    export let hasBorder = true;

    $:shrink = shouldExpand === true ? Boolean( value ) : true;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import "../../../constant.styl";
    @import "../../../mixin.styl";

    // -- ELEMENTS

    select
    {
        background: darkGrayColor !important;
        transition : background 0.3s linear;
    }

    select:hover,
    select:active
    {
        background: pureBlackColor !important;
    }

    // -- CLASSES

    .input-root
    {
        margin: 0.5rem;
        height: 100%;
        width: 25ch;
    }

    .input-container
    {
        position: relative;

        margin: 0px;
        min-width: 0px;
        border: 0px;
        padding: 0px;

        display: inline-flex;
        flex-direction: column;

        vertical-align: top;
    }

    .input-label.error
    {
        color: rgb(211, 47, 47);
    }

    .input-label
    {
        margin-bottom: .5rem;
        max-width: calc(133% - 2rem);

        color: lightGreyColor;

        user-select: none;
        pointer-events: auto;
        transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    [data-shrink="false"]
    {
        transform: translate(0.875rem, 1rem) scale(1);

        max-width: calc(100% - 1.5rem);

        pointer-events: none;
    }

    .input-content
    {
        box-sizing: border-box;

        display: inline-flex;

        line-height: 1.4375em;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.00938em;
        color: lightGreyColor;

        cursor: text;
        -webkit-box-align: center;
        position: relative;

        overflow: hidden;
        height: 100%;
        border-radius: 4px;

        align-items: center;
    }

    .input-content.is-multiline
    {
        overflow: hidden;

        align-items: flex-start;
    }

    .input
    {
        box-sizing: content-box;
        margin: 0px;
        outline: none;
        border: 0px;

        background: none;

        font: inherit;
        letter-spacing: inherit;
        color: currentcolor;
        -webkit-tap-highlight-color: transparent;
        height: 100%;
        width: 100%;
        min-width: 0px;
        padding: 1.031rem 0.875rem;

        display: block;

        animation-name: mui-auto-fill-cancel;
        animation-duration: 10ms;
    }

    .input-content.error .fieldset
    {
        border-color: redColor500;
    }

    .fieldset
    {
        position: absolute;

        overflow: hidden;
        inset: -5px 0px 0px;
        margin: 0px;
        min-width: 0%;
        padding: 0px 0.5rem;

        text-align: left;

        pointer-events: none;
    }

    .legend
    {
        overflow: hidden;
        height: 0.6875rem;
        width: auto;
        padding: 0px;

        display: block;
        float: unset;

        visibility: hidden;
        background: darkGreyColor;

        font-size: 0.75em;
        white-space: nowrap;
        color: lightGoldColor;

        transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    }

    [data-input-filled="true"]
    {
        max-width: 100%;
    }

    [data-input-filled="false"]
    {
        max-width: 0.01px;
    }

    .legend > span
    {
        padding-left: 0.3125rem;
        padding-right: 0.3125rem;

        display: inline-block;

        visibility: visible;
        opacity: 0;
    }

    .helper-text.error
    {
        color: rgb(211, 47, 47);
    }

    .helper-text
    {
        margin: 3px 0.875rem 0px;

        line-height: 1.66;
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.03333em;
        text-align: left;
        color: lightGoldColor;
    }

    .input-without-adornment
    {
        height: 0.01em;
        max-height: 2em;

        display: flex;
        -webkit-box-align: center;
        margin-right: 0.5rem;

        align-items: center;

        white-space: nowrap;
        color: lightGoldColor;
    }

    .input-start-adornment
    {
        box-sizing: border-box;

        display: inline-flex;

        line-height: 1.4375em;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.00938em;
        color: lightGoldColor;

        cursor: text;
        -webkit-box-align: center;
        position: relative;

        border-radius: 4px;
        padding-left: 0.875rem;

        align-items: center;
    }

    .input-end-adornment
    {
        height: 0.01em;
        max-height: 2em;

        display: flex;
        -webkit-box-align: center;
        margin-left: 0.5rem;

        align-items: center;

        white-space: nowrap;
        color: lightGoldColor;
    }

    .input-paragraphy
    {
        margin: 0px;

        line-height: 1.5;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.00938em;
        color: lightGoldColor;
    }

    .full-width
    {
        width: 100%;
    }

    :global( option )
    {
        color: lightGreyColor;
    }

    @-webkit-keyframes mui-auto-fill
    {
        from
        {
            display: block;
        }
    }

    @keyframes mui-auto-fill
    {
        from
        {
            display: block;
        }
    }

    @-webkit-keyframes mui-auto-fill-cancel
    {
        from
        {
            display: block;
        }
    }

    @keyframes mui-auto-fill-cancel
    {
        from
        {
            display: block;
        }
    }
</style>

<div class="input-root input-container" class:full-width={ fullWidth }>
    <h4
        class:error={ error }
        class="input-label"
        for="outlined-error-helper-text"
        id="outlined-error-helper-text-label"
    >
        { capitalizeFirstLetter( label ) }
    </h4>
    <div class="input-content" class:error={ error }>
        {#if Boolean( multiple ) }
            <select
                aria-describedby="outlined-error-helper-text-helper-text"
                aria-invalid="true"
                bind:this={ element }
                bind:value
                class="input is-multiline"
                disabled={ disabled }
                id="outlined-error-helper-text"
                multiple="multiple"
                name={ name }
                size={ size }
            >
                <slot/>
            </select>
        {:else}
            <select
                aria-describedby="outlined-error-helper-text-helper-text"
                aria-invalid="true"
                bind:value
                class="input"
                disabled={ disabled }
                id="outlined-error-helper-text"
                name={ name }
                on:blur={ () => ( shrink = false ) }
                on:focus={ () => ( shrink = true ) }
            >
                <slot/>
            </select>
        {/if}
        <fieldset
            aria-hidden="true"
            class="fieldset"
            class:has-border={ hasBorder }
        >
            <legend
                class="legend"
                data-input-filled={ Boolean( value ) || shrink }
            >
                <span>
                    { label }
                </span>
            </legend>
        </fieldset>
    </div>
    {#if error }
        <p
            class="helper-text"
            class:error={ error }
            id="outlined-error-helper-text-helper-text"
        >
            { helperText }
        </p>
    {/if}
</div>
