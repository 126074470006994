<script>
    // -- IMPORTS

    import { enabledAdminView } from '$lib/store/adminStore';
  import { fetchData } from '$src/lib/base';
  import { languageTagStore } from '$src/lib/store/languageTagStore';
  import { textStore } from '$src/lib/store/textStore';
    import EditTextModal from './EditTextModal.svelte';
    import IconButton from './IconButton.svelte';

    // -- VARIABLES

    export let inset = '-0.5rem -1rem auto auto';
    export let type = null;
    export let id = null;
    export let slug = null;
    export let hash = null;
    export let theme = "light-gold";
    export let queryString = '';
    export let title = '';
    export let handleAdminButton = null;
    export let isTextModalOpen = false;
    export let textToEdit = '';
    export let isMultiline = true;

    let route = window.origin.includes( 'localhost' ) 
                    ? 'http://localhost:5174' 
                    : '';
    let adminButtonElement = null;
    let isHovering = false;
    let textData = { slug, text: textToEdit };

    let path;

    if ( slug )
    {
        path = slug;
    }
    else
    {
        path = id;
    }

    // -- FUNCTIONS

    function markParentElement(
        )
    {
        let parentElement = adminButtonElement.parentNode;
        isHovering = !isHovering;
        
        if( isHovering )
        {
            parentElement.style.outline = "1px outset #ef5350"
        }
        else
        {
            parentElement.style.outline = "none";
        }
    }

    // ~~

    function handleOpenTextModal(
        )
    {
        isTextModalOpen = true;
    }

    // ~~

    async function reloadText(
        slug
        )
    {
        let textData =
            await fetchData(
                '/api/text',
                {
                    method: 'POST',
                    body: JSON.stringify(
                        {
                            slug: slug,
                        }
                    ),
                    headers: { 'Content-Type': 'application/json' }
                }
                );

        $textStore[ slug ] = textData.text;
        isTextModalOpen = false;
    }
</script>

<style lang="stylus">
    // -- ELEMENTS

    a, button
    {
        z-index: 1;
    }

    button 
    {
        background-color: transparent;
    }

    // -- CLASSES

    .admin-edit-button
    {
        position: absolute;

        cursor: pointer;
    }
</style>

{ #if type === 'text' }
    <EditTextModal 
        bind:isModalOpen={ isTextModalOpen }
        isMultiline={ isMultiline }
        textData={ textData }
        handleReloadText={ () => reloadText( slug ) }
    />
{ /if }

{#if $enabledAdminView }
    {#if type === "text" }
        <button
            bind:this={ adminButtonElement }
            on:click={ handleOpenTextModal }
            class="admin-edit-button"
            style="inset: { inset };"
            title={ slug }
            on:mouseenter={ markParentElement }
            on:mouseleave={ markParentElement }
        >
            <IconButton>
                <div class="{ theme }-edit-icon size-150"/>
            </IconButton>
        </button>
    { :else if handleAdminButton !== null }
        <button
            bind:this={ adminButtonElement }
            on:click={ handleAdminButton }
            class="admin-edit-button"
            style="inset: { inset };"
            title={ title }
            on:mouseenter={ markParentElement }
            on:mouseleave={ markParentElement }
        >
            <IconButton>
                <div class="{ theme }-edit-icon size-150"/>
            </IconButton>
        </button>
    { :else }
        <a
            bind:this={ adminButtonElement }
            href="{ route }/admin{ type ? `/${ type }` : '' }{ path ? `/edit/${ path }` : '' }{ queryString ? `?${ queryString }` : '' }{ hash ? `#${hash}` : ''}"
            target="_blank" 
            class="admin-edit-button"
            style="inset: { inset };"
            title={ title }
            on:mouseenter={ markParentElement }
            on:mouseleave={ markParentElement }
        >
            <IconButton>
                <div class="{ theme }-edit-icon size-150"/>
            </IconButton>
        </a>  
    { /if }
{/if}
