<script>
    // -- IMPORTS

    import { fetchData, getProcessedMultilineTranslatedText, websiteUrl } from '$lib/base.js';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import { pageStore } from '../store/pageDataStore';
    import { onMount } from 'svelte';
    import FullLoading from '../component/Layout/FullLoading.svelte';
    import TitleImageAndDescriptionBlock from '../component/element/TitleImageAndDescriptionBlock.svelte';

    // -- VARIABLES

    let isLoading = true;
    let name = '';
    let slug = '';
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let mediaPath = '';
    let url = '';

    let networkingPage = $pageStore.page.networkingPage;
    let networkingBlockArray = $pageStore.block.networkingBlockArray;
    let networkingBlockArrayBySlug = $pageStore.block.networkingBlockArrayBySlug;
    let networkCityArray = $pageStore.block.networkCityArray;
    let networkingBenefitsArray = $pageStore.block.networkingBenefitsArray;
    let partnerCompanyArray = $pageStore.block.partnerCompanyArray;

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let pageData =
                await fetchData(
                    '/api/page/partners-page',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                            },
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            let networkBenefits = pageData.blockArray.filter(( block ) => block.typeSlug === 'text-and-image');

            $pageStore.page.networkingPage = pageData.page;
            $pageStore.block.networkingBlockArray = pageData.blockArray;
            $pageStore.block.networkBenefitsArray = networkBenefits;

            networkingPage = pageData.page;
            networkingBlockArray = pageData.blockArray;
            networkingBenefitsArray = networkBenefits;

            metaTitle = pageData.page.metaTitle ?? pageData.page.title;
            metaDescription = pageData.page.metaDescription ?? pageData.page.subTitle;
            metaKeywords = pageData.page.metaKeywords;
            mediaPath = pageData.page.mediaPath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // ~~

    function scrollToPartner(
        )
    {
        let hash = window.location.hash.replace('#', '');

        if ( hash ) 
        {
            setTimeout(
                () => 
                {
                    let element = document.getElementById( hash );
                    
                    if ( element ) 
                    {
                        let elementPosition = element.getBoundingClientRect().top + window.pageYOffset
                        let offset = 
                            hash === 'partenaire-participant'
                                ? 200
                                : 100;


                        window.scrollTo( 
                            { 
                                top: elementPosition - offset,
                                behavior: 'smooth'
                            } 
                        );
                    }
                }, 
                500
            );
        }
    }

    // -- STATEMENTS

    onMount(
        async () =>
        {
            if
            (
                networkingPage === undefined
                || networkingBlockArray === undefined
                || networkingBlockArrayBySlug === undefined
                || networkCityArray === undefined
                || networkingBenefitsArray === undefined
                || partnerCompanyArray === undefined
            )
            {
                await loadData();
                scrollToPartner()
            }
            else
            {
                isLoading = false;
                scrollToPartner();
            }
        }
        );

    $: if ( networkingPage !== undefined 
            && !isLoading )
        {
            name = networkingPage.slug;
            slug = networkingPage.slug;
            metaTitle = networkingPage.metaTitle;
            metaDescription = networkingPage.metaDescription;
            metaKeywords = networkingPage.metaKeywords;
            mediaPath = networkingPage.mediaPath;
            url = websiteUrl;
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    :global( html > body > div > main )
    {
        background: unset;
        background-color: darkGreyColor;
    }

    .what-is-bamhub-section
    {
        border: 1px solid borderColor;
        padding: 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 4rem;

        +media( desktop )
        {
            margin: 0;
            margin-bottom: 5rem;
            max-width: 77vw;
            border: none;
            padding-bottom: 0;
        }
    }

    .networking-section
    {
        padding: 5rem 1.5rem;

        gap: 5rem;

        +media( desktop )
        {
            max-width: 77vw;
        }
    }

    .networking-heading
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        align-self: stretch;
    }

    .networking-title
    {
        line-height: 2.5rem;
        font-size: 2rem;
        letter-spacing: 0.16rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 250;
            letter-spacing: 0.48rem;
            text-align: center;
        }
    }

    .what-we-offer-container
    {
    }

    @keyframes infinite-scroll
    {
        to
        {
            transform: translate( calc( -50% - 0.5rem ) );
        }
    }

    .what-we-offer-container
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        align-self: stretch;

        +media( desktop )
        {
            gap: 5rem;
        }
    }

    .what-we-offer-card-list
    {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
        align-self: stretch;

        +media( desktop )
        {
            gap: 4rem;
        }
    }
</style>

<section class="what-is-bamhub-section">
    {#if isLoading }
        <FullLoading />
    {:else}
        <div class="what-we-offer-container">
            <p class="networking-title color-light-gold" class:is-admin-enabled={ $enabledAdminView }>
                <AdminEditButton
                    type="text"
                    slug="partners-bamhub-label"
                    inset="-2.5rem -1.5rem auto auto"
                    textToEdit={ $textStore[ 'partners-bamhub-label' ] }
                />
                <AnimatedText text={ getProcessedMultilineTranslatedText( $textStore[ 'partners-bamhub-label' ] || '', $languageTagStore ) }/>
            </p>

            <div class="what-we-offer-card-list">
                {#each networkingBenefitsArray as networkingBenefit }
                    <TitleImageAndDescriptionBlock 
                        block={ networkingBenefit }
                    />
                {/each}
            </div>
        </div>
    {/if}
</section>
