<script>
    import { getImagePath } from '$lib/filePath';
    // -- IMPORTS

    import { getLocalizedText, setLanguageTag } from "senselogic-gist";
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { navigate } from "svelte-routing";
    import page from '$lib/store/pageStore';

    // -- VARIABLES

    export let languageIconPath;
    export let languageName;
    export let optionArray;
    export let isMobileNavigationOpen;
    let isOpen = false;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    a, button
    {
        font-size: 1rem;
        text-decoration: none;
        text-transform: uppercase;
        text-transform: uppercase;
        color: lightGreyColor;

        cursor: pointer;
        transition: all 0.1s ease-in-out;

        +media( smaller-desktop )
        {
            line-height: 2rem;
            font-size: 1.5rem;
            letter-spacing: 0.08em;
        }

        +media( desktop )
        {
            margin: 0 2rem 0 .75rem;
        }
    }

    button
    {
        background-color: unset;
    }

    a:hover
    {
        color: lightGoldColor;
    }

    // -- CLASSES

    .dropdown-navigation-link
    {
        position: relative;

        display: flex;

        +media( smaller-desktop )
        {
            width: 100%;
            border-bottom: 1px solid #2A2A2A;
            padding: 1rem 0;
        }
    }

    .main-link
    {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        +media( smaller-desktop )
        {
            width: 100%;

            justify-content: space-between;
        }
    }

    .dropdown-menu
    {
        top: 2.5rem;

        min-width: 11.875rem;

        display: none
        flex-direction: column;

        +media( desktop )
        {
            position: absolute;
        }
    }

    .dropdown-menu.is-open
    {
        display: flex;
    }

    .dropdown-option-container
    {
        width: 100%;
        border-bottom: 1px solid #2A2A2A;
        padding: 1rem 1.5rem;

        background-color: darkGreyColor;

        cursor: pointer;

        +media( desktop )
        {
            border-bottom: 1px solid black;
        }
    }

    .icon
    {
        transition: all 0.2s ease;
    }

    .dropdown-navigation-link .icon.is-active
    {
        transform: rotate( -180deg );
    }

    .dropdown-option
    {
        border-bottom: none;

        display: flex;
        gap: 0.5rem;
        align-items: center;

        text-decoration: none;
        text-transform: inherit;
        color: lightGreyColor;
    }

    .dropdown-option:hover
    {
        color: lightGoldColor;
    }

    .flag
    {
        height: 1.5rem;
        width: 2rem;
    }
</style>

<div class="dropdown-navigation-link">
    <button
        type="button"
        class="main-link"
        on:click={ () => isOpen = !isOpen }
    >
        <div class="dropdown-option">
            <!-- {#if languageIconPath }
               <img class="flag" src={ getImagePath( languageIconPath ) } />
            {/if} -->
            { getLocalizedText( $languageTagStore, $languageTagStore ) }
        </div>
        <div class="down-caret-icon size-100 icon" class:is-active={ isOpen } />
    </button>
    <ul class="dropdown-menu" class:is-open={ isOpen }>
        {#each optionArray as option }
            <li
                class="dropdown-option-container"
                on:click={
                    () =>
                    {
                        if ( languageIconPath )
                        {
                            let languageCode = option.languageCode;
                            languageIconPath = option.languageIconPath;

                            let languageRoute
                                = ( $page.url.pathname + '/' ).replace(
                                      '/' + $languageTagStore + '/',
                                      '/' + languageCode + '/'
                                      ).slice( 0, -1 );

                            $languageTagStore = languageCode;
                            setLanguageTag( languageCode );

                            navigate( languageRoute );
                        }

                        isMobileNavigationOpen = false;
                        isOpen = false;
                    }
                }
            >
                <div
                    class="dropdown-option"
                >
                    {#if option.labelIcon }
                        <img class="flag" src={ getImagePath( option.languageIconPath ) } />
                    {/if}
                    { getLocalizedText( option.languageName, $languageTagStore ) }
                </div>
            </li>
        {/each}
    </ul>
</div>
