<script>
    // -- VARIABLES

    export let emailAddress = null;
    export let linkedinLink = null;
    export let instagramLink = null;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .description-contact
    {
        display: flex;
        gap: 1.5rem;
    }

    .contact-anchor
    {
        border: 1px solid lightGoldColor;
        padding: 0.625rem;

        cursor: pointer;
    }

    .contact-anchor:hover
    {
        animation: fadeIn 300ms ease-in-out forwards;
    }

    .contact-anchor:hover .light-gold-email-icon,
    .contact-anchor:hover .light-gold-linkedin-icon,
    .contact-anchor:hover .light-gold-instagram-icon
    {
        border: 1px solid darkGrayColor;

        background: darkGreyColor;
    }

    .icon
    {
        flex-shrink: 0;
    }

    // -- MIXINS

    @keyframes fadeIn
    {
        from
        {
            opacity: 0;
            border: 1px solid darkGreyColor;
        }
        to
        {
            opacity: 1;
            background: lightGoldColor;
            border: 1px solid lightGoldColor;
        }
    }
</style>

<div class="description-contact">
    {#if emailAddress }
        <a class="contact-anchor" href="mailto:{ emailAddress }" aria-label="Send an email">
            <div class="light-gold-email-icon size-200 icon"></div>
        </a>
    {/if}

    {#if linkedinLink }
        <a class="contact-anchor" href={ linkedinLink } target="_blank" aria-label="Go to linkedin">
            <div class="light-gold-linkedin-icon size-200 icon"></div>
        </a>
    {/if}

    {#if instagramLink }
        <a class="contact-anchor" href={ instagramLink } target="_blank" aria-label="Go to Instagram">
            <div class="light-gold-instagram-icon size-200 icon"></div>
        </a>
    {/if}
</div>
